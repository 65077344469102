import { NoteName, STANDARD_NOTES } from '@power-chord/music-theory/notes';
import * as React from 'react';
import PopupMenuButton from './PopupMenuButton';

const MENU_ITEMS = STANDARD_NOTES.map(n => [n.formattedName, n.name]);

type NoteSelectorProps = {
  /** Initial note to select */
  selectedNote: NoteName | "";
  /** Function to call when selection changed */
  onChange: (note: NoteName) => void;
  /** Optional title for the selector */
  title?: string;
};

/** Used to select a standard note name */
export default function NoteSelector(props: NoteSelectorProps) {
  return (
    <PopupMenuButton
      menuItems={MENU_ITEMS}
      title={props.title}
      value={props.selectedNote}
      onChange={e => noteChanged(e as NoteName)}>
    </PopupMenuButton>
  );

  function noteChanged(noteName: NoteName): any {
    props.onChange(noteName);
  }
}
