import * as React from 'react';
import { useLogger } from '../../hooks/useLogger';

const CHORD_NAMES = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh"];

export type KeyNotesAndChordsProps = {
  selectedKey: string;
  selectedQuality: string;
  notesInKey: string[];
  chordsInKey: string[];
  chordNumbers: string[];
  noteDegrees: string[];
};

export default function KeyNotesAndChords(props: KeyNotesAndChordsProps) {
  const logger = useLogger("KeyNotesAndChords");
  const [selectedTriad, setSelectedTriad] = React.useState(-1);
  const [selectedNotes, setSelectedNotes] = React.useState<number[]>([]);

  return (
      <div>
        <label>Notes in Key</label>
        <ol className="note-names">
          {props.notesInKey.map((note, i) =>
            <li key={note} title={props.noteDegrees[i]}
              className={getChordClassName(i) + " " + (selectedNotes.indexOf(i) >= 0 ? "selected" : "")}>
              {note}
              <span>{i + 1}</span>
            </li>)}
        </ol>
        <label>Triad Chords in Key</label>
        <div>
          <ol className="triad-names">
            {props.chordsInKey.map((note, i) =>
              <li key={note} title={CHORD_NAMES[i]}
                className={getChordClassName(i) + " " + (selectedTriad === i ? "selected" : "")}
                onClick={e => toggleTriad(i)}>
                {note}
                <span>{props.chordNumbers[i]}</span>
              </li>
            )}
          </ol>
        </div>
      </div>
  );

  function getChordClassName(i: number): string {
    let name: string;
    if (i === 0) {
      name = "tonic";
    }
    else {
      const numeral = props.chordNumbers[i];
      name = numeral.indexOf("°") >= 0 ? "dim" :
        numeral.indexOf("i") >= 0 || numeral.indexOf("v") >= 0 ? "minor" :
          "major";
    }

    return name;
  }

  function toggleTriad(noteNumber: number): void {
    noteNumber = (selectedTriad === noteNumber ? -1 : noteNumber);

    let selectedNotes: number[] = [];
    if (noteNumber >= 0) {
      selectedNotes = [noteNumber, (noteNumber + 2) % 7, (noteNumber + 4) % 7];
      logger.debug(selectedNotes);
    }

    setSelectedTriad(noteNumber);
    setSelectedNotes(selectedNotes);
  }
}
