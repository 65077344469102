import OpenIcon from '../common/icons/OpenIcon';
import SaveIcon from '../common/icons/SaveIcon';
import UndoIcon from '../common/icons/UndoIcon';
import ClearGridIcon from './icons/ClearGridIcon';
import NewGridIcon from './icons/NewGridIcon';
import ZoomInIcon from './icons/ZoomInIcon';
import ZoomOutIcon from './icons/ZoomOutIcon';
import ZoomResetIcon from './icons/ZoomResetIcon';
import { ZOOM_AMT } from './RhythmView';

type RhythmToolbarProps = {
  isPlaying: boolean;
  canUndo: boolean;
  canRedo: boolean;
  onClear: () => any;
  onReset: () => any;
  onSaveFile: () => any;
  onOpenFile: () => any;
  onZoom: (amount: number) => any;
  onUndo: () => any;
  onRedo: () => any;
};

export default function RhythmToolbar(props: RhythmToolbarProps) {
  return (
    <div className="controls">
      <section className="toolbar">
        <button onClick={reset} title="Reset (Ctrl+R)" className={props.isPlaying ? "disabled" : ""}><NewGridIcon /></button>
        <button onClick={clear} title="Clear Events (Escape)" className={props.isPlaying ? "disabled" : ""}><ClearGridIcon /></button>
        <button onClick={props.onOpenFile} title="Open (Ctrl+O)"><OpenIcon /></button>
        <button onClick={props.onSaveFile} title="Save (Ctrl+S)"><SaveIcon /></button>
        <button onClick={props.onUndo} title="Undo (Ctrl+Z)" className={props.canUndo ? "" : "disabled"}><UndoIcon /></button>
        <button onClick={props.onRedo} title="Redo (Ctrl+Y)" className={props.canRedo ? "redo" : "redo disabled"}><UndoIcon /></button>
        <button onClick={() => zoom(ZOOM_AMT)} title="Zoom In (+)"><ZoomInIcon /></button>
        <button onClick={() => zoom(-ZOOM_AMT)} title="Zoom Out (-)"><ZoomOutIcon /></button>
        <button onClick={() => zoom(0)} title="Zoom Reset (1)"><ZoomResetIcon /></button>
      </section>
    </div>
  );

  function reset(): void {
    props.onReset();
  }

  function clear(): void {
    props.onClear();
  }

  function zoom(amount: number): void {
    props.onZoom(amount);
  }
}