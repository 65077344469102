import { AudioTuner } from "audio-analyzer";
import { CanvasContext2D } from "canvas-context-2d";
import { debounce } from "debounce";
import { useEffect, useRef } from "react";
import { useConst } from "../../hooks/useConst";
import { useLogger } from "../../hooks/useLogger";
import { FrequencyDataRenderer, RainbowFrequencyRenderer } from "./audio-data-renderer";

const COLOR_SPEED = 4;

type AudioVisualizerCanvasProps = {
  isRunning: boolean;
  tuner: AudioTuner;
};

export default function AudioVisualizerCanvas(props: AudioVisualizerCanvasProps) {
  const logger = useLogger("AudioVisualizerCanvas");
  const renderDebounce = useConst(() => debounce(() => renderer.current!.draw(), 200));
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const renderer = useRef<FrequencyDataRenderer>();

  // Init canvas and renderer
  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const canvasCtx = new CanvasContext2D(canvas);
      renderer.current = new RainbowFrequencyRenderer(canvasCtx, COLOR_SPEED);
      updateCanvasSize(canvas, renderDebounce);

      const resizeObserver = new ResizeObserver(() => updateCanvasSize(canvas, renderDebounce));
      resizeObserver.observe(canvas.parentElement!);

      return () => {
        resizeObserver.disconnect();
      }
    }
  }, [renderDebounce]);

  // Init freq listener
  useEffect(() => {
    if (props.isRunning) {
      logger.debug("Starting freq listener");
      const freqListener = (data: Uint8Array) => renderer.current?.draw(data);
      props.tuner.listener.addByteFrequencyDataListener(freqListener);

      return () => {
        logger.debug("Stopping freq listener");
        props.tuner.listener.removeByteFrequencyDataListener(freqListener);
      }
    }
  }, [logger, props.isRunning, props.tuner.listener]);

  return (
    <div className="canvas-container">
      <canvas width="1024" height="576" ref={canvasRef}></canvas>
    </div>
  );
}

function updateCanvasSize(canvas: HTMLCanvasElement, renderDebounce: Function): void {
  const container = canvas.parentElement!;
  canvas.width = Math.max(200, container.clientWidth - 16);
  canvas.height = Math.max(200, container.clientHeight - 16);

  renderDebounce();
}
