import { hasUrlSearchParam, isPathRoot } from "@local/power-chord-lib/build/src/browser-utils";
import FileService from "@local/power-chord-lib/build/src/services/file-service";
import StateService from '@local/power-chord-lib/build/src/services/state-service';
import { ViewName } from "@local/power-chord-lib/build/src/state-types";
import * as analog from "analogging";
import iocContainer from 'ez-ioc';
import { useEffect, useMemo, useState } from 'react';
import CacheBuster from "react-cache-buster";
import { Outlet, useNavigate } from 'react-router-dom';
import './App.scss';
import PrivacyPolicy from "./PrivacyPolicy";
import RenderWhen from "./components/common/RenderWhen";
import SplitContainer from "./components/common/SplitContainer";
import NavBar from './components/navbar/NavBar';
import { Globals } from "./globals";
import { useAnalytics } from "./hooks/useAnalytics";
import { TYPES, initDependencies } from './lib/init-dependencies';
import { initLogging } from './lib/init-logging';

const isProduction = process.env.NODE_ENV === 'production';

initLogging();
const logger = analog.getLogger("App");
logger.debug("Starting app version", Globals.version);

initDependencies();
const stateService = iocContainer.resolve<StateService>(TYPES.StateService);

let loading = false;

export default function App() {
  const analytics = useAnalytics();
  const [navExpanded, setNavExpanded] = useState(stateService.state.navExpanded);
  const [ledColor, setLedColor] = useState(stateService.state.color);
  const [bgColor, setBgColor] = useState(stateService.state.bgColor);
  const [colorMode, setColorMode] = useState(stateService.state.colorMode);
  const [ledMode, setLedMode] = useState(stateService.state.ledMode);
  const [loaded, setLoaded] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(!stateService.state.privacyConsent);

  const isLandingPage = window.location.pathname === "/";
  const viewClassName = useMemo(() => isLandingPage ? "landing" : "views", [isLandingPage]);

  const navigate = useNavigate();

  // Init the app
  useEffect(() => {
    if (loading || loaded) return;

    stateService.onStateChanged = updateState;

    if (hasUrlSearchParam("reset")) {
      resetAppState();
      setLoaded(true);
    }
    else {
      logger.info("Loading app state");
      loading = true;
      stateService.loadState().then(state => {
        setLoaded(true);
        loading = false;
        updateState(state);

        if (isPathRoot() && state.currentView) {
          logger.debug("Redirecting to previous view:", state.currentView);
          navigate("/" + state.currentView);
        }
      });
    }
  }, [loaded, navigate]);

  return (
    <>
      <RenderWhen condition={loaded}>
        <div className={`App ${ledColor} bg${bgColor} ${colorMode} led-${ledMode}`}>
          <RenderWhen condition={showPrivacy}>
            <PrivacyPolicy onConsent={privacyConsented} />
          </RenderWhen>
          <SplitContainer direction="vertical" rightWeight={1}>
            <RenderWhen condition={!isLandingPage}>
              <NavBar onExpandedChanged={e => expandChanged(e)} expanded={navExpanded} onViewChanged={view => viewChanged(view)}></NavBar>
            </RenderWhen>
            <div className={viewClassName}>
              <Outlet />
            </div>
          </SplitContainer>
        </div>
      </RenderWhen>
      <RenderWhen condition={!loaded}>
        <CacheBuster
          currentVersion={Globals.version}
          isEnabled={isProduction}
          isVerboseMode={logger.isDebugEnabled}
          loadingComponent={<h2>Loading...</h2>}>
          <h2>Loading...</h2>
        </CacheBuster>
      </RenderWhen>
    </>
  );

  /** Updates component state from persisted state */
  function updateState(state = stateService.state): void {
    setLedColor(state.color);
    setBgColor(state.bgColor);
    setColorMode(state.colorMode);
    setLedMode(state.ledMode);
    setNavExpanded(state.navExpanded);
    setShowPrivacy(!state.privacyConsent);
  }

  function privacyConsented(): void {
    setShowPrivacy(false);
    stateService.state.privacyConsent = true;
    stateService.saveState();
  }

  function expandChanged(expanded: boolean): void {
    analytics.logEvent("", "toggle");
    stateService.state.navExpanded = expanded;
    setNavExpanded(expanded);
    stateService.saveState();
  }

  function viewChanged(view: ViewName): any {
    logger.debug("view changed:", view);
    if (view) {
      stateService.state.currentView = view;
      stateService.saveState();
      analytics.logScreenView(view);
    }
  }
}

function resetAppState() {
  logger.info("Resetting app state");
  // Remove any files in the file services
  iocContainer.resolve<FileService>(TYPES.RhythmFileSvc).reset();
  iocContainer.resolve<FileService>(TYPES.ProgressionFileSvc).reset();
  stateService.resetState();
}
