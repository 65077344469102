import * as React from 'react';
import Toggle, { ToggleProps } from "react-toggle"
import "react-toggle/style.css";
import "./ToggleSwitch.scss";

export default function ToggleSwitch(props: ToggleProps) {
  return (
    <Toggle checked={props.checked} onChange={e => onChange(e)} icons={props.icons} className="toggle-switch"></Toggle>
  );

  function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (props.onChange) {
      props.onChange(e);
    }
  }
}
