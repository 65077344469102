import { Sequencer } from '@local/power-chord-lib/build/src/audio/sequencer';
import { ProgressionController } from '@local/power-chord-lib/build/src/progressions/progression-controller';
import { progressionToSequencerRows } from '@local/power-chord-lib/build/src/progressions/progression-to-sequence';
import FileService from '@local/power-chord-lib/build/src/services/file-service';
import { ChordProgression } from '@local/power-chord-lib/build/src/state-types';
import { ModeName, MusicScale, NoteName } from '@power-chord/music-theory';
import iocContainer from 'ez-ioc';
import { useEffect, useRef, useState } from 'react';
import { Key } from 'ts-key-enum';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useAudioLib } from '../../hooks/useAudioLib';
import { useConst } from '../../hooks/useConst';
import { useEventListener } from '../../hooks/useEventListener';
import { useLogger } from '../../hooks/useLogger';
import { useStateService } from '../../hooks/useStateService';
import { TYPES } from '../../lib/init-dependencies';
import FilePicker, { FilePickerOperation } from '../common/FilePicker';
import RenderWhen from '../common/RenderWhen';
import ChordList from './ChordList';
import ProgressionChordSelector from './ProgressionChordSelector';
import ProgressionControls from './ProgressionControls';
import ProgressionToolbar from './ProgressionToolbar';
import "./ProgressionView.scss";

const KEY_1 = 49;
const KEY_7 = 55;

export default function ProgressionView() {
  const analytics = useAnalytics();
  const logger = useLogger("ProgressionBuilderView");
  const stateService = useStateService();
  const audioLib = useAudioLib("piano");
  const fileService = useConst(() => iocContainer.resolve<FileService>(TYPES.ProgressionFileSvc));
  const sequencer = useConst(() => iocContainer.resolve<Sequencer>(TYPES.PianoSequencer));
  const controller = useConst(() => new ProgressionController(stateService.getState().progression));

  // Stops keyup events when editing BPM
  const bpmHasFocus = useRef(false);

  const [progression, setProgression] = useState<ChordProgression>(stateService.getState().progression);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioPercent, setAudioPercent] = useState(0);
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [filePickerOperation, setFilePickerOperation] = useState<FilePickerOperation>("open");
  const [playbackIndex, setPlaybackIndex] = useState(-1);

  // Init sequencer
  useEffect(() => {
    sequencer.isRepeatOn = false;
    sequencer.onStop = () => setIsPlaying(false);
    sequencer.onTick = i => setPlaybackIndex(i);
    return () => {
      if (isPlaying) stopPlayback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEventListener("keyup", e => onKeyUp(e as KeyboardEvent));
  // Stop browser from handling events
  useEventListener("keydown", e => onKeyDown(e as KeyboardEvent));

  function onKeyUp(e: KeyboardEvent) {
    if (showFilePicker) return;

    if (bpmHasFocus.current) {
      bpmHasFocus.current = false;
      return;
    }

    switch (e.key) {
      case Key.Backspace:
      case Key.Delete:
        deleteClicked();
        break;
      case Key.Escape:
        clearClicked();
        break;
      case " ":
        if (isPlaying) {
          stopPlayback();
        }
        else {
          startPlayback();
        }
        break;
      case "o":
        if (e.ctrlKey) onOpenFile();
        break;
      case "s":
        if (e.ctrlKey) onSaveFile();
        break;
      case "z":
        if (e.ctrlKey) onUndo();
        break;
      case "y":
        if (e.ctrlKey) onRedo();
        break;
      default:
        if (e.key.length === 1) {
          const keyNum = e.key.charCodeAt(0);
          if (keyNum >= KEY_1 && keyNum <= KEY_7) {
            addChordClicked(controller!.chordsInKey[keyNum - KEY_1], 4);
          }
        }
        break;
    }
  }

  function onKeyDown(e: KeyboardEvent): void {
    if (e.ctrlKey) {
      switch (e.key) {
        case "o":
        case "s":
        case "z":
        case "y":
          e.preventDefault();
      }
    }
  }
  
  /**
   * Applies changes to progression and saves to persistent storage
   * @param changes ChordProgression changes
   */
  function saveState(): void {
    stateService.setProgressionState(controller!.progression);
    setProgression(controller!.progression);
    // Any change should cause playback to stop
    stopPlayback();
  }

  function isMinorKey(): boolean {
    return progression.quality as ModeName === "minor";
  }

  return (
    <div className="progression-view">
      <h2>Progression Builder/Transposer</h2>
      <ProgressionControls
        isPlaying={isPlaying}
        isLoading={isLoading}
        audioPercent={audioPercent}
        beatsPerMinute={progression.beatsPerMinute}
        tonic={progression.key as NoteName}
        mode={progression.quality as ModeName}
        onBPMChanged={bpmChanged}
        onKeyChanged={keyChanged}
        onStartPlayback={startPlayback}
        onStopPlayback={stopPlayback} />
      <ProgressionToolbar
        isPlaying={isPlaying}
        canRedo={controller!.canRedo}
        canUndo={controller!.canUndo}
        onClear={clearClicked}
        onDelete={() => deleteClicked()}
        onOpenFile={onOpenFile}
        onSaveFile={onSaveFile}
        onRedo={onRedo}
        onUndo={onUndo} />
      <ChordList
        items={progression.items}
        currentBeat={playbackIndex}
        onDeleteClicked={idx => deleteClicked(idx)}
        onAddBeat={(idx, cnt) => addBeat(idx, cnt)} />
      <ProgressionChordSelector
        chordsInKey={controller!.chordsInKey}
        isMinorKey={isMinorKey()}
        suggestedChords={controller!.suggestedChords}
        onSelect={(c, b) => addChordClicked(c, b)} />

      <RenderWhen condition={showFilePicker}>
        <FilePicker operation={filePickerOperation}
          fileService={fileService}
          fileName={filePickerOperation === "save" ? progression.name : ""}
          description={filePickerOperation === "save" ? progression.description : ""}
          onClose={(f, d) => onFileChosen(f, d)} />
      </RenderWhen>
    </div>
  );

  function onRedo(): void {
    if (controller!.redo()) {
      saveState();
    }
  }

  function onUndo(): void {
    if (controller!.undo()) {
      saveState();
    }
  }

  function onOpenFile(): void {
    analytics.logEvent("progression", "open");

    if (isPlaying) {
      stopPlayback();
    }

    setShowFilePicker(true);
    setFilePickerOperation("open");
  }

  function onSaveFile(): void {
    analytics.logEvent("progression", "save");
    setShowFilePicker(true);
    setFilePickerOperation("save");
  }

  function onFileChosen(name: string, descr: string): any {
    setShowFilePicker(false);

    if (name) {
      if (filePickerOperation === "save") {
        saveProgression(name, descr);
      }
      else {
        openProgression(name);
      }
    }
  }

  function saveProgression(name: string, descr: string) {
    const prog = progression;
    prog.name = name;
    prog.description = descr;
    logger.debug("save", progression);
    fileService.set(name, progression);
  }

  function openProgression(name: string): any {
    fileService.get<ChordProgression>(name)
      .then(prog => {
        logger.debug("open", prog);
        controller!.setProgression(prog);
        saveState();
      })
      .catch(() => alert("File not found: " + name));
  }

  function stopPlayback(): void {
    setIsPlaying(false);
    setPlaybackIndex(-1);
    sequencer.stop();
  }

  function startPlayback(): void {
    if (progression.items.length > 0 && !isLoading && !isPlaying) {
      analytics.logEvent("progression", "play");
      setIsPlaying(true);

      if (audioLib.isLoaded) {
        setTimeout(playProgression, 0);
      }
      else {
        loadAudio().then(() => {
          setTimeout(playProgression, 0);
        });
      }
    }
  }

  function deleteClicked(idx?: number): void {
    if (controller!.deleteItem(idx)) {
      analytics.logEvent("progression", "remove");
      saveState();
    }
    else {
      logger.error("Invalid index for delete", idx);
    }
  }

  function addBeat(idx: number, cnt: number): void {
    if (controller!.addBeat(idx, cnt)) {
      analytics.logEvent("progression", "add-beat");
      saveState();
    }
  }

  function addChordClicked(chord: string, beats: number): void {
    analytics.logEvent("progression", "add-chord", chord);
    controller!.addItem(chord, beats);
    saveState();
  }

  function clearClicked(): void {
    if (isPlaying) {
      return;
    }

    analytics.logEvent("progression", "reset");
    controller!.clear();
    saveState();
  }

  function bpmChanged(bpm: number): void {
    if (isFinite(bpm)) {
      controller!.setBeatsPerMinute(bpm);
      saveState();
      bpmHasFocus.current = true;
    }
  }

  function keyChanged(key: MusicScale): void {
    logger.debug("keySelected", key);
    analytics.logEvent("progression", "key", key.name);
    if (controller!.setKey(key)) {
      saveState();
    }
  }

  function loadAudio(): Promise<void> {
    if (isLoading) return Promise.reject();

    setIsLoading(true)

    return audioLib.load(pct => setAudioPercent(pct))
      .catch(err => {
        alert("Error loading audio: " + err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function playProgression(): void {
    const rows = progressionToSequencerRows(progression);
    sequencer.start(progression.beatsPerMinute, rows);
  }
}
