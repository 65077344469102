import React, { ReactNode } from "react";

export type SplitDirection = "horizontal" | "vertical";

type SplitContainerProps = {
  direction: SplitDirection;
  topWeight?: number;
  bottomWeight?: number;
  leftWeight?: number;
  rightWeight?: number;
  children: ReactNode[];
};

/**
 * A split container contains two panes that will be displayed next to each other
 * either horizontally or vertically 
 */
export default function SplitContainer(props: SplitContainerProps) {
  if (props.children.length > 2) throw new Error("A SplitContainer can only have two children");
  
  const flexDirection = props.direction === "horizontal" ? "column" : "row";
  const tlWeight = props.topWeight ?? props.leftWeight;
  const brWeight = props.bottomWeight ?? props.rightWeight;

  return (
    <div className={"split-container " + props.direction} style={{ display: "flex", flexDirection: flexDirection }}>
      <div className={flexDirection === "column" ? "top" : "left"} style={tlWeight ? { flex: tlWeight } : {}}>
        {props.children[0]}
      </div>
      <div className={flexDirection === "column" ? "bottom" : "right"} style={brWeight ? { flex: brWeight } : {}}>
        {props.children[1]}
      </div>
    </div>
  );
}