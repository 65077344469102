export class Point {
    x: number;
    y: number;

    constructor(x: number = 0, y: number = 0) {
        this.x = x;
        this.y = y;
    }
}

export class Rectangle {
    x: number;
    y: number;
    width: number;
    height: number;

    constructor(x: number = 0, y: number = 0, w: number = 0, h: number = 0) {
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
    }

    top() { return this.y; }
    bottom() { return this.y + this.height; }
    left() { return this.x; }
    right() { return this.x + this.width }

    /** Determines if this rect intersects with another */
    intersects(rect: Rectangle) {
        var result =
            this.x < rect.x + rect.width &&
            this.x + this.width > rect.x &&
            this.y < rect.y + rect.height &&
            this.y + this.height > rect.y;
        return result;
    }

    /** Determines if this rect contains a point */
    contains(point: Point): boolean {
        var result =
            this.x < point.x &&
            this.x + this.width > point.x &&
            this.y < point.y &&
            this.y + this.height > point.y;
        return result;
    }
}
