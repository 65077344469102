import React from "react";

function ClearIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.356 49.356">
      <path
        d="M7.97 49.356h33.417c1.103 0 2-.897 2-2V11.701c0-.843-.46-1.857-1.093-2.411L32.735.907C32.135.382 31.121 0 30.324 0H7.97c-1.103 0-2 .897-2 2v45.355c0 1.104.896 2.001 2 2.001zM32.304 4.519l5.881 5.159h-5.882l.001-5.159zM8.97 3h20.333v7.678c0 1.103.897 2 2 2h9.084v33.678H8.97V3z"
      ></path>
      <path
        d="M7.079 214.85l25.905 26.276c9.536 9.674 25.106 9.782 34.777.252l56.559-55.761 55.739 56.548c9.542 9.674 25.112 9.782 34.78.246l26.265-25.887c9.674-9.536 9.788-25.106.246-34.786l-55.742-56.547 56.565-55.754c9.667-9.536 9.787-25.106.239-34.786L216.52 8.374c-9.541-9.667-25.111-9.782-34.779-.252l-56.568 55.761L69.433 7.33C59.891-2.338 44.32-2.452 34.65 7.078L8.388 32.97c-9.674 9.542-9.791 25.106-.252 34.786l55.745 56.553-56.55 55.767c-9.674 9.53-9.791 25.106-.252 34.774z"
        transform="translate(11.622 14.843) scale(.1047)"
      ></path>
    </svg>
  );
}

export default ClearIcon;