import { Sequencer, SequencerRow } from "../sequencer";
import * as analog from "analogging";
import * as Tone from "tone";
import { ToneInstrument } from "./instruments/tone-instrument";

export class ToneSequencer implements Sequencer {
    private readonly logger = analog.getLogger("ToneSequencer");
    // Used to start/stop the transport
    private scheduleId?: number;
    private index = 0;

    beatsPerMinute = 120;
    rows: SequencerRow[] = [];

    get isPlaying(): boolean {
        return (this.scheduleId != null);
    }

    isRepeatOn = false;
    
    onTick?: (num: number, time?: number) => void;
    onStop?: () => any;
    
    constructor(readonly instrument: ToneInstrument) {
    }

    start(beatsPerMinute: number, rows: SequencerRow[]): void {
        Tone.Transport.bpm.value = this.beatsPerMinute = beatsPerMinute;
        this.rows = rows;
        this.index = 0;
        if (!this.isPlaying) {
            this.logger.debug("Starting");
            Tone.start().then(() => {
                this.scheduleId = Tone.Transport.scheduleRepeat(time => this.playSequence(time), "4n");
                Tone.Transport.start();
                this.logger.debug("Started sequencer", this.scheduleId);
            });
        }
    }

    resume(beatsPerMinute: number, rows: SequencerRow[]): void {
        if (this.isPlaying) {
            Tone.Transport.bpm.value = beatsPerMinute;
            this.rows = rows;
        }
    }

    stop(): void {
        if (this.isPlaying) {
            this.logger.debug("Stopping sequencer", this.scheduleId);
            Tone.Transport.clear(this.scheduleId!);
            Tone.Transport.stop();
            this.scheduleId = undefined;
        }

        if (this.onStop) {
            this.onStop();
        }
    }

    private playSequence(time: number): void {
        if (this.onTick) {
            this.onTick(this.index);
        }

        for (const instr of this.rows) {
            if (instr.name && instr.beats[this.index]) {
                // We Have to get the player because we need to pass in the time to get accurate timing
                const player = this.instrument.getPlayer(instr.name);
                if (player)
                    player.start(time);
                else
                    this.logger.error("Unable to get player for", instr.name);
            }
        }

        if (++this.index >= this.rows[0].beats.length) {
            if (this.isRepeatOn) {
                this.index = 0;
            }
            else {
                this.stop();
            }
        }
    }
}