import { getNoteAtPosition } from "@local/power-chord-lib/build/src/guitar/guitar-controller";
import { GuitarPositionInfo } from "@local/power-chord-lib/build/src/state-types";
import { Note } from "@power-chord/music-theory";
import React, { useEffect, useRef } from "react";
import GuitarRenderer from "./guitar-renderer";

type FretboardProps = {
  width: string | number;
  height: string | number;
  numFrets: number;
  openNotes: Note[];
  hintPositions: GuitarPositionInfo[];
  selectedPositions: GuitarPositionInfo[];
  /** Called when a fret position is selected */
  onSelect: (pos: GuitarPositionInfo) => void;
  /** Called when a fret position is hovered */
  onHover: (pos: GuitarPositionInfo) => void;
  /** Called when the pointer leaves the fretboard */
  onLeave: () => void;
};

export default function Fretboard(props: FretboardProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const guitarRenderer = useRef<GuitarRenderer>();

  useEffect(() => {
    if (canvasRef.current) {
      guitarRenderer.current = new GuitarRenderer(canvasRef.current, props.openNotes.length, props.numFrets);
    }
  }, [props.numFrets, props.openNotes.length]);

  // Update when hint position changes
  useEffect(() => {
    if (guitarRenderer.current) {
      guitarRenderer.current.clearHintPositions();
      if (props.hintPositions.length > 0) {
        guitarRenderer.current.setHintPosition(...props.hintPositions);
      }
    }
  }, [props.hintPositions]);

  // Update when selected positions change
  useEffect(() => {
    if (guitarRenderer.current) {
      guitarRenderer.current.clearPositions().setPosition(...props.selectedPositions);
    }
  }, [props.selectedPositions]);

  return (
    <div className="fretboard">
      <canvas ref={canvasRef}
        width={props.width} height={props.height}
        onPointerDown={e => pointerDown(e)}
        onPointerUp={e => pointerUp(e)}
        onPointerMove={e => pointerMove(e)}
        onPointerLeave={e => pointerLeave(e)}>
      </canvas>
    </div>
  );

  function pointerDown(ev: React.PointerEvent<HTMLCanvasElement>): void {
    if (ev.isPrimary) {
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  function pointerUp(ev: React.PointerEvent<HTMLCanvasElement>): void {
    if (ev.isPrimary) {
      ev.stopPropagation();
      ev.preventDefault();

      const pos = getPositionUnderPointer(ev);
      if (pos) {
        props.onSelect(pos);
      }
    }
  }

  function pointerLeave(e: React.PointerEvent<HTMLCanvasElement>): void {
    if (e.isPrimary) {
      props.onLeave();
    }
  }

  function pointerMove(ev: React.PointerEvent<HTMLCanvasElement>): void {
    if (ev.isPrimary) {
      ev.stopPropagation();
      ev.preventDefault();

      const pos = getPositionUnderPointer(ev);
      if (pos) {
        props.onHover(pos);
      }
    }
  }

  function getPositionUnderPointer(ev: React.PointerEvent<HTMLCanvasElement>): GuitarPositionInfo | undefined {
    const canvas = ev.target as HTMLElement;
    const bounds = canvas.getBoundingClientRect();
    const position = guitarRenderer.current!.getPositionAt(ev.clientX - bounds.left, ev.clientY - bounds.top);
    if (position) {
      position.note = getNoteAtPosition(position, props.openNotes);
    }
    return position;
  }
}
