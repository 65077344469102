import * as React from 'react';
import { normalize } from '@local/power-chord-lib/build/src/utils';
import RepeatIcon from '../common/icons/RepeatIcon';
import SquareIcon from '../common/icons/SquareIcon';
import TriangleIcon from '../common/icons/TriangleIcon';
import PopupMenuButton from '../common/PopupMenuButton';
import RenderWhen from '../common/RenderWhen';
import ToggleSwitch from '../common/ToggleSwitch';
import { RhythmLib } from '@local/power-chord-lib/build/src/rhythm/rhythm-lib';

const MAX_BAR_COUNT = 16;
const MAX_BEATS_PER_BAR = 16;
const MAX_UNITS_PER_BEAT = 8;
const MAX_BPM = 240;

export type RhythmControlsProps = {
  barCount: number;
  beatsPerBar: number;
  unitsPerBeat: number;
  beatsPerMinute: number;
  isRepeatOn: boolean;
  isPlaying: boolean;
  isLoading: boolean;
  audioPercent: number;
  onBarCountChanged: (count: number) => any;
  onBeatPerBarChanged: (count: number) => any;
  onUnitsPerBeatChanged: (npb: number) => any;
  onBPMChanged: (bpm: number) => any;
  onStartPlayback: () => any;
  onStopPlayback: () => any;
  onRepeatChanged: (on: boolean) => any;
  onPreset: (name: string) => any;
};

export default function RhythmControls(props: RhythmControlsProps) {
  return (
    <div className="controls">
      <section>
        <label>Presets:
          <PopupMenuButton
            menuItems={RhythmLib.map(r => [r.name, r.name, r.description || ""])}
            value=""
            onChange={presetSelected} />
        </label>
      </section>
      <section>
        <label title="Number of bars">
          Bars:
          <input onChange={barCountChanged} type="number" min="1" max={MAX_BAR_COUNT} step="1" value={props.barCount}></input>
        </label>
        <label title="Beats per bar">
          Beats per bar:
          <input onChange={beatPerBarChanged} type="number" min="1" max={MAX_BEATS_PER_BAR} step="1" value={props.beatsPerBar}></input>
        </label>
        <label title="Beat subdivisions">
          /
          <input onChange={unitsPerBeatChanged} type="number" min="1" max={MAX_UNITS_PER_BEAT} step="1" value={props.unitsPerBeat}></input>
        </label>
      </section>
      <section>
        <RenderWhen condition={props.isPlaying}>
          <button onClick={stopClicked} title="Stop (Space)"><SquareIcon /> Stop</button>
        </RenderWhen>
        <RenderWhen condition={!props.isPlaying}>
          <button onClick={playClicked} title="Play (Space)"><TriangleIcon /> Play</button>
        </RenderWhen>
        <label title="Beats per minute">BPM:
          <input onChange={bpmChanged} type="number" min="1" max={MAX_BPM} step="1" value={props.beatsPerMinute}></input>
        </label>
        <label>
          Repeat
          <ToggleSwitch checked={props.isRepeatOn} onChange={toggleRepeat} icons={{ checked: <RepeatIcon />, unchecked: null }} />
        </label>
        <RenderWhen condition={props.isLoading}>
          <label>Loading: <progress title="Loading audio" value={props.audioPercent}></progress></label>
        </RenderWhen>
      </section>
    </div>
  );

  function presetSelected(name: string): any {
    props.onPreset(name);
  }

  function toggleRepeat(): void {
    props.onRepeatChanged(!props.isRepeatOn);
  }

  function barCountChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    // Normalize to between 1 and max
    const count = normalize(parseInt(e.target.value), 1, MAX_BAR_COUNT);
    if (isFinite(count)) {
      props.onBarCountChanged(count);
    }
  }

  function beatPerBarChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    // Normalize to between 1 and max
    const count = normalize(parseInt(e.target.value), 1, MAX_BEATS_PER_BAR);
    if (isFinite(count)) {
      props.onBeatPerBarChanged(count);
    }
  }

  function unitsPerBeatChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    // Normalize to between 1 and max
    const count = normalize(parseInt(e.target.value), 1, MAX_UNITS_PER_BEAT);
    if (isFinite(count)) {
      props.onUnitsPerBeatChanged(count);
    }
  }

  function bpmChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    // Normalize to between 1 and max
    const bpm = normalize(parseInt(e.target.value), 1, MAX_BPM);
    if (isFinite(bpm)) {
      props.onBPMChanged(bpm);
    }
  }

  function playClicked(): void {
    if (props.barCount > 0 && !props.isPlaying) {
      props.onStartPlayback();
    }
  }

  function stopClicked(): void {
    props.onStopPlayback();
  }
}
