import { getNote, Note, NoteName } from "@power-chord/music-theory";
import NoteSelector from "../common/NoteSelector";
import PopupMenuButton, { NameValueTitle } from "../common/PopupMenuButton";

const OCTAVE_MENU_ITEMS: NameValueTitle[] = [["1"], ["2"], ["3"], ["4"], ["5"], ["6"], ["7"]];

type NoteOctaveSelectorProps = {
  label: string;
  note: Note;
  onChange: (note: Note) => void;
};

export default function NoteOctaveSelector(props: NoteOctaveSelectorProps) {
  return (
    <>
      <label>{props.label} </label>
      <NoteSelector selectedNote={props.note.name} onChange={onNoteChanged} />
      <PopupMenuButton
        menuItems={OCTAVE_MENU_ITEMS}
        value={props.note.octave.toString()}
        onChange={onOctaveChanged} />
    </>
  );

  function onOctaveChanged(v: string): void {
    props.onChange(getNote(props.note.name, parseInt(v)));
  }

  function onNoteChanged(n: NoteName): void {
    props.onChange(getNote(n, props.note.octave));
  }
}