import { CanvasRenderer } from "../common/canvas-renderer";

export interface IGuitarTabRenderer {
    setTab(positions: number[]): void;
    render(): CanvasRenderer;
}

export class GuitarTabRenderer extends CanvasRenderer implements IGuitarTabRenderer {
    private positions: number[] = [0, 0, 0, 0, 0, 0];
    private _top = 11.5;
    private _lineGap = 12;

    constructor(canvas: HTMLCanvasElement) {
        super(canvas);
        this.setTab(this.positions);
        this.render();
    }

    public setTab(positions: number[]): void {
        this.positions = positions;
        this.render();
    }

    protected draw(): GuitarTabRenderer {
        this.context.save()
            .clear()
            .fillStyle("whitesmoke")
            .fillRect(0, 0, this.canvas.width, this.canvas.height)
            .fillStyle("black")
            .strokeStyle("#888");
        this.drawLines()
            .drawTAB()
            .drawNumbers();
        this.context.restore();

        return this;
    }

    private drawNumbers(): GuitarTabRenderer {
        this.context
            .font("bold 12px sans-serif")
            .textBaseline("middle");

        for (let i = 0; i < this.positions.length; i++) {
            let text = this.positions[i] < 0 ? "X" : this.positions[i].toString();
            this.context.fillText(text, 40, this._top + i * this._lineGap);
        }

        return this;
    }

    private drawTAB(): GuitarTabRenderer {
        this.context
            .font("bold 22px sans-serif")
            .textBaseline("top")
            .fillText("T", 4, this._top)
            .fillText("A", 4, this._top + 19)
            .fillText("B", 4, this._top + 38)
            ;
        return this;
    }

    private drawLines(): GuitarTabRenderer {
        for (let i = 0; i < 6; i++) {
            let y = this._top + (i * this._lineGap);
            this.context.drawLine(0, y, this.context.canvas.width, y);
        }

        this.context.drawLine(0, 0, 0, this.context.canvas.height);

        return this;
    }
}
