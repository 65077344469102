import * as analog from "analogging";
import { ViewName } from "../state-types";

export default interface AnalyticsService {
    logEvent(viewName: ViewName, action: string, label?: string, value?: number): void;
    logEvent(viewName: ViewName, action: string, labelFn?: () => string, value?: number): void;
    logScreenView(viewName: ViewName): AnalyticsService;
    logException(err: Error): void;
}

type GtagFunc = (event: string, action: string, data: any) => void;

declare var gtag: GtagFunc;

export class GoogleAnalytics implements AnalyticsService{
    private readonly logger = analog.getLogger("analytics");
    readonly gtag: GtagFunc;

    constructor(public isEnabled: boolean, _gtag: GtagFunc = gtag) {
        this.gtag = _gtag;
    }

    logEvent(viewName: ViewName, action: string, label?: string, value?: number): void;
    logEvent(viewName: ViewName, action: string, labelFn?: () => string, value?: number): void;
    logEvent(viewName: ViewName, action: string, labelOrFn?: string|(() => string), value?: number): void {
        try {
            let label = (typeof labelOrFn === "function") ? labelOrFn() : labelOrFn as string;
            this.logger.debug(`Analytics event(${viewName}, ${action}, ${label}, ${value})`);
            if (!this.isEnabled) return;

            this.gtag("event", action, {
                event_category: viewName,
                event_label: label,
                value: value
            });
        }
        catch (err) {
            this.logger.warn(err);
            this.logException(err as Error);
        }
    }
    
    logScreenView(viewName: ViewName): GoogleAnalytics {
        this.logger.debug(`Analytics screen(${viewName})`);
        if (this.isEnabled) {
            this.gtag('event', "screen_view", { "screen_name": viewName});
        }
        return this;
    }

    logException(err: Error): void {
        this.logger.debug("Analytics exception:", err.message, err.stack);
        if (this.isEnabled) {
            this.gtag('event', "exception", { "description": err.message + ": " + err.stack});
        }
    }
}
