import * as analog from "analogging";
import { hasUrlSearchParam, isLocalHost } from "@local/power-chord-lib/build/src/browser-utils";

export function initLogging() {
    try {
        const logLevel = getLogLevel();

        analog.configure({
            loggers: [
                { name: "", level: logLevel }
            ]
        });
    
        analog.getLogger().info("Logging started");
    }
    catch (err) {
        console.error((err as any).message);
    }
}

function getLogLevel(): analog.LogLevel {
    const levelParam = window.location.search.slice(1).split("&").find(x => x.indexOf("log") === 0);
    if (levelParam) {
        const value = levelParam.split("=")[1];
        if (value) {
            return (analog.LogLevel as any)[value] as analog.LogLevel;
        }
    }

    return (isLocalHost || hasUrlSearchParam("debug") ? analog.LogLevel.Debug : analog.LogLevel.Error);
}
