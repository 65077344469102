import * as React from 'react';
import "./FilePicker.scss";
import FileService from '@local/power-chord-lib/build/src/services/file-service';
import { Key } from 'ts-key-enum';
import { RhythmSequence } from '@local/power-chord-lib/build/src/state-types';
import { useEffect, useRef, useState } from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import RenderWhen from './RenderWhen';

export type FilePickerOperation = "save" | "open";

export type FilePickerProps = {
  operation: FilePickerOperation;
  title?: string;
  fileName?: string;
  description?: string;
  fileService: FileService;
  onClose: (fileName: string, description: string) => any;
};

export type FilePickerState = {
  files: string[];
  fileName: string;
  description: string;
}

export default function FilePicker(props: FilePickerProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (props.fileName) {
      setFileName(props.fileName);
    }
    if (props.description) {
      setDescription(props.description);
    }
  }, [props.description, props.fileName]);

  useEffect(() => {
    props.fileService.list().then(files => setFiles(files));
    inputRef.current!.focus();
  }, [props.fileService])

  useEventListener("keyup", e => onKeyUp(e as unknown as React.KeyboardEvent<HTMLElement>));

  return (
    <div className="file-picker">
      <div className="content">
        <header>{props.title || "Choose a file"}</header>
        <ul>
          <RenderWhen condition={files.length === 0}>
            <li>No files</li>
          </RenderWhen>
          <RenderWhen condition={files.length > 0}>
            {files.map(f => <li key={f} onClick={() => onItemClicked(f)} onDoubleClick={() => onItemSelected(f)}>{f}</li>)}
          </RenderWhen>
        </ul>
        <label>
          File name:
          <input type="text" ref={inputRef} autoFocus
            value={fileName}
            onChange={e => setFileName(e.target.value)} />
        </label>
        <label>
          Description:
          <RenderWhen condition={props.operation === "save"}>
            <input type="text"
              value={description}
              onChange={e => setDescription(e.target.value)}
              onKeyUp={e => onKeyUp(e)} />
          </RenderWhen>
          <RenderWhen condition={props.operation !== "save"}>
            <span className="description">&nbsp;{description || "no description"}</span>
          </RenderWhen>
        </label>
        <div className="buttons">
          <button className="ok" onClick={() => onOkClicked()}>{props.operation === "open" ? "Open" : "Save"}</button>
          <button onClick={() => onCancelClicked()}>Cancel</button>
        </div>
      </div>
    </div>
  );

  function onKeyUp(e: React.KeyboardEvent<HTMLElement>): void {
    if (e.key === Key.Enter) {
      onOkClicked();
    }
    else if (e.key === Key.Escape) {
      onCancelClicked();
    }
    e.preventDefault();
    e.stopPropagation();
  }

  function onItemClicked(fileName: string): void {
    if (props.operation === "open") {
      props.fileService.get<RhythmSequence>(fileName).then(file => {
        setFileName(fileName);
        setDescription(file.description || "no description")
      });
    }
    else {
      setFileName(fileName);
    }
  }

  function onItemSelected(f: string): void {
    onItemClicked(f);
    onOkClicked();
  }

  function onOkClicked(): void {
    props.onClose(fileName, description);
  }

  function onCancelClicked(): void {
    return props.onClose("", "");
  }
}
