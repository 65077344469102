import { useNavigate } from 'react-router-dom';
import { useAnalytics } from '../../hooks/useAnalytics';
import ArrowsIcon from '../common/icons/ArrowsIcon';
import LogoIcon from '../common/icons/LogoIcon';
import PowerChordIcon from '../common/icons/PowerChordIcon';
import "./LandingView.scss";

const currentYear = new Date().getFullYear();

export default function LandingView() {
  const analytics = useAnalytics();
  const navigate = useNavigate();

  return (
    <>
      <div className="splash">
        <div className="content">
          <PowerChordIcon></PowerChordIcon>
          <div className="tagline">
            Tools for Musicians, Songwriters and Students
          </div>
        </div>
        <div className="footer">
          <div className="button" onClick={e => scrollDown()}>
            <ArrowsIcon></ArrowsIcon>
          </div>
        </div>
      </div>
      <div className="content-top"></div>
      <div className="content">
        <div className="row">
          <h1>
            The tools you need to write, learn and practice music
          </h1>
        </div>
        {/* <div className="row">
                <ul>
                    <li>Piano and Guitar tools helps you find chord positions and names</li>
                    <li>Rhythm sequencer lets you build your own beats or use as a metronome</li>
                    <li>Progression builder helps you build and transpose progressions</li>
                    <li>Music Theory tool helps you find notes and chords in any key</li>
                </ul>
            </div> */}
        <div className="row">
          <div className="column">
            <img src="images/keyboard-view.jpg" alt=""></img>
          </div>
          <p className="column">
            Use the keyboard to play notes and chords with the touch of a finger or mouse.
            Look up chords by name and inversion, or find chord names by simply selecting notes on the keyboard.
            Want to see all the notes in a key? We've got you covered.
          </p>
        </div>
        <div className="row">
          <p className="column">
            Play notes on the guitar with the touch of a finger or mouse.
            Look up guitar and ukulele chords by name and variation, or find chord names by simply selecting notes on the fretboard.
            Want to see all the notes in a key on the fretboard? No problem.
          </p>
          <div className="column">
            <img src="images/guitar-view.jpg" alt=""></img>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img src="images/progression-view.jpg" alt=""></img>
          </div>
          <p className="column">
            Writing a song and need help building that perfect chord progression?
            Or maybe you just want to transpose the chords of a song to another key.
            The progression builder tool will help you do all that and more.
          </p>
        </div>
        <div className="row">
          <p className="column">
            Find your vocal range using your device's microphone.
            Practice scales and see if you're singing in tune and if not how far off.
            Or just hum a tune if you want to find out what the notes are.
          </p>
          <div className="column">
            <img src="images/vocals-view.jpg" alt=""></img>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img src="images/theory-view.jpg" alt=""></img>
          </div>
          <p className="column">
            Take your understanding of music to the next level in the music theory tool.
            There you'll find the circle of fifths displayed in any one of the seven modes.
            You'll see all of the notes and triads in the selected key as well as the key signature.
          </p>
        </div>
        <div className="row">
          <p className="column">
            Need a metronome or beat to keep you steady?
            In the rhythm sequencer select from built in rhythms or build beats of your own using your finger or mouse.
            Choose from a wide variety percussion instruments to get the right sound.
          </p>
          <div className="column">
            <img src="images/sequencer-view.jpg" alt=""></img>
          </div>
        </div>
        <div className="video">
          <p>Watch this short introduction to learn more about the features of Power Chord.</p>
          <iframe title="PowerChord Intro" width="560" height="315" src="https://www.youtube.com/embed/fdisAV2B3hs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className="call-to-action">
          <h2>
            Whether you're a musician, songwriter or student,<br></br>
            take your music to the next level with Power Chord!
          </h2>
          <button className="start" onClick={e => startApp()}>
            Start Power Chord <ArrowsIcon></ArrowsIcon>
          </button>
          <div>
            <LogoIcon></LogoIcon>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <LogoIcon></LogoIcon>
        <p>
          Copyright &copy; {currentYear} JM Gustafson
        </p>
        <p>
          <a href="https://worldtreesoftware.com">WorldTree Software</a>
        </p>
      </div>

      <div className="top-nav">
        <LogoIcon></LogoIcon>
        <button className="start" onClick={e => startApp()}>
          Start Power Chord <ArrowsIcon></ArrowsIcon>
        </button>
      </div>
    </>
  );

  function scrollDown(): void {
    analytics.logEvent("landing", "scroll");
    document.querySelector(".content-top")!.scrollIntoView(true);
  }

  function startApp(): void {
    analytics.logEvent("landing", "start-app");
    navigate("/keyboard");
  }
}
