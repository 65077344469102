import { Rectangle, Point } from "@local/power-chord-lib/build/src/geometry";
import { KeyboardDimensions, getKeyboardDimensions, KeyDimensions } from "./keyboard-dimensions";
import getKeyboardColors from "./keyboard-colors";
import * as analog from "analogging";
import { formatAccidentals, unformatAccidentals, getNote, NoteName, Note } from "@power-chord/music-theory";
import { CanvasRenderer } from "../common/canvas-renderer";

// Keyboard layout 1=white, 0=black
const KEY_CONFIG = [1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1];
// Formatted key note names
//                  0    1     2    3     4    5    6     7    8     9    10    11
const KEY_NOTES = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"].map(k => formatAccidentals(k));

export default class KeyboardRenderer extends CanvasRenderer{
    private readonly logger = analog.getLogger("KeyboardRenderer");
    private readonly whiteGradient: CanvasGradient;
    private readonly blackGradient: CanvasGradient;
    private readonly dimensions: KeyboardDimensions;
    private readonly colors = getKeyboardColors();
    private hintedKeys: boolean[] = [];   // keys that are hilited (array will have one value for each key)
    private selectedKeys: boolean[] = []; // keys that are selected (array will have one value for each key)
    private rootNoteName: ("" | NoteName) = "";
    private rootNote?: Note;
    private keys: Rectangle[];

    constructor(canvas: HTMLCanvasElement, private octaves = 2) {
        super(canvas);
        this.dimensions = getKeyboardDimensions(octaves, canvas.width);
        // this.logger.debug(this.dimensions);
        this.whiteGradient = this.createWhiteGradient();
        this.blackGradient = this.createBlackGradient();
        this.keys = this.createPianoKeys();
        this.clearSelectedKeys();
        this.clearHintKeys();
    }

    /**
     * Gets a list of all selected keys
     */
    getSelectedKeys(): number[] {
        const result: number[] = [];
        this.selectedKeys.forEach((k, i) => {
            if (k) {
                result.push(i);
            }
        });
        return result;
    }

    /**
     * Toggles a key selection and return true if the key is selected
     * @param key Number of the key where C0 == 0
     */
    toggleKey(key: number): boolean {
        const newState = !this.isKeySelected(key);
        this.selectKey(key, newState);
        return newState;
    }

    /**
     * Selects or deselects a key
     * @param key Number of the key where C0 == 0
     * @param on If true the key is selected otherwise deselected
     */
    selectKey(key: number, on = true): KeyboardRenderer {
        this.selectedKeys[key] = on;
        return this.render() as KeyboardRenderer;
    }

    selectKeys(...key: number[]): KeyboardRenderer {
        key.forEach(k => this.selectKey(k))
        return this;
    }

    /** 
     * Returns true if a key is selected
     * @param key Number of the key where C0 == 0
     */
    isKeySelected(key: number): boolean {
        return this.selectedKeys[key] || false;
    }

    /** Clears all selected keys */
    clearSelectedKeys(): KeyboardRenderer {
        this.selectedKeys = new Array(this.keys.length).map(() => false);
        return this.render() as KeyboardRenderer;
    }

    setSelectedKeys(...key: number[]): KeyboardRenderer {
        return this.clearSelectedKeys()
            .selectKeys(...key)
            .render() as KeyboardRenderer;
    }

    /**
     * Selects or deselects a hint key
     * @param key Number of the key where C0 == 0
     * @param on If true the key is selected otherwise deselected
     */
    setHintKey(key: number, on = true): KeyboardRenderer {
        this.hintedKeys[key] = on;
        return this.render() as KeyboardRenderer;
    }

    /**
     * Selects one or more hint keys
     * @param key One or more keys to set
     */
    setHintKeys(...key: number[]): KeyboardRenderer {
        let changed = (this.hintedKeys.length === 0);
        for (let i = 0; !changed && i < this.hintedKeys.length; i++) {
            changed = (this.hintedKeys[i] && key.indexOf(i) < 0);
        }

        if (changed) {
            this.clearHintKeys();
            key.forEach(k => this.setHintKey(k));
        }

        return this;
    }

    /** 
     * Returns true if a key is hinted
     * @param key Number of the key where C0 == 0
     */
    isKeyHinted(key: number): boolean {
        return this.hintedKeys[key] || false;
    }

    /** Clears all hint keys */
    clearHintKeys(): KeyboardRenderer {
        this.hintedKeys = [];
        return this.render() as KeyboardRenderer;
    }

    /** Sets the name of the root note */
    setRootNote(rootNote: "" | NoteName): KeyboardRenderer {
        this.rootNote = rootNote !== "" ? getNote(rootNote) : undefined;
        this.rootNoteName = rootNote;
        return this.render() as KeyboardRenderer;
    }

    getRootNote(): "" | NoteName {
        return this.rootNoteName;
    }

    /**
     * Gets the number of the key under the specified point where 0 == C0.
     * If no key returns -1.
     */
    getKeyAt(x: number, y: number): number;
    getKeyAt(p: Point): number;
    getKeyAt(xOrP: Point | number, y?: number): number {
        const point = (typeof xOrP === "number" ? new Point(xOrP, y) : xOrP);

        // Check black keys first sicne they're on top
        for (let i = 0; i < this.keys.length; i++) {
            if (isBlackKey(i) && this.keys[i].contains(point)) {
                return i;
            }
        }

        // Check white keys
        for (let i = 0; i < this.keys.length; i++) {
            if (isWhiteKey(i) && this.keys[i].contains(point)) {
                return i;
            }
        }

        // None found
        return -1;
    }

    private getKeyNoteName(key: number): string {
        return KEY_NOTES[key % KEY_NOTES.length];
    }

    private createPianoKeys(): Rectangle[] {
        let keys = [];
        let lastWhiteKey: Rectangle = new Rectangle(0, 0, -this.dimensions.whiteKey.margin, 0);

        const dimWhite = this.dimensions.whiteKey;
        const dimBlack = this.dimensions.blackKey;

        const keyCount = KEY_CONFIG.length * this.octaves + 1;
        for (let i = 0; i < keyCount; i++) {
            let key: Rectangle;
            if (isWhiteKey(i)) {
                lastWhiteKey = key = this.createWhiteKey(lastWhiteKey, dimWhite);
            }
            else {
                key = this.createBlackKey(lastWhiteKey, dimBlack);
            }
            keys.push(key);
            this.selectedKeys.push(false);
        }

        return keys;
    }

    private createWhiteKey(prevWhiteKey: Rectangle, dimensions: KeyDimensions): Rectangle {
        const x = dimensions.margin + (prevWhiteKey.right() + dimensions.margin);
        const key = new Rectangle(x, 0, dimensions.width, dimensions.length);
        return key;
    }

    private createBlackKey(prevWhiteKey: Rectangle, dimensions: KeyDimensions): Rectangle {
        const x = prevWhiteKey.right() - (dimensions.width) / 2 + dimensions.margin;
        let key = new Rectangle(x, 0, dimensions.width, dimensions.length);
        return key;
    }

    private createWhiteGradient(): CanvasGradient {
        return this.context.createLinearGradient(0, 0, 0, this.dimensions.whiteKey.length,
            { offset: 0, color: this.colors.white.pressed },
            { offset: 1, color: this.colors.white.key });
    }

    private createBlackGradient(): CanvasGradient {
        return this.context.createLinearGradient(0, 0, 0, this.dimensions.blackKey.length,
            { offset: 0, color: this.colors.black.pressed },
            { offset: 1, color: this.colors.black.key });
    }

    ///////////////////////////////////////////////////////////////////////////
    // Drawing methods
    ///////////////////////////////////////////////////////////////////////////

    protected draw(): KeyboardRenderer {
                this.logger.debug("drawing keyboard");
                this.context
                    .save()
                    .font("bold .75em sans-serif")
                    .textBaseline("middle")
                    .textAlign("center")
                    .clear();
                this.drawWhiteKeys()
                    .drawBlackKeys()
                    .drawHintPoints();
                this.context.restore();
        return this;
    }

    private drawWhiteKeys() {
        const dimensions = this.dimensions.whiteKey;
        this.context
            .save()
            .shadowStyle(this.colors.white.shadow, dimensions.shadowX, dimensions.shadowY, dimensions.shadowBlur);

        for (let i = 0; i < this.keys.length; i++) {
            if (isWhiteKey(i)) {
                const key = this.keys[i];
                const isSelected = this.isKeySelected(i);
                if (isSelected) {
                    this.context.fillStyle(this.whiteGradient);
                }
                else {
                    this.context.fillStyle(this.colors.white.key);
                }

                this.context.fillRoundedRect(key.x, key.y, key.width, key.height, 4);
                if (isSelected) {
                    this.drawKeySelectionPoint(key, i);
                }
            }
        }

        this.context.restore();
        return this;
    }

    private drawBlackKeys() {
        const dimensions = this.dimensions.blackKey;
        const colors = this.colors.black;
        const topLeft = (dimensions.width - dimensions.topWidth) / 2;
        const topRight = dimensions.width - 2 * topLeft;

        for (let i = 0; i < this.keys.length; i++) {
            if (isBlackKey(i)) {
                const key = this.keys[i];
                const isSelected = this.isKeySelected(i);
                if (isSelected) {
                    this.context.fillStyle(this.blackGradient);
                }
                else {
                    this.context.fillStyle(this.colors.black.key);
                }

                this.context
                    .save()
                    .shadowStyle(colors.shadow, dimensions.shadowX, dimensions.shadowY, dimensions.shadowBlur)
                    .fillRect(key.x, key.y, key.width, key.height)
                    .restore()
                    .save()
                    .strokeStyle(colors.ridge)
                    .lineWidth(dimensions.ridgeLine)
                    .drawRect(key.x + topLeft, key.y, topRight, key.height - dimensions.bevelLength)
                    .fillStyle(colors.bevel)
                    .fillShape(key.x + topLeft, key.y + key.height - dimensions.bevelLength,
                        key.x + topLeft + topRight, key.y + key.height - dimensions.bevelLength,
                        key.x + key.width, key.y + key.height,
                        key.x, key.y + key.height)
                    .restore()
                    .save()
                    .strokeStyle("black")
                    .lineWidth(1)
                    .drawRect(key.x, key.y, key.width, key.height)
                    .restore();

                if (isSelected) {
                    this.drawKeySelectionPoint(key, i);
                }
            }
        }
        return this;
    }

    private drawKeySelectionPoint(key: Rectangle, i: number): void {
        const note = this.getKeyNoteName(i);
        //this.logger.debug(note, this.rootNote);
        const isRootNote = this.rootNote ? getNote(unformatAccidentals(note) as any).isSameAs(this.rootNote) : false;
        const colors = (isRootNote ? this.colors.selection.root : this.colors.selection.other);
        const x = key.x + key.width / 2;
        const y = key.bottom() - this.dimensions.selection.top;
        this.context
            .save()
            .shadowStyle(this.colors.selection.shadow, 0, 0, 5)
            .fillStyle(colors.bg)
            .fillCircle(x, y, this.dimensions.selection.size)
            .fillStyle(colors.fg)
            .fillText(note, x, y)
            .restore();
    }

    private drawHintPoints(): KeyboardRenderer {
        this.keys.forEach((key, i) => {
            if (this.isKeyHinted(i)) {
                const x = key.x + key.width / 2;
                const y = key.bottom() - this.dimensions.selection.top;
                this.context
                    .strokeStyle(this.colors.selection.hint)
                    .lineWidth(4)
                    .drawCircle(x, y, this.dimensions.selection.size);
                this.drawNoteTooltip(x, y, this.dimensions.selection.size, this.getKeyNoteName(i));
            }
        });

        return this;
    }

    private drawNoteTooltip(x: number, y: number, size: number, note: string) {
        const fontSize = size * 1.5;
        const top = y - size * 3;
        const left = x - size;

        this.context
            .save()
            .shadowStyle("black", 1, 1, 4)
            .fillStyle(this.colors.selection.hintTipBG)
            .fillRect(left, top, size * 2, fontSize)
            .restore()
            .save()
            .fillStyle(this.colors.selection.hintTip)
            .font(`bold ${fontSize}px Segoe UI, sans-serif`)
            .textAlign("center")
            .textBaseline("top")
            .fillText(note, left + size, top)
            .restore();
    }
}

function isWhiteKey(index: number): boolean {
    return Boolean(KEY_CONFIG[index % KEY_CONFIG.length]);
}

function isBlackKey(index: number): boolean {
    return !isWhiteKey(index);
}
