import React from "react";

function NewWindowIcon() {
  return (
    <svg className="new-window" xmlns="http://www.w3.org/2000/svg" viewBox="0 -35 163.54 163.54">
      <path d="M148.45-34.999l-38.418.219c-8.281.047-14.961 6.797-14.914 15.082.047 8.258 6.754 14.918 14.996 14.918h.086l1.918-.012L71.16 36.165c-5.86 5.856-5.86 15.355 0 21.211 2.93 2.93 6.765 4.395 10.605 4.395s7.68-1.465 10.605-4.395l40.957-40.957-.012 1.918c-.043 8.285 6.633 15.035 14.918 15.082h.086c8.242 0 14.95-6.66 14.996-14.914l.219-38.418a15.024 15.024 0 00-4.395-10.691c-2.832-2.832-6.718-4.441-10.69-4.394z"></path>
      <path d="M148.54 53.591c-8.285 0-15 6.715-15 15V98.54H30V-5h29.949c8.285 0 15-6.715 15-15 0-8.281-6.715-15-15-15H15C6.715-35 0-28.281 0-20v133.54c0 8.281 6.715 15 15 15h133.54c8.281 0 15-6.719 15-15V68.591c0-8.285-6.715-15-15-15z"></path>
    </svg>
  );
}

export default NewWindowIcon;
