import { VocalRange } from '@local/power-chord-lib/build/src/state-types';
import { VocalRangeFinder } from '@local/power-chord-lib/build/src/vocals/vocal-range-finder';
import { Note } from '@power-chord/music-theory';
import { AudioTuner } from 'audio-analyzer';
import React, { useEffect, useRef } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useLogger } from '../../hooks/useLogger';
import RenderWhen from '../common/RenderWhen';

type TestRangeControlProps = {
  tuner: AudioTuner;
  range: VocalRange;
  onChange: (range: VocalRange) => void;
};

export default function VocalRangeTest(props: TestRangeControlProps) {
  const analytics = useAnalytics();
  const logger = useLogger("VocalsView");

  const rangeFinder = useRef<VocalRangeFinder>();

  const [isTesting, setIsTesting] = React.useState(false);
  // We track range internally because it can be cancelled
  const [range, setRange] = React.useState(Object.assign({}, props.range));
  const [currentNote, setCurrentNote] = React.useState<Note>();

  // If the external range property changes update the internal range state
  useEffect(() => {
    logger.debug("updating range", props.range);
    setRange(Object.assign({}, props.range));
  }, [logger, props.range]);

  // Starts/stops test range controller
  useEffect(() => {
    if (isTesting) {
      if (!rangeFinder.current) {
        rangeFinder.current = new VocalRangeFinder(props.tuner, setCurrentNote, setRange);
      }

      logger.debug("Starting tuner");
      const finder = rangeFinder.current;
      finder.start(Object.assign({}, props.range));

      return () => {
        logger.debug("Stopping tuner");
        finder.stop();
      };
    }
  }, [isTesting, logger, props.range, props.tuner]);

  return (
    <section className="test-range">
      <label>Low</label>
      <span className={"range-note " + Boolean(range.low)}>
        {range.low ? range.low.toString(true) : "N/A"}
      </span>
      <label>High</label>
      <span className={"range-note " + Boolean(range.high)}>
        {range.high ? range.high.toString(true) : "N/A"}
      </span>
      <RenderWhen condition={!isTesting}>
        <span>
          <button onClick={startTesting} title="Start testing range">Test</button>
          <button onClick={resetRange} title="Reset range">Reset</button>
        </span>
      </RenderWhen>
      <RenderWhen condition={isTesting}>
        <span>
          <label>Current</label>
          <span className="current-note">[{currentNote ? currentNote.toString(true) : "---"}]</span>
          <button onClick={() => stopTesting()}>OK</button>
          <button className="cancel" onClick={() => stopTesting(true)}>Cancel</button>
        </span>
      </RenderWhen>
    </section>
  );

  function resetRange(): void {
    const newRange = {};
    setRange(newRange);
    props.onChange(newRange);
  };

  function startTesting(): void {
    if (!isTesting) {
      logger.debug("Starting testing");

      // Make sure audio context is running
      props.tuner.listener.analyzer.context.resume().then(() => {
        analytics.logEvent("vocals", "range-test");
        setIsTesting(true);
      });
    }
  }

  function stopTesting(cancel = false): void {
    if (isTesting) {
      logger.debug("Stopping testing");
      setIsTesting(false);

      if (cancel) {
        // Reset back to initial range
        setRange(Object.assign({}, props.range));
      }
      else if (range.low && range.high) {
        logger.debug("Committing range", range);
        props.onChange(range);
      }
    }
  }
}
