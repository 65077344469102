import { featureFlags } from "@local/power-chord-lib/build/src/services/feature-flag-service";
import { GuitarPositionInfo } from "@local/power-chord-lib/build/src/state-types";
import { GuitarLookupService, MusicScale, Note, unformatAccidentals } from "@power-chord/music-theory";
import React, { useEffect, useMemo, useRef } from "react";
import { useAudioLib } from "../../hooks/useAudioLib";
import { useLogger } from "../../hooks/useLogger";
import PlayNotesButton from "../common/PlayNotesButton";
import PopupMenuButton, { NameValueTitle } from "../common/PopupMenuButton";
import RenderWhen from "../common/RenderWhen";
import ScaleSelector from "../common/ScaleSelector";
import { ShareLinkModal } from "../common/ShareLinkModal";
import SplitContainer from "../common/SplitContainer";
import ToggleSwitch from "../common/ToggleSwitch";
import SoundIcon from "../common/icons/SoundIcon";
import "../keyboard/KeyboardControls.scss";
import GuitarChordSelector, { ChordInformation } from "./GuitarChordSelector";
import GuitarChordView from "./GuitarChordView";

export type GuitarType = "guitar" | "ukulele";

export type GuitarControlsProps = {
  guitarType: GuitarType;
  hoverString: string;
  hoverFret: string;
  hoverNote: Note | undefined;
  selectedPositions: GuitarPositionInfo[];
  selectedNotes: string;
  chordName: string;
  selectedTab: string;
  soundOn: boolean;
  shareMenuItems: NameValueTitle[];
  guitarLookup: GuitarLookupService;
  stringCount: number;
  onClearSelections: () => any;
  onSoundChanged: (soundOn: boolean) => void;
  onPlayNotes: () => void;
  onChordSelected: (c: ChordInformation) => void;
  onScaleSelected: (scale: MusicScale) => void;
};

let _isAudioLoaded = false;
let _audioError = "";

export default function GuitarControls(props: GuitarControlsProps) {
  const logger = useLogger("GuitarControls");
  const audioLib = useAudioLib("guitar");
  // Used to control when to clear the chord selector
  const selectingChord = useRef(false);
  const selectingKey = useRef(false);

  const [audioLoaded, setAudioLoaded] = React.useState(_isAudioLoaded);
  const [audioError, setAudioError] = React.useState(_audioError);
  const [audioPercent, setAudioPercent] = React.useState(0);
  const [shareLink, setShareLink] = React.useState("");
  const [selectedChord, setSelectedChord] = React.useState<ChordInformation>();
  const [selectedScale, setSelectedScale] = React.useState<MusicScale>();

  const selectedFrets = useMemo(() => {
    // We don't want to set this when a scale is selected
    if (props.selectedPositions.length === props.stringCount) {
      return props.selectedPositions.map(p => p.fret);
    }
  }, [props.selectedPositions, props.stringCount]);

  useEffect(() => {
    if (props.soundOn && !audioLoaded) {
      loadAudio();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioLoaded, props.soundOn]);

  useEffect(() => {
    // When the selected positions change and we didn't select a chord then clear the chord & scale selectors 
    if (!selectingChord.current) {
      setSelectedChord(undefined);
    }
    if (!selectingKey.current) {
      setSelectedScale(undefined);
    }

    selectingChord.current = false;
    selectingKey.current = false;
  }, [props.selectedPositions]);

  function onChordSelected(c: ChordInformation): any {
    setSelectedChord(c);
    selectingChord.current = true;
    props.onChordSelected(c);
  }

  return (
    <div className="controls">
      <div className="string-fret-note">
        <label>String:</label>
        <span>{props.hoverString}</span>
        <label>Fret:</label>
        <span>{props.hoverFret}</span>
        <label>Note:</label>
        <span>{props.hoverNote?.formattedName ?? ""}</span>
        <label>Octave:</label>
        <span>{props.hoverNote?.octave ?? ""}</span>
      </div>

      <SplitContainer direction="vertical" leftWeight={1} rightWeight={1}>
        <SplitContainer direction="vertical" leftWeight={1}>
          <section className="section-0">
            <div className="large">
            <label>Tab:</label>
              <span>{props.selectedTab}</span>
            </div>
            <div>
              <button onClick={() => clearSelections()} title="Clear selections (Escape)">Reset</button>
            </div>
            <div className="chord-selection">
              <label>Select Chord:</label>
              <GuitarChordSelector guitarLookup={props.guitarLookup} chord={selectedChord} onChanged={onChordSelected} />
            </div>
            <div className="chord-selection">
              <label>Select Scale:</label>
              <ScaleSelector
                selectedMode={selectedScale?.mode}
                selectedTonic={selectedScale?.tonic.name}
                selectedScaleType={selectedScale?.scaleType}
                onChange={onScaleSelected} />
            </div>
          </section>
          <section className="section-1">
            <div className="large">
              <label>Chord:</label>
              <span>{props.chordName}</span>
            </div>
            <div className="sound">
              <label>Sound:</label>
              <ToggleSwitch
                checked={props.soundOn}
                onChange={e => toggleSound()}
                icons={{ checked: <SoundIcon on={true} />, unchecked: null }} />
            </div>
            <RenderWhen condition={featureFlags.shareLink && props.shareMenuItems.length > 0}>
              <div className="share">
                <label>Share: </label>
                <PopupMenuButton menuItems={props.shareMenuItems} value="" onChange={v => onShareClicked(v)} />
              </div>
            </RenderWhen>
          </section>
        </SplitContainer>
        <SplitContainer direction="vertical" leftWeight={1}>
          <section className="section-2">
            <div className="large">
            <label>Notes:</label>
              <span>{props.selectedNotes}</span>
            </div>
            <div className="sound">
              <RenderWhen condition={props.selectedPositions.length <= 6}>
                <PlayNotesButton
                  soundOn={props.soundOn}
                  audioError={audioError}
                  audioLoaded={audioLoaded}
                  audioPercent={audioPercent}
                  onClick={props.onPlayNotes} />
              </RenderWhen>
            </div>
          </section>
          <section className="section-3">
            <RenderWhen condition={Boolean(selectedFrets)}>
              <GuitarChordView width="100" height="84" positions={selectedFrets!} />
            </RenderWhen>
          </section>
        </SplitContainer>
      </SplitContainer>
      <RenderWhen condition={Boolean(shareLink)}>
        <ShareLinkModal shareLink={shareLink} onClose={() => setShareLink("")} />
      </RenderWhen>
    </div>
  );

  function onScaleSelected(scale: MusicScale): void {
    setSelectedScale(scale);
    selectingKey.current = true;
    props.onScaleSelected(scale);
  }

  function toggleSound(): void {
    const soundOn = !props.soundOn;
    if (soundOn && !_isAudioLoaded) {
      loadAudio();
    }
    props.onSoundChanged(soundOn);
  }

  function loadAudio(): void {
    audioLib.load(pct => setAudioPercent(pct))
      .then(() => {
        _isAudioLoaded = true;
        setAudioPercent(1);
        setAudioLoaded(true);
      })
      .catch(err => {
        logger.error(err);
        setAudioError(err.message);
      });
  }

  function clearSelections(): void {
    props.onClearSelections();
  }

  function onShareClicked(shareInfo: string): void {
    const parts = shareInfo.split(": ");
    // analytics.logEvent("guitar", "share", parts[0]);
    switch (parts[0]) {
      case "Chord":
        setShareLink(getShareLink(props.guitarType, "chord", unformatAccidentals(parts[1])));
        break;
      case "Scale":
        setShareLink(getShareLink(props.guitarType, "scale", unformatAccidentals(parts[1])));
        break;
      case "Tab":
        setShareLink(getShareLink(props.guitarType, "tab", parts[1]));
        break;
      default:
      // logger.current.error("Invalid share type:", shareInfo);
    }
  }
}

function getShareLink(instrument: string, shareType: string, params: string): string {
  return `${window.location.origin}/${instrument}/${shareType}/${encodeURIComponent(params)}`;
}
