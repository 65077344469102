import React from "react";

function GuitarIcon() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="110mm"
          height="415.555"
          version="1.1"
          viewBox="0 0 110 109.949"
          xmlSpace="preserve"
        >
          <g transform="translate(-6.172 -2.954)">
            <path
              strokeWidth="0.266"
              d="M111.559 2.954L94.165 18.985c-1.445 1.397-4.405 4.15-6.578 6.12l-3.95 3.58L80.99 26.4c-3.67-3.166-5.392-4.26-8.404-5.337-4.485-1.605-8.642-1.286-12.153.935-.695.44-2.007 1.588-2.917 2.553-1.543 1.637-1.787 2.056-3.687 6.34-3.146 7.095-3.831 8.093-6.647 9.682-1.975 1.115-3.665 1.568-7.593 2.037-12.343 1.472-18.332 3.914-24.745 10.09C10.69 56.7 8.07 60.914 7.07 65.206c-.9 3.867-.545 9.635.824 13.354 1.063 2.89 5.84 10.326 9.082 14.139.569.67 3.384 3.603 6.256 6.52 4.844 4.92 5.423 5.439 8.024 7.186 7.627 5.124 12.12 6.607 18.764 6.193 5.775-.36 11.956-3.646 16.227-8.626 3.565-4.157 6.995-9.887 8.065-13.473.288-.964 1.062-4.465 1.72-7.779 1.609-8.09 1.73-8.422 3.893-10.694 2.026-2.127 2.83-2.574 8.19-4.557 1.884-.697 4.077-1.636 4.875-2.088 5.453-3.086 8.507-10.154 6.849-15.848-.972-3.338-3.784-7.847-6.912-11.085l-1.31-1.355 4.495-4.59c2.472-2.524 5.366-5.381 6.374-6.407l13.685-13.938V2.954zM67.903 42.946c1.107-.012 2.252.194 3.435.62 1.678.602 2.016.83 3.424 2.307 1.277 1.34 1.658 1.918 2.088 3.164.776 2.248.779 4.214.01 6.352-.618 1.718-.814 2.004-2.493 3.619-1.512 1.454-2.049 1.825-3.152 2.18-2.027.654-4.375.648-6.299-.013-1.422-.488-1.997-.851-3.265-2.061-2.374-2.265-3.137-4.234-2.908-7.504.182-2.6 1.081-4.335 3.212-6.197 1.856-1.622 3.834-2.443 5.948-2.467zM49.89 59.049l9.253 10.722-2.737 2.747-9.253-10.721z"
            ></path>
          </g>
        </svg>
      );
    
    
}

export default GuitarIcon;