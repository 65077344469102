import { SampleSpriteInfo, ToneSampleSpriteLib } from "../tone-sample-sprite-lib";
import { AudioInstrument } from "../../audio-instrument";
import { normalizeNoteName, parseNote, STANDARD_NOTE_NAMES } from "@power-chord/music-theory/notes";
import * as Tone from "tone";

/** An instrument that uses the Tone module */
export abstract class ToneInstrument implements AudioInstrument {
    protected readonly lib: ToneSampleSpriteLib;

    get isLoaded() { return this.lib.isLoaded; }

    constructor(spriteInfo: SampleSpriteInfo[], audioPath: string, extensions: string[]) {
        this.lib = new ToneSampleSpriteLib(audioPath, extensions, spriteInfo);
    }

    abstract load(progress?: (pct: number) => any): Promise<void>;

    play(note: string): void;
    play(notes: string[], strumDelay?: number): void;
    play(notes: string | string[], strumDelay = 0): void {
        if (typeof notes === "string") {
            notes = [notes];
        }
        notes.forEach((n, i) => {
            if (strumDelay) {
                setTimeout(() => this.lib.play(this.getSpriteName(n)), strumDelay * i);
            }
            else {
                this.lib.play(this.getSpriteName(n));
            }
        });
    }

    /** Provides direct access to the underlying Tone player */
    getPlayer(note: string): Tone.Player | undefined {
        return this.lib.getPlayer(this.getSpriteName(note));
    }

    stop(notes: string[]): void;
    stop(note: string): void;
    stop(notes: string | string[]): void {
        if (typeof notes === "string") {
            notes = [notes];
        }
        notes.forEach(n => {
            this.lib.stop(this.getSpriteName(n));
        })
    }

    /** Map the raw note name to the correct sprite name */
    protected getSpriteName(noteName: string): string {
        const note = parseNote(noteName);
        // Our sprites are named according to standard note names
        return normalizeNoteName(note.name) + note.octave;
    }
}

/**
 * Builds an array of SampleSpriteInfo
 * @param name Base name of the audio file 
 * @param minOctave Lowest octave
 * @param maxOctave Highest octave
 * @param start Offset in seconds from the beginning of the sample where sprites start
 * @param duration Length of the sprite in seconds
 * @param spacing How far apart in seconds each sprite is
 */
export function getSpriteInfo(name: string, minOctave: number, maxOctave: number, start = 1, duration = 2, spacing = 4): SampleSpriteInfo[] {
    const info: SampleSpriteInfo[] = [];
    for (let octave = minOctave; octave <= maxOctave; octave++) {
        for (let n = 0; n < STANDARD_NOTE_NAMES.length; n++) {
            info.push({
                id: STANDARD_NOTE_NAMES[n] + octave,
                fileName: name + octave,
                offset: (n * spacing) + start,
                duration: duration
            });
        }
    }
    return info;
}
