import { ToneInstrument, getSpriteInfo } from "./tone-instrument";

const audioFiles = [
    "guitar2", "guitar3", "guitar4", "guitar5"
];

export class GuitarInstrument extends ToneInstrument {
    constructor(audioPath: string, extensions: string[]) {
        super(getSpriteInfo("guitar", 2, 5), audioPath, extensions);
    }

    load(progress?: (pct: number) => any): Promise<void> {
        return this.lib.load(audioFiles, progress);
    }
}
