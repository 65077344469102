import React from "react";

function TheoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 496.2 496.2"
    >
      <path
        d="M496.2 248.1C496.2 111.1 385.1 0 248.1 0S0 111.1 0 248.1s111.1 248.1 248.1 248.1 248.1-111.1 248.1-248.1z"></path>
      <path
        fill="#000000"
        d="M263.3 240.3c-2.3-12.6-3.4-26.3-5.6-39.1 19.4-19.5 42-45.4 44.4-86.7.8-14.2-2.1-26.1-5.6-39.1-2.4-9-8.8-33.1-18.4-29.2-33.6 13.7-41.5 80-32 122.1-23.2 23.9-62.4 47.9-68.3 94.3-3.1 24.4 4.7 45.9 15.8 61.6 14.3 20.3 41.9 38.5 76.9 30.5 4.3 29.3 15.2 72.4-13.2 84.7-10.4 4.5-33.8 4.9-36.1-8.5 31.3 4.9 31.2-39 9-44.2-18.4-4.4-29.4 13.6-30.2 25.5-1.5 22.3 19.6 38.4 42.8 37.9 24.9-.5 42.5-15.7 41.8-47.1-.4-17.4-5.5-30.9-6.3-50.8 20.1-9.6 38.1-23.4 40.5-50.8 2.8-32.6-17.6-63.2-55.5-61.1zM268.8 87c9-8.7 24.2-7.8 24.9 8.3.7 15.6-7.4 29.5-14.9 40.3-7.8 11.2-16 20.9-26.2 26.8-5.7-24.4 1.9-61.4 16.2-75.4zM193 287.6c1.2-20.7 10.8-35.8 21-48 11-13.2 24.3-23.3 36.8-33.2 2.2 11.2 3.3 23.5 4.8 35.4-16.9 7.5-36 20.8-35.2 48.1.3 9.4 4.2 18.5 9.5 25.6 4.9 6.4 12.3 14.3 21 13.1-.4-5.3-6.8-6.6-10-9.8-16.1-15.5-4-47.5 18.6-50.2 3.1 26.5 6.8 52.3 10.1 78.6-48.1 7.3-78.9-22.7-76.6-59.6zm83.8 57c-2.7-26.1-7.2-50.7-9.7-77 9.7 1.2 18 4.8 23.8 10.2 20 18.8 11.7 60-14.1 66.8z"></path>
    </svg>
  );
}

export default TheoryIcon;