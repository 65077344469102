import { CanvasContext2D } from "canvas-context-2d";

/**
 * Abstract base class for canvas renderers.
 * Takes care of queuing redraws so multiple changes are rendered only once
 * no matter how many times draw() is called.
 */
export abstract class CanvasRenderer {
    private drawQueued = false;
    protected readonly context: CanvasContext2D;

    constructor(protected readonly canvas: HTMLCanvasElement) {
        this.context = new CanvasContext2D(canvas);
    }

    /**
     * Internal method called when the canvas actually is drawn.
     * This should never be called directly.
     */
    protected abstract draw(): CanvasRenderer;

    /** Public method called when the canvas needs to be rendered */
    render(): CanvasRenderer {
        if (!this.drawQueued) {
            this.drawQueued = true;

            requestAnimationFrame(() => {
                this.drawQueued = false;
                this.draw();
            });
        }

        return this;
    }
}