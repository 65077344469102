import React, { useEffect, useRef } from "react";
import { GuitarGridRenderer } from "./guitar-grid-renderer";
import { GuitarTabRenderer } from "./guitar-tab-renderer";

type GuitarChordViewProps = {
  positions: number[];
  width: string;
  height: string;
};

/**
 * Displays a guitar chord view with grid and tab views
 */
export default function GuitarChordView(props: GuitarChordViewProps) {
  const gridRef = useRef<HTMLCanvasElement>(null);
  const tabRef = useRef<HTMLCanvasElement>(null);
  const gridRenderer = useRef<GuitarGridRenderer>();
  const tabRenderer = useRef<GuitarTabRenderer>();

  const [currentView, setCurrentView] = React.useState<"grid" | "tab">("grid");

  useEffect(() => {
    gridRenderer.current = new GuitarGridRenderer(gridRef.current as HTMLCanvasElement);
    gridRenderer.current!.setTab(props.positions);

    tabRenderer.current = new GuitarTabRenderer(tabRef.current as HTMLCanvasElement);
    tabRenderer.current.setTab(props.positions);
  }, [props.positions]);


  return (
    <div className="chord-view">
      <div>
        <canvas hidden={currentView === "tab"} ref={gridRef} width={props.width} height={props.height}></canvas>
        <canvas hidden={currentView === "grid"} ref={tabRef} width={props.width} height={props.height}></canvas>
      </div>
      <div>
        <button onClick={e => setView("grid")} className={currentView === "grid" ? "selected" : ""}>Grid</button>
        <button onClick={e => setView("tab")} className={currentView === "tab" ? "selected" : ""}>Tab</button>
      </div>
    </div>
  );

  function setView(view: "grid" | "tab"): void {
    setCurrentView(view);
  }
}