// Defines keyboard dimensions based off the width of a white key

export interface KeyDimensions {
    width: number;
    length: number;
    shadowX: number;
    shadowY: number;
    shadowBlur: number;
    margin: number;
}

export interface BlackKeyDimensions extends KeyDimensions {
    // Length of the bevel at the front of the key
    bevelLength: number;
    // Width at the top of the key
    topWidth: number;
    // Width of the ridge line
    ridgeLine: number;
}

export interface KeyboardDimensions extends Record<string, any> {
    scale: number;
    width: number;
    height: number;
    whiteKey: KeyDimensions;
    blackKey: BlackKeyDimensions;
    selection: {
        size: number,
        top: number
    }
}

// Scales
// NOTE: (whiteKey.width + whiteKey.margin * 2) === 1
const defaultDimensions = {
    scale: 1,
    width: 0,
    height: 0,
    whiteKey: {
        width: 15 / 16,
        length: 5.5,
        margin: 1 / 32,
        shadowBlur: 1 / 8,
        shadowX: 1 / 64,
        shadowY: 2 / 32
    },
    blackKey: {
        width: 8 / 16,
        length: 3.5,
        margin: 1 / 32,
        shadowBlur: 2 / 16,
        shadowX: 4 / 64,
        shadowY: 1 / 64,
        bevelLength: 5 / 16,
        topWidth: 6 / 16,
        ridgeLine: 2 / 64
    },
    selection: {
        size: 6 / 32,
        top: 6 / 32 + 12 / 32
    }
};
defaultDimensions.height = defaultDimensions.whiteKey.length + defaultDimensions.whiteKey.shadowBlur;

export function getDefaultDimensions(): KeyboardDimensions {
    if (defaultDimensions.whiteKey.width + defaultDimensions.whiteKey.margin * 2 !== 1) {
        throw new Error("Invalid keyboard dimensions");
    }

    return Object.assign({}, defaultDimensions);
}
/**
 * Gets the dimensions used to draw the keyboard
 * @param octaves Number of octaves
 * @param canvasWidth 
 */
export function getKeyboardDimensions(octaves: number, canvasWidth: number): KeyboardDimensions {
    if (defaultDimensions.whiteKey.width + defaultDimensions.whiteKey.margin * 2 !== 1) {
        throw new Error("Invalid keyboard dimensions");
    }

    const whiteKeyCount = (octaves * 7) + 1;
    // Scale is the width of one white key plus margins
    const scale = canvasWidth / whiteKeyCount;

    const dimensions = scaleProperties(defaultDimensions, scale);
    dimensions.width = canvasWidth;
    return dimensions;
}

function scaleProperties(source: KeyboardDimensions, scale: number): KeyboardDimensions {
    const dest: any = {};

    for (const f in source) {
        const value = source[f];
        if (typeof value === "number") {
            dest[f] = scale * value;
        }
        else {
            dest[f] = scaleProperties(value, scale);
        }
    }
    return dest;
}