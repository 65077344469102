import { getUrlSearchParam } from "../browser-utils";

export type FeatureFlagService = {
    /** When true the popupMenuButton control uses a dropdown (select) instead */
    dropDownMenuButton: boolean;
    /** When true share link is enabled */
    shareLink: boolean;
};

export const featureFlags: FeatureFlagService = {
    dropDownMenuButton: getUrlSearchParam("ff-dropDownMenuButton") !== "false",
    shareLink: true//getUrlSearchParam("ff-shareLink") === "true"
};
