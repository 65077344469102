import { RhythmSequence } from "../state-types";

/**
 * A library of predefined rhythms
 */
export const RhythmLib: RhythmSequence[] = [
    {
        name: "Basic 4/4", description: "Basic 4/4 time metronome",
        barCount: 1, beatsPerBar: 4, unitsPerBeat: 1, beatsPerMinute: 120,
        rows: [{"name":"low block","beats":[true,true,true,false]},{"name":"high block","beats":[false,false,false,true]}]
    },
    {
        name: "Basic 3/4", description: "Basic 3/4 time metronome",
        barCount: 1, beatsPerBar: 3, unitsPerBeat: 1, beatsPerMinute: 120,
        rows: [{"name":"low block","beats":[true,true,false]},{"name":"high block","beats":[false,false,true]}]
    },
    {
        name: "Rock 4 beat", description: "4 beat rock rhythm",
        barCount: 1, beatsPerBar: 4, unitsPerBeat: 2, beatsPerMinute: 120,
        rows: [
            {"name":"bass drum","beats":[true,false,false,false,true,true,false,false]},
            {"name":"snare","beats":[false,false,true,false,false,false,true,false]},
            {"name":"hi-hat","beats":[true,true,true,true,true,true,true,true]}]
    },
    {
        name:"Rock 8 beat", description:"8 beat rock rhythm",
        barCount: 2, beatsPerBar: 4, unitsPerBeat: 2, beatsPerMinute: 120,
        rows:[
            {"name":"bass drum","beats":[true,false,false,false,true,false,false,false,true,false,false,false,true,true,false,false]},
            {"name":"snare","beats":[false,false,true,false,false,false,true,false,false,false,true,false,false,false,true,false]},
            {"name":"hi-hat","beats":[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,false]},
            {"name":"open hi-hat","beats":[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,true]}]
    },
    {
        name: "Rock 6 beat", description: "6 beat rock rhythm",
        barCount: 2, beatsPerBar: 3, unitsPerBeat: 3, beatsPerMinute: 120,
        rows: [{"name":"bass drum","beats":[true,false,false,false,false,false,true,false,false,false,false,false,true,false,false,false,false,true]},{"name":"snare","beats":[false,false,false,true,false,false,false,false,false,true,false,false,false,false,false,true,false,false]},{"name":"hi-hat","beats":[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true]}]
    },
];