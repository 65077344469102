import React from "react";

function ArrowsIcon() {
  return (
    <svg className="arrows"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 898.3 898.3"
      xmlSpace="preserve"
    >
      <path d="M120.2 882.5L553.6 449.2 120.2 15.8 0 136 313.2 449.2 0 762.3z"></path>
      <path d="M344.7 762.3L464.9 882.5 898.3 449.2 464.9 15.8 344.7 136 657.9 449.2z"></path>
    </svg>
  );
}

export default ArrowsIcon;