export default interface FileService {
    /** Returns a list of all files */
    list(): Promise<string[]>;
    /** Gets the file with the specified name */
    get<T>(fileName: string): Promise<T>;
    /** Saves the file with the specified name and value */
    set<T>(fileName: string, value: T): Promise<void>
    /** Deletes all files! */
    reset(): void;
}

export class InMemoryFileService implements FileService
{
    private files = new Map<string, any>();

    list(): Promise<string[]> {
        return Promise.resolve(Array.from<string>(this.files.keys()));
    }

    get<T>(fileName: string): Promise<T> {
        const v = this.files.get(fileName);
        return v ? Promise.resolve(JSON.parse(v)) : Promise.reject("Not found");
    }

    set<T>(fileName: string, value: T): Promise<void> {
        this.files.set(fileName, JSON.stringify(value));
        return Promise.resolve();
    }

    reset(): void {
        this.files.clear();
    }
}

export class LocalStorageFileService implements FileService
{
    constructor(private storageKey: string) {}

    list(): Promise<string[]> {
        const files = this.getFiles();
        return Promise.resolve(Object.keys(files));
    }

    get<T>(fileName: string): Promise<T> {
        const file = this.getFiles<T>()[fileName];
        return file ? Promise.resolve(file) : Promise.reject("Not found: " + fileName);
    }

    set<T>(fileName: string, value: T): Promise<void> {
        const files = this.getFiles<T>();
        files[fileName] = value;
        localStorage.setItem(this.storageKey, JSON.stringify(files));
        return Promise.resolve();
    }

    reset(): void {
        localStorage.removeItem(this.storageKey);
    }

    private getFiles<T>(): Record<string, T> {
        const item = localStorage.getItem(this.storageKey);
        return item ? JSON.parse(item) : {}
    }
}
