import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import AboutView from './components/about/AboutView';
import StandardGuitarView from './components/guitar/StandardGuitarView';
import UkuleleView from './components/guitar/UkuleleView';
import KeyboardView from './components/keyboard/KeyboardView';
import LandingView from './components/landing/LandingView';
import ProgressionView from './components/progressions/ProgressionView';
import RhythmView from './components/rhythm/RhythmView';
import SettingsView from './components/settings/SettingsView';
import TheoryView from './components/theory/TheoryView';
import VocalsView from './components/vocals/VocalsView';
import { Globals } from './globals';

export default function AppRouter() {
  return (
<BrowserRouter>
  <Routes>
    <Route path="/" element={<App />} errorElement={<App />}>
      <Route index element={<LandingView />} />
      <Route path="keyboard" element={<KeyboardView octaves="2" width="1000" height="375" />}>
        <Route path=":shareType/:shareId" element={<KeyboardView octaves="2" width="1000" height="375" />} />
      </Route>
      <Route path="guitar" element={<StandardGuitarView />}>
        <Route path=":shareType/:shareId" element={<StandardGuitarView />} />
      </Route>
      <Route path="ukulele" element={<UkuleleView />}>
        <Route path=":shareType/:shareId" element={<UkuleleView />} />
      </Route>
      <Route path="vocals" element={<VocalsView />} />
      <Route path="rhythm" element={<RhythmView />} />
      <Route path="progression" element={<ProgressionView />} />
      <Route path="theory" element={<TheoryView />} />
      <Route path="settings" element={<SettingsView />} />
      <Route path="about" element={<AboutView version={Globals.version} />} />
    </Route>
  </Routes>
</BrowserRouter>
  );
}
