import { ToneInstrument, getSpriteInfo } from "./tone-instrument";

const audioFiles = [
    "piano4", "piano5", "piano6"
];

export class PianoInstrument extends ToneInstrument {
    constructor(audioPath: string, extensions: string[]) {
        super(getSpriteInfo("piano", 4, 6), audioPath, extensions);
    }

    load(progress?: (pct: number) => any): Promise<void> {
        return this.lib.load(audioFiles, progress);
    }
}
