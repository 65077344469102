import { SampleSpriteInfo } from "../tone-sample-sprite-lib";
import { ToneInstrument } from "./tone-instrument";

// https://en.wikipedia.org/wiki/General_MIDI
export const PERCUSSION_NAMES = [
    "", "bass drum", "rimshot", "snare", "clap", "low tom", "hi-hat", "open hi-hat", "high tom", "tambourine", "cowbell",
    "high bongo", "low bongo", "timbale", "high agogo", "low agogo", "low maracas", "whistle", "guiro", "claves", "high block",
    "low block", "mute cuica", "open cuica", "high maracas", "click", "triangle", "vibra slap"
];

export class PercussionInstrument extends ToneInstrument {
    constructor(audioPath: string, extensions: string[]) {
        super(getSpriteInfo(), audioPath, extensions);
    }

    load(progress?: (pct: number) => any): Promise<void> {
        return this.lib.load(["percussion"], progress);
    }

    protected getSpriteName(name: string): string {
        return name;
    }
}

export function getSpriteInfo(): SampleSpriteInfo[] {
    const info: SampleSpriteInfo[] = [];

    // Most of the instruments fit into 1 second intervals
    for (let n = 1; n < PERCUSSION_NAMES.length - 2; n++) {
        info.push({
            id: PERCUSSION_NAMES[n],
            fileName: "percussion",
            offset: n,
            duration: .99
        });
    }

    // The last two are in 2 second intervals
    const idx = PERCUSSION_NAMES.length - 2;
    info.push({
        id: PERCUSSION_NAMES[idx],
        fileName: "percussion",
        offset: idx,
        duration: 1.5
    });
    info.push({
        id: PERCUSSION_NAMES[idx + 1],
        fileName: "percussion",
        offset: idx + 2,
        duration: 1.5
    });

    return info;
}
