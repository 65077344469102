import { ChordProgressionCalculator, getChordProgressionCalculator, getScale, ModeName, MusicScale, NoteName, parseChord, unformatAccidentals } from '@power-chord/music-theory';
import * as analog from "analogging";
import { Undoable } from 'undoable-wrapper';
import { normalize } from '../utils';
import { ChordProgression, ChordProgressionItem } from '../state-types';

const MAX_BPM = 300;

export class ProgressionController {
    private readonly logger = analog.getLogger("ProgressionController");
    private readonly undoableState: Undoable<ChordProgression>;
    private currentKey: MusicScale;
    private progCalculator: ChordProgressionCalculator;
    private _suggestedChords: string[] = [];
    private _chordsInKey: string[] = [];

    constructor(progression: ChordProgression) {
        this.undoableState = new Undoable<ChordProgression>(progression, true);
        this.currentKey = getMusicScale(progression);
        this.progCalculator = getChordProgressionCalculator(this.currentKey);
        this.updateSuggestedChords();
    }

    get progression(): ChordProgression { return this.undoableState.value!; }

    get suggestedChords(): string[] { return this._suggestedChords }

    get chordsInKey(): string[] { return this._chordsInKey; }

    get canRedo(): boolean { return this.undoableState.canRedo; }

    get canUndo(): boolean { return this.undoableState.canUndo; }

    clear(): void {
        const newProgression = Object.assign({}, this.progression, { name: "unnamed", description: "", items: [] });
        this.setProgression(newProgression);
    }

    redo(): boolean {
        if (this.canRedo) {
            this.undoableState.redo();
            this.updateSuggestedChords();
            return true;
        }
        return false;
    }

    undo(): boolean {
        if (this.canUndo) {
            this.undoableState.undo();
            this.updateSuggestedChords();
            return true;
        }
        return false;
    }

    /**
     * Adds an item to end of the progression
     * @param chord Name of the chord
     * @param beats Number of beats
     */
    addItem(chord: string, beats: number): void {
        this.undoableState.value = this.progression;
        this.undoableState.value.items.push({
            chord: chord,
            beats: beats
        });
        this.updateSuggestedChords();
    }

    /**
     * Deletes the item at the specified index
     * @param idx Index of item to delete, default is the last item
     * @returns True if an item was deleted
     */
    deleteItem(idx = this.progression.items.length - 1): boolean {
        if (this.progression.items.length > 0) {
            this.undoableState.value = this.progression;
            this.undoableState.value.items = this.progression.items.filter((v, i) => idx !== i);
            this.updateSuggestedChords();
            return true;
        }
        return false;
    }

    /**
     * Adds (or deletes) the number of beats to the specified item
     * @param idx Index of the item
     * @param cnt Number of beats to add, if negative the beats are removed
     * @returns True if there is an item at the specified index
     */
    addBeat(idx: number, cnt: number): boolean {
        if (this.progression.items.length >= idx) {
            this.undoableState.value = this.progression;
            this.undoableState.value.items[idx].beats += cnt;
            return true;
        }
        return false;
    }

    /**
     * Sets the key of the progression and transposes any chords to the new key
     * @param key A MusicScale
     * @returns True if the key changed
     */
    setKey(key: MusicScale): boolean {
        if (this.isKeySame(key)) {
            // no change
            return false;
        }

        this.undoableState.value = this.progression;
        this.undoableState.value.items = transposeChords(this.undoableState.value.items, this.currentKey, key);
        this.undoableState.value.key = key.tonic.name;
        this.undoableState.value.quality = key.mode;

        this.currentKey = key;
        this.progCalculator = getChordProgressionCalculator(key);
        this.updateSuggestedChords();

        return true;
    }

    setProgression(newProgression: ChordProgression): void {
        this.undoableState.value = newProgression;
        this.updateSuggestedChords();
    }

    setBeatsPerMinute(bpm: number): void {
        this.undoableState.value = this.progression;
        // Normalize to between 1 and max
        this.undoableState.value.beatsPerMinute = normalize(bpm, 1, MAX_BPM);
    }

    /** Compares a key to the key of the progression */
    private isKeySame(key: MusicScale) {
        return this.progression.key === key.tonic.name && this.progression.quality === key.mode;
    }

    private updateSuggestedChords(): void {
        if (!this.isKeySame(this.currentKey)) {
            this.currentKey = getMusicScale(this.progression);
            this.progCalculator = getChordProgressionCalculator(this.currentKey);    
            this.logger.debug("Updating key to", this.currentKey)
        }

        const chords = this.progression.items;
        let lastChord: string;
        if (chords.length > 0) {
            lastChord = chords[chords.length - 1].chord;
        }
        else {
            lastChord = this.currentKey.chords[0].name;
        }

        this._suggestedChords = this.getSuggestedChordsForChord(lastChord);
        this._chordsInKey = this.currentKey.chords.map(c => c.formattedName);
    }

    private getSuggestedChordsForChord(chordName: string): string[] {
        const chord = parseChord(unformatAccidentals(chordName));
        return this.progCalculator.getNextChords(chord).map(c => c.formattedName) as string[];
    }
}

/** Parses a music scale from a chord progression */
function getMusicScale(progression: ChordProgression): MusicScale {
    return getScale((progression.key || "C") as NoteName, progression.quality as ModeName);
}

/**
 * Transposes all of the chords to the new key
 */
function transposeChords(items: ChordProgressionItem[], oldKey: MusicScale, newKey: MusicScale): ChordProgressionItem[] {
    const amount = newKey.notes[0].number - oldKey.notes[0].number;
    const chords = items.map(item => {
        const chord = parseChord(unformatAccidentals(item.chord));
        return {
            chord: newKey.getChordInScale(chord.transpose(amount)).formattedName,
            beats: item.beats
        } as ChordProgressionItem;
    });

    return chords;
}
