import * as React from 'react';
import PopupMenuButton, { NameValueTitle } from './PopupMenuButton';
import { NoteName, getScale, MusicScale, ModeName } from '@power-chord/music-theory';
import NoteSelector from './NoteSelector';
import { featureFlags } from '@local/power-chord-lib/build/src/services/feature-flag-service';

const MODE_MENU_ITEMS: NameValueTitle[] = [["Major", "major"], ["Minor", "minor"]];

const EXTENDED_MODE_MENU_ITEMS: NameValueTitle[] = MODE_MENU_ITEMS.concat([
  [featureFlags.dropDownMenuButton ? "" : "\n"],
  ["Lydian", "lydian"],
  ["Ionian", "ionian", "aka Major"],
  ["Mixolydian", "mixolydian"],
  ["Dorian", "dorian"],
  ["Aeolian", "aeolian", "aka Minor"],
  ["Phrygian", "phrygian"],
  ["Locrian", "locrian"]
]);

export type KeySelectorProps = {
  /** Initial tonic to select */
  selectedTonic?: NoteName;
  /** Initial mode to select */
  selectedMode?: ModeName;
  /** If true all modes are display, otherwise just major and minor */
  extended?: boolean;
  /** Function to call when selection changed */
  onChange: (key: MusicScale) => void;
};

/** Used to select a musical key and quality */
export default function KeySelector(props: KeySelectorProps) {
  const qualityMenuItems = props.extended ? EXTENDED_MODE_MENU_ITEMS : MODE_MENU_ITEMS;

  return (
    <div className='key-selector'>
      <NoteSelector selectedNote={props.selectedTonic || ""} onChange={e => tonicChanged(e)} title="Select Tonic" />
      <PopupMenuButton menuItems={qualityMenuItems} value={props.selectedMode || ""} onChange={e => modeChanged(e)} title="Select Mode" />
    </div>
  );

  function tonicChanged(tonic: NoteName): void {
    // Notify listener
    fireChangedEvent(tonic, props.selectedMode);
  }

  function modeChanged(mode: string): void {
    if (mode && props.selectedTonic) {
      fireChangedEvent(props.selectedTonic, mode);
    }
  }

  function fireChangedEvent(tonic: NoteName, mode?: string): void {
    props.onChange(getScale(tonic, (mode || "major") as ModeName))
  }
}
