import { getLogger } from "analogging";
import { CanvasRenderer } from "./canvas-renderer";

function loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.addEventListener('load', e => resolve(img));
        img.addEventListener('error', () => reject(new Error(`Failed to load image: ${url}`)));
        img.src = url;
    });
}

/**
 * Abstract base class for drawing a music staff.
 * Implement drwContent() to draw the note or other content.
 */
export default abstract class MusicStaffRenderer extends CanvasRenderer {
    protected readonly logger = getLogger("MusicStaffRenderer");
    protected images: HTMLImageElement;
    // The y-pos of the top line in the staff
    protected _top = 25.5;
    protected _lineGap = 10;

    /**
     * 
     * @param canvas A canvas that is 100 x 81 pixels
     */
    constructor(canvas: HTMLCanvasElement) {
        super(canvas);

        this.images = new Image();
        loadImage("/icons.png").then(img => {
            this.images = img;
            this.render();
        })
            .catch((err: Error) => console.error(err.message));
    }

    /**
     * Draws the notes and/or key signatures
     */
    protected abstract drawContent(): MusicStaffRenderer;

    protected draw(): MusicStaffRenderer {
        if (this.images) {
            this.logger.debug("Drawing staff");
            this.context.save()
                .clear()
                .fillStyle("whitesmoke")
                .fillRect(0, 0, this.canvas.width, this.canvas.height)
                .lineWidth(1)
                .strokeStyle("black");

            this.drawTrebleClef()
                .drawContent()
                .drawLines();

            this.context.restore();
        }
        return this;
    }

    protected drawLines(): MusicStaffRenderer {
        for (let i = 0; i < 5; i++) {
            let y = this._top + (i * this._lineGap);
            this.context.drawLine(0, y, this.context.canvas.width, y);
        }
        return this;
    }

    protected drawTrebleClef(): MusicStaffRenderer {
        this.context.drawClippedImage(this.images, 132, 132, 22, 65, 2, this._top - 11.5);
        return this;
    }

    protected drawSharp(x: number, y: number, w?: number, h?: number): MusicStaffRenderer {
        this.context.drawClippedImage(this.images, 154, 132, 10, 26, x, y - 2, w, h);
        return this;
    }

    protected drawFlat(x: number, y: number, w?: number, h?: number): MusicStaffRenderer {
        this.context.drawClippedImage(this.images, 154, 158, 10, 26, x, y - 6, w, h);
        return this;
    }
}