import iocContainer from "ez-ioc";
import { useConst } from "./useConst";
import { AudioInstrument } from "@local/power-chord-lib/build/src/audio/audio-instrument";
import { TYPES } from "../lib/init-dependencies";

/** Gets an audio lib for the specified instrument for use in a component */
export function useAudioLib(instrument: "percussion" | "piano" | "guitar"): AudioInstrument {
    const id = getInstrumentId(instrument);
    return useConst(() => iocContainer.resolve<AudioInstrument>(id));
}

function getInstrumentId(instrument: string): symbol {
    switch (instrument) {
        case "percussion": return TYPES.PercussionAudioLib;
        case "piano": return TYPES.PianoAudioLib;
        case "guitar": return TYPES.GuitarAudioLib;
        default:
            throw new Error("Invalid instrument: " + instrument);
    }
}
