import { CanvasContext2D } from "canvas-context-2d";
import * as analog from "analogging";

let colorOffset = 0;
// const NOTE_HUES = Array.from(Array(12).keys()).map(i => i / 12 * 360);
// const NOTE_COLORS = Array.from(Array(12).keys()).map((i) => `hsl(${NOTE_HUES[i]}, 100%, $l)`);

/**
 * Draws audio frequency data to a canvas
 */
export interface FrequencyDataRenderer {
    canvasCtx: CanvasContext2D;
    draw(data?: Uint8Array): void;
}

/**
 * Draws audio frequency data to a canvas in a rotating rainbow hue
 */
export class RainbowFrequencyRenderer implements FrequencyDataRenderer {
    private readonly logger = analog.getLogger("RainbowFrequencyRenderer");

    /**
     * @param canvasCtx Canvas to draw on
     * @param speed Speed of the rainbow hue
     */
    constructor(readonly canvasCtx: CanvasContext2D, readonly speed: number) { }

    private drawQueued = false;

    draw(data?: Uint8Array): void {
        if (!this.drawQueued) {
            this.drawQueued = true;

            requestAnimationFrame(() => {
                this.logger.debug("drawing audio data");
                this.drawQueued = false;

                const canvasCtx = this.canvasCtx;
                const width = canvasCtx.canvas.width;
                const height = canvasCtx.canvas.height;
                canvasCtx.clearRect(0, 0, width, height);

                if (data) {
                    this.drawData(data, width, height);
                }
            });
        }
    }

    private drawData(data: Uint8Array, width: number, height: number) {
        colorOffset += this.speed;
        const length = data.length;
        const huePct = 360 / length;
        const barWidth = (width / length);

        for (let i = 0; i < length; i++) {
            const pct = data[i] / 256;
            const barHeight = pct * height;
            const hue = i * huePct;
            //const hue = pct * 360;
            this.canvasCtx
                .fillStyle(`hsl(${(hue + colorOffset) % 360}, 100%, 50%)`)
                .fillRect(i * barWidth, height - barHeight, barWidth, barHeight);
        }
    }
}
