export const GUITAR_COLORS = {
    fretboard: {
        top: "#643A1C",
        bottom: "#422109",
        head: "#2A1506",
        dot: "ivory",
        nut: "ivory",
        fret: "GhostWhite"
    },
    strings: {
        bass: "khaki",
        treble: "silver",
        shadow: "black"
    },
    selection: {
        root: {
            bg: "rgba(128, 255, 128, .8)",
            fg: "black"
        },
        other: {
            bg: "rgba(255, 255, 255, .8)",
            fg: "black"
        },
        open: {
            bg: "rgba(128, 128, 128, .3)",
            fg: "white"
        },
        muted: {
            bg: "transparent",
            fg: "rgba(255, 0, 0, .8)"
        },
        shadow: "black",
        background: "white",
        hint: "dodgerblue",
        hintTipBG: "rgba(255, 255, 255, .6)",
        hintTip: "rgba(0, 0, 0, .8)"
    }
};

export const UKULELE_COLORS = Object.assign({}, GUITAR_COLORS, {
    strings: {
        bass: "white",
        treble: "white",
        shadow: "black"
    },
});
