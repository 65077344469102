import { Note } from "@power-chord/music-theory";
import { VocalRange } from "@local/power-chord-lib/build/src/state-types";
import { TuneCaptureProcessor } from "@local/power-chord-lib/build/src/vocals/tune-capture-processor";
import * as analog from "analogging";
import { CanvasRenderer } from "../common/canvas-renderer";

const NOTE_HUES = Array.from(Array(12).keys()).map(i => i / 12 * 360);
const NOTE_COLORS = Array.from(Array(12).keys()).map((i) => `hsl(${NOTE_HUES[i]}, 100%, $l)`);
const OCTAVE_LINE_COLOR = "limegreen";
const FONT_NAME = "px sans-serif";
const LEDGER_LINE_COLOR = "#888";
const DOTTED_LINE = [1, 2];

export class TuneCaptureRenderer extends CanvasRenderer {
    private readonly logger = analog.getLogger("TuneCaptureRenderer");
    private _maxTime = 0;
    width = 0
    height = 0;
    minKey = 0;
    maxKey = 0;
    noteCount = 0;

    get maxTime(): number {
        return this._maxTime;
    }

    // private get minNote(): Note {
    //     return this.range.low!;
    // }

    private get maxNote(): Note {
        return this.range.high!;
    }

    constructor(canvas: HTMLCanvasElement, readonly processor: TuneCaptureProcessor, private range: VocalRange) {
        super(canvas);
    }

    setMaxTime(max: number): TuneCaptureRenderer {
        this._maxTime = max;
        return this.render() as TuneCaptureRenderer;
    }

    setRange(range: VocalRange): TuneCaptureRenderer {
        this.range = range;
        this.minKey = range.low!.keyNumber;
        this.maxKey = range.high!.keyNumber;
        this.noteCount = this.maxKey - this.minKey + 1;
        //console.log("update", this.minKey, this.maxKey, this.noteCount);
        return this.render() as TuneCaptureRenderer;
    }

    /** Updates size from underlying canvas */
    update(): TuneCaptureRenderer {
        this.width = this.canvas.width;
        this.height = this.canvas.height;
        return this.render() as TuneCaptureRenderer;
    }

    /** @override */
    protected draw(): TuneCaptureRenderer {
        this.logger.debug("drawing tune capturer");
        this.context.save().clearRect(0, 0, this.width, this.height);

        const lineHeight = this.height / this.noteCount;
        this.drawEvents(this._maxTime, lineHeight)
            .drawGuides(this._maxTime, lineHeight);

        this.context.restore();
        return this;
    }

    private drawEvents(maxTime: number, lineHeight: number): TuneCaptureRenderer {
        this.context.strokeStyle("black");

        // let prevNote: Note | undefined;
        // const noteNames = [];

        for (const event of this.processor.events) {
            if (event.note) {
                const x = this.getX(event.startTime, maxTime);
                const y = this.getY(event.note.keyNumber);
                const w = this.getX(event.endTime, maxTime) - x;

                const l = 50 - Math.abs(event.cents! * 30);
                const color = NOTE_COLORS[event.note!.number].replace("$l", l + "%");
                this.context
                    .fillStyle(color)
                    .fillRect(x, y, w, lineHeight);

                // Draw cent line
                const cy = y + lineHeight * (.5 - event.cents!);
                this.context.drawLine(x, cy, x + w, cy);

                // if (prevNote !== event.note) {
                //     prevNote = event.note;
                //     noteNames.push({
                //         name: prevNote.name,
                //         x: x,
                //         y: y
                //     });
                // }
            }
        }

        // Draw note names last so they are on top
        // const fontSize = Math.min(16, (lineHeight / 2))
        // this.context
        //     .textBaseline("top")
        //     .textAlign("right")
        //     .font(fontSize + FONT_NAME);

        // for (const noteInfo of noteNames) {
        //     this.context
        //         .fillStyle("white")
        //         .fillText(noteInfo.name, noteInfo.x - 1, noteInfo.y + 1);
        // }

        return this;
    }

    private drawGuides(maxTime: number, lineHeight: number): void {
        this.drawNoteGuides(lineHeight)
            .drawTimeGuide(maxTime);
    }

    /** Shows the current time position on the graph */
    private drawTimeGuide(maxTime: number) {
        const lastTime = this.processor.lastTime;
        if (lastTime) {
            const x = this.getX(lastTime, maxTime);
            this.context.strokeStyle("red").drawLine(x, 0, x, this.height);
        }
    }

    private drawNoteGuides(lineHeight: number): TuneCaptureRenderer {
        const hOver2 = Math.floor(lineHeight / 2);
        this.context
            .lineWidth(1)
            .textBaseline("middle")
            .textAlign("left")
            .font(hOver2 + FONT_NAME);

        let y = lineHeight;
        for (let n = this.maxNote; n.keyNumber >= this.minKey; n = n.transpose(-1)) {
            const top = y - lineHeight;
            const hasAccidental = n.hasAccidental;
            const textColor = hasAccidental ? "ivory" : "black";
            const bgColor = hasAccidental ? "#333" : "ivory";
            // draw note name
            this.context
                .fillStyle(bgColor)
                .strokeStyle(LEDGER_LINE_COLOR)
                .fillRect(0, top, lineHeight, lineHeight)
                .drawRect(0, top, lineHeight, lineHeight)
                .fillStyle(textColor)
                .fillText(n.toString(true), 2, y - hOver2);

            // draw ledger
            this.drawLedgerLine(n.name === "C", y);

            y += lineHeight;
        }

        return this;
    }

    private drawLedgerLine(isOctave: boolean, y: number) {
        let ledgerColor = isOctave ? OCTAVE_LINE_COLOR : LEDGER_LINE_COLOR;

        this.context
            .strokeStyle(ledgerColor)
            .lineDash(isOctave ? [] : DOTTED_LINE)
            .drawLine(0, y, this.width, y);

        if (!isOctave) {
            this.context.lineDash([]);
        }
    }

    getY(keyNumber: number): number {
        const yPct = (keyNumber - this.minKey + 1) / this.noteCount;
        return this.height - yPct * this.height;
    }

    getX(time: number, maxTime: number): number {
        return this.width * (time - this.processor.startTime!) / maxTime;
    }
}