import React from "react";

function OpenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 276.157 276.157"
      version="1.1"
      viewBox="0 0 276.157 276.157"
      xmlSpace="preserve"
    >
      <path
        d="M273.081 101.378c-3.3-4.651-8.86-7.319-15.255-7.319h-24.34v-26.47c0-10.201-8.299-18.5-18.5-18.5h-85.322c-3.63 0-9.295-2.876-11.436-5.806l-6.386-8.735c-4.982-6.814-15.104-11.954-23.546-11.954H58.731c-9.293 0-18.639 6.608-21.738 15.372l-2.033 5.752c-.958 2.71-4.721 5.371-7.596 5.371H18.5c-10.201 0-18.5 8.299-18.5 18.5v167.07c0 .885.161 1.73.443 2.519.152 3.306 1.18 6.424 3.053 9.064 3.3 4.652 8.86 7.319 15.255 7.319h188.486c11.395 0 23.27-8.424 27.035-19.179l40.677-116.188c2.112-6.035 1.432-12.164-1.868-16.816zM18.5 64.089h8.864c9.295 0 18.64-6.608 21.738-15.372l2.032-5.75c.959-2.711 4.722-5.372 7.597-5.372h29.564c3.63 0 9.295 2.876 11.437 5.806l6.386 8.734c4.982 6.815 15.104 11.954 23.546 11.954h85.322c1.898 0 3.5 1.603 3.5 3.5v26.47H69.34c-11.395 0-23.27 8.424-27.035 19.179L15 191.231V67.589c0-1.897 1.603-3.5 3.5-3.5zm242.291 49.149l-40.677 116.188c-1.674 4.781-7.812 9.135-12.877 9.135H18.751c-1.448 0-2.577-.373-3.02-.998-.443-.625-.423-1.814.056-3.181l40.677-116.188c1.674-4.781 7.812-9.135 12.877-9.135h188.486c1.448 0 2.577.373 3.021.998.442.625.422 1.814-.057 3.181z"
      ></path>
    </svg>
  );
}

export default OpenIcon;