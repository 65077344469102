import React, { useMemo } from "react";
import PopupMenuButton, { NameValueTitle } from "../common/PopupMenuButton";
import { NoteName, ChordQuality, GuitarLookupService } from "@power-chord/music-theory";
import NoteSelector from "../common/NoteSelector";
import RenderWhen from "../common/RenderWhen";

const QUALITY_MENU_ITEMS: NameValueTitle[] = [
  ["M", "M", "Major"],
  ["m", "m", "Minor"],
  ["7", "7", "7th"],
  ["M7", "M7", "Major 7th"],
  ["m7", "m7", "Minor 7th"],
  ["dim", "dim", "Diminished"],
  // ["dim7", "Diminished 7th"],
  ["M6", "M6", "Major 6th"],
  ["sus4", "sus4", "Suspend 4"],
  // ["sus2", "Suspend 2"],
  // ["aug", "Augmented"],
  // ["5", "Fifth"],
  // ["M6", "Major 6th"],
  // ["m6", "Minor 6th"],
];

export type ChordInformation = {
  root: NoteName | "";
  quality: ChordQuality | "";
  inversion: number;
};

type GuitarChordSelectorProps = {
  chord?: ChordInformation;
  guitarLookup: GuitarLookupService;
  onChanged: (chord: ChordInformation) => any;
};

/**
 * Chord selector component allows user to select a chord by name, quality and bass note (inversion).
 * Fires an onChange event when the chord selection changes.
 */
export default function GuitarChordSelector(props: GuitarChordSelectorProps) {
  const rootNote = useMemo(() => props.chord?.root ?? "", [props.chord]);
  const chordQuality = useMemo(() => props.chord?.quality ?? "", [props.chord]);
  const variationName = useMemo(() => props.chord?.inversion ? (props.chord.inversion + 1).toString() : "1", [props.chord]);
  const variationNumber = useMemo(() => props.chord?.inversion ?? 0, [props.chord]);  
  const variations = useMemo(() => {
    if (rootNote) {
      return getChordVariationsMenuList(props.guitarLookup, rootNote, chordQuality);
    }
    else {
      return [];
    }
  }, [chordQuality, props.guitarLookup, rootNote]);

  return (
    <div className="chord selector">
      <NoteSelector selectedNote={rootNote} title="Select Root" onChange={e => onRootChanged(e)} />
      <PopupMenuButton
        menuItems={QUALITY_MENU_ITEMS}
        value={chordQuality}
        title="Select Quality"
        onChange={e => onQualityChanged(e as ChordQuality)}
      />
      <RenderWhen condition={Boolean(rootNote)}>
        <span>
          : <PopupMenuButton menuItems={variations} value={variationName} title="Select Variation" onChange={e => onVariationChanged(e)} />
          <button onClick={e => selectNextVariation()} title="Next">+</button>
          <button onClick={e => selectNextVariation(-1)} title="Previous">-</button>
        </span>

      </RenderWhen>
    </div>
  );

  function onRootChanged(root: NoteName): void {
    fireOnChanged(root, chordQuality, 0);
  }

  function onQualityChanged(quality: ChordQuality): void {
    if (rootNote) {
      fireOnChanged(rootNote, quality, 0);
    }
  }

  function onVariationChanged(variation: string): void {
    if (variations) {
      const varNumber = parseInt(variation) - 1;
      fireOnChanged(rootNote, chordQuality, varNumber);
    }
  }

  function selectNextVariation(incr = 1): void {
    let variation = variationNumber + incr;
    if (variation >= variations.length) {
      variation = 0;
    }
    else if (variation < 0) {
      variation = variations.length - 1
    }

    onVariationChanged((variation + 1).toString());
  }

  function fireOnChanged(root: NoteName | "", quality: ChordQuality | "", inversion: number): void {
    props.onChanged({
      root: root,
      quality: quality,
      inversion: inversion
    });
  }
}

/**
 * Gets chord variation selection list
 */
function getChordVariationsMenuList(lookupSvc: GuitarLookupService, root: NoteName | "", quality: ChordQuality | ""): NameValueTitle[] {
  const count = lookupSvc.getChordVariationCount(root as NoteName, quality as ChordQuality);
  const positions: NameValueTitle[] = [];
  for (let i = 1; i <= count; i++) {
    positions.push([i.toString(), i.toString(), "Position " + i]);
  }
  return positions;
}
