import * as Geometry from "@local/power-chord-lib/build/src/geometry";
import { GuitarPositionInfo } from "@local/power-chord-lib/build/src/state-types";
import * as analog from "analogging";
import { CanvasRenderer } from "../common/canvas-renderer";
import { GUITAR_COLORS, UKULELE_COLORS } from "./guitar-colors";

export default class GuitarRenderer extends CanvasRenderer {
    private readonly logger = analog.getLogger("GuitarRenderer");
    private readonly colors = GUITAR_COLORS;
    private readonly width: number;
    private readonly height: number;
    private readonly xScale: number;
    private readonly yScale: number;
    private readonly fretSize: number;
    private readonly stringSize: number;
    private readonly margin: number;
    private readonly fretboardGradient: CanvasGradient;

    // The positions being drawn
    private readonly stringPositions: GuitarPositionInfo[] = [];
    // The position(s) being hovered
    private hintPositions: GuitarPositionInfo[] = [];

    constructor(canvas: HTMLCanvasElement, readonly numStrings = 6, readonly numFrets = 16) {
        super(canvas);
        if (numStrings === 4) {
            this.colors = UKULELE_COLORS;
        }
        this.width = canvas.width;
        this.height = this.width * ((numStrings + 1) / 40);
        this.fretSize = this.width / this.numFrets;
        this.stringSize = this.height / (numStrings + 1);
        this.margin = this.stringSize / 2;
        this.fretboardGradient = this.createFretboardGradient();

        this.xScale = canvas.width / this.width;
        this.yScale = canvas.height / this.height;
        this.context.scale(this.xScale, this.yScale);
    }

    private createFretboardGradient() {
        return this.context.createLinearGradient(0, 0, 0, this.height,
            { offset: 0, color: this.colors.fretboard.top },
            { offset: 1, color: this.colors.fretboard.bottom });
    }

    /** Returns a list of all string positions */
    getPositions(): GuitarPositionInfo[] {
        return this.stringPositions;
    }

    /** Sets one or more positions */
    setPosition(...pos: GuitarPositionInfo[]): GuitarRenderer {
        pos.forEach(p => this.stringPositions.push(p));
        this.render();
        return this;
    }

    /** Clears all string positions */
    clearPositions(): GuitarRenderer {
        this.stringPositions.splice(0);
        this.render();
        return this;
    }

    getHintPositions(): GuitarPositionInfo[] {
        return this.hintPositions;
    }

    setHintPosition(...positions: GuitarPositionInfo[]): void {
        this.hintPositions = positions;
        this.render();
    }

    clearHintPositions(): void {
        if (this.hintPositions.length > 0) {
            this.hintPositions = [];
            this.render();
        }
    }

    /**
     * Gets the position info at a geometric point on the fretboard
     * @returns Info containing only fret and string, or undefined if not a valid position
     */
    getPositionAt(x: number, y: number): GuitarPositionInfo | undefined {
        if (y >= this.margin && y < this.height - this.margin) {
            return {
                str: this.getStringAt(x, y),
                fret: this.getFretAt(x, y),
            };
        }

        return undefined;
    }

    /**
     * Gets the number of the fret for the specified coordinates
     * @return Fret number where 0 is the nut
     */
    private getFretAt(x: number, y: number): number {
        let fret = Math.floor(x / this.fretSize / this.xScale);
        return fret;
    }

    /**
     * Gets the number of the string for the specified coordinates
     * @return String number starting at 0
     */
    private getStringAt(x: number, y: number): number {
        let str = Math.floor(((y + this.margin) / this.stringSize) / this.yScale) - 1;
        return Math.min(str, this.numStrings - 1);
    }

    /**
     * Gets the geometric point of a position on the fretboard
     * @param str 
     * @param fret 
     */
    private getFingerPoint(str: number, fret: number): Geometry.Point {
        // Using max here because -1 is the same fret as 0 (mute and open)
        let x = this.fretSize * (Math.max(fret, 0) + 1) - (this.stringSize / 2);
        let y = this.stringSize * str + this.stringSize;
        return new Geometry.Point(x, y);
    }

    ///////////////////////////////////////////////////////////////////////////
    // Drawing methods
    ///////////////////////////////////////////////////////////////////////////

    protected draw(): GuitarRenderer {
        this.logger.debug("Drawing guitar");
        this.context.clear().save();
        this.drawFretboard()
            .drawStrings()
            .drawFingerPositions()
            .drawHintPositions()
            .context.restore();

        return this;
    }

    private drawHintPositions() {
        if (this.hintPositions) {
            const color = this.colors.selection.hint;
            for (let i = 0; i < this.hintPositions.length; i++) {
                let posInfo = this.hintPositions[i];
                let point = this.getFingerPoint(posInfo.str, posInfo.fret);
                this.context
                    .strokeStyle(color)
                    .lineWidth(5)
                    .drawCircle(point.x, point.y, this.stringSize / 2);
                this.drawNoteTooltip(point, posInfo.note ? posInfo.note.formattedName : "");
            }
        }
        return this;
    }

    private drawNoteTooltip(point: Geometry.Point, note: string): void {
        const size = 16;
        const fontSize = size * 1.5;
        const top = point.y - fontSize / 2;
        const left = point.x + 16;
        const colors = this.colors.selection;

        this.context
            .save()
            .shadowStyle(colors.shadow, 1, 1, 4)
            .fillStyle(colors.hintTipBG)
            .fillRect(left, top, size * 2, fontSize)
            .restore()
            .save()
            .fillStyle(colors.hintTip)
            .font(`bold ${fontSize}px sans-serif`)
            .textAlign("left")
            .textBaseline("top")
            .fillText(note, point.x + 20, point.y - 12)
            .restore();
    }

    private drawFingerPositions() {
        const colors = this.colors.selection;
        this.context.save()
            .shadowStyle(colors.shadow, 0, 0, 4)
            .font("bold .75em sans-serif")
            .textBaseline("middle")
            .textAlign("center");

        for (let pos of this.stringPositions) {
            if (!pos) break;

            let fret = pos.fret;
            let point = this.getFingerPoint(pos.str, fret);
            if (fret < 0) {
                // Muted
                this.context
                    .strokeStyle(colors.muted.fg)
                    .lineWidth(6)
                    .drawLine(point.x - 6, point.y - 6, point.x + 6, point.y + 6)
                    .drawLine(point.x + 6, point.y - 6, point.x - 6, point.y + 6);
            }
            else {
                let noteName = pos.note ? pos.note.formattedName : "";
                let bgColor = (pos.isRoot ? colors.root.bg : colors.other.bg);
                if (fret > 0) {
                    // Fingered
                    this.context
                        .fillStyle(bgColor)
                        .fillCircle(point.x, point.y, this.stringSize / 2)
                        .fillStyle(colors.other.fg)
                        .fillText(noteName || "", point.x, point.y);
                }
                else if (fret === 0) {
                    // Open
                    this.context
                        .fillStyle(colors.open.bg)
                        .fillCircle(point.x, point.y, this.stringSize / 2)
                        .strokeStyle(bgColor)
                        .lineWidth(3)
                        .drawCircle(point.x, point.y, this.stringSize / 2 - 3)
                        .fillStyle(colors.open.fg)
                        .fillText(noteName || "", point.x, point.y);
                }
            }
        }

        this.context.restore();
        return this;
    }

    private drawFretboard() {
        this.context
            .fillStyle(this.fretboardGradient)
            .fillRect(0, 0, this.width, this.height)
            // Head (above the nut)
            .fillStyle(this.colors.fretboard.head)
            .fillRect(0, 0, this.fretSize, this.height);

        this.drawFrets()
            .drawDot(3)
            .drawDot(5)
            .drawDot(7)
            .drawDot(9)
            .drawDot(12, 2)
            .drawDot(12, 4)
            .drawDot(15);

        return this;
    }

    private drawDot(fret: number, pos: number = 3) {
        let x = this.fretSize * fret + this.fretSize / 2;
        let y = this.height / (6 / pos);
        this.context
            .fillStyle(this.colors.fretboard.dot)
            .fillCircle(x, y, 6);
        return this;
    }

    private drawNut(x: number) {
        this.context.save()
            .strokeStyle(this.colors.fretboard.nut)
            .lineWidth(6)
            .drawLine(x, 0, x, this.height)
            .restore();
        return this;
    }

    private drawFrets() {
        this.context.save()
            .strokeStyle(this.colors.fretboard.fret)
            .lineWidth(2)
            .shadowStyle(this.colors.selection.shadow, 1, 0, 4);

        for (let i = 1; i < this.numFrets; i++) {
            let x = i * this.fretSize;
            if (i === 1) {
                this.drawNut(x);
            }
            else {
                this.context.drawLine(x, 0, x, this.height);
            }
        }
        this.context.restore();
        return this;
    }

    private drawStrings() {
        const colors = this.colors.strings;
        for (let i = 0; i < this.numStrings; i++) {
            let y = this.stringSize + (i * this.stringSize);
            this.context.save()
                .strokeStyle((i < 2) ? colors.treble : colors.bass)
                .shadowStyle(colors.shadow, 1, 2, 4)
                .lineWidth(1 + i * 0.5)
                .drawLine(0, y, this.width, y)
                .restore();
        }
        return this;
    }
}