import { DEFAULT_OPEN_NOTES, getDefaultTuningGuitarLookup } from '@power-chord/music-theory/guitar/default-tuning';
import { useMemo } from "react";
// import { GuitarController } from "./guitar-controller";
import { GuitarController } from "@local/power-chord-lib/build/src/guitar/guitar-controller";
import { useStateService } from '../../hooks/useStateService';
import GuitarView from "./GuitarView";

/**
 * Wrapper of a GuitarView for a guitar with standard tuning
 */
export default function StandardGuitarView() {
  const stateService = useStateService();
  
  const controller = useMemo<GuitarController>(() => {
    const state = stateService.getState().guitar;
    return new GuitarController(DEFAULT_OPEN_NOTES, getDefaultTuningGuitarLookup(), 16, state.selectedPositions);
  },
  [stateService]);

  return (
    <GuitarView guitarType="guitar" width="1000" height="175"
      numFrets={16}
      controller={controller}
      getState={() => stateService.getState().guitar}
      saveState={state => stateService.setGuitarState(state)} />
  );
}