import { Note, getNote } from "@power-chord/music-theory";
import { SequencerRow } from "./audio/sequencer";

export type ViewName = ""|"keyboard"|"guitar"|"ukulele"|"vocals"|"rhythm"|"theory"|"progression"|"settings"|"about"|"landing";
export type ColorMode = "light"|"dark";

export type AppState = {
    version: string;
    timestamp: number;
    privacyConsent: boolean;
    currentView: ViewName;
    navExpanded: boolean;
    color: string;
    bgColor: string;
    colorMode: ColorMode;
    ledMode: "on"|"off";
    keyboard: KeyboardState;
    guitar: GuitarState;
    ukulele: GuitarState;
    theory: TheoryState;
    progression: ChordProgression;
    rhythm: RhythmState;
    vocals: VocalsState;
};

export type VocalRange = {
    low?: Note;
    high?: Note;
};

export type VocalsState = {
    range: VocalRange;
    rangeExpanded: boolean;
    tab: string;
    captureRange: VocalRange;
};

export type KeyboardSelectionMode = "0" | "1" | "M" | "m" | "7" | "M7" | "m7" | "dim";

export type KeyboardState = {
    selectedKeys: number[];
    selectionMode: KeyboardSelectionMode;
    soundOn: boolean;
    sustainOn: boolean;
};

export type GuitarPositionInfo = {
    /** String number */
    str: number;
    /** Fret number */
    fret: number;
    /** Note to be displayed */
    note?: Note;
    /** Used to flag as a root note */
    isRoot?: boolean;
};

export type GuitarState = {
    selectedPositions: GuitarPositionInfo[];
    soundOn: boolean;
};

export type ChordProgression = {
    name: string;
    description?: string,
    key: string;
    quality: string;
    items: ChordProgressionItem[];
    beatsPerMinute: number;
};

export type ChordProgressionItem = {
    chord: string;
    beats: number;
};

export type TheoryState = {
    selectedKey: string;
    selectedQuality: string;
};

export type RhythmSequence = {
    name: string,
    description?: string,
    beatsPerMinute: number;
    barCount: number;
    beatsPerBar: number;
    unitsPerBeat: number;
    rows: SequencerRow[];
};

export type RhythmState = {
    sequence: RhythmSequence;
    repeatOn: boolean;
};

export function getDefaultRhythmState(): RhythmState {
    return {
        sequence: {
            name: "",
            barCount: 1,
            beatsPerBar: 4,
            unitsPerBeat: 2,
            rows: [{
                name: "rimshot",
                beats: new Array(8).fill(false)
            }],
            beatsPerMinute: 120
        },
        repeatOn: true
    }
}

export function getDefaultVocalRange(): VocalRange {
    return {
        low: getNote("C", 2),
        high: getNote("C", 5)
    };
};
