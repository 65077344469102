import { ChordQuality, getChordFromNotes, getFormattedNoteNames, getNotes, GuitarLookupService, MusicScale, Note, NoteName } from "@power-chord/music-theory";
import * as analog from "analogging";
import { GuitarPositionInfo } from "../state-types";

export type ChordInformation = {
    root: NoteName | "";
    quality: ChordQuality | "";
    inversion: number;
};

export class GuitarController {
    private readonly logger = analog.getLogger("GuitarController");

    readonly numStrings = this.openNotes.length;

    isScaleSelected = false;
    chordName = "";
    selectedNotes = "";
    tabString = "";

    constructor(
        readonly openNotes: Note[],
        readonly guitarLookup: GuitarLookupService,
        readonly numFrets: number,
        public selectedPositions: GuitarPositionInfo[] = []
    ) {
        if (this.selectedPositions.length === 0) {
            this.logger.debug("Initializing selectedPositions");
            this.selectedPositions = (openNotes.map<GuitarPositionInfo>((note, str) => ({ str, fret: 0, note })));
        }

        this.setSelectedPositions(this.selectedPositions);
    }

    setScale(scale: MusicScale): void {
        const rootNote = scale.tonic.name;
        this.tabString = "";
        this.chordName = "";
        // Get all notes in the key
        const positions = this.getAllPositionsForNotes(scale.notes, rootNote);
        this.setSelectedPositions(positions);
    }

    private getAllPositionsForNotes(notes: Note[], rootNote: string): GuitarPositionInfo[] {
        const positions: GuitarPositionInfo[] = [];
        for (let str = 0; str < this.numStrings; str++) {
            for (let fret = 0; fret < this.numFrets; fret++) {
                const note = this.guitarLookup.getNote(str, fret);
                if (notes.some(x => x.number === note.number)) {
                    const isRoot = note.name === rootNote;
                    positions.push({ str, fret, note, isRoot });
                }
            }
        }
        return positions;
    }

    setChord(info: ChordInformation): boolean {
        this.logger.debug("Chord selected", info);

        const tab = this.guitarLookup.getChordTab(info.root as NoteName, info.quality as ChordQuality, info.inversion);
        this.logger.debug("Chord tab:", tab);
        if (tab) {
            // Need to reverse it to string order
            const positions = tab.reverse().map((str, fret) => this.getGuitarPositionInfo(str, fret, info.root));
            this.setSelectedPositions(positions);
            return true;
        }
        else {
            this.logger.error("Invalid chord", info);
            return false;
        }
    }

    private getGuitarPositionInfo(fret: number, str: number, rootNote: string): GuitarPositionInfo {
        const note = getNoteAtPosition({ str, fret }, this.openNotes);
        const isRoot = note?.name === rootNote;
        return ({ str, fret, note, isRoot } as GuitarPositionInfo);
    }

    updatePosition(pos: GuitarPositionInfo): Note | undefined {
        this.logger.debug("selected", pos);
        if (this.isScaleSelected) {
            // when showing a scale clear everything when a position is selected
            this.clearAllPositions();
        }

        // If a selected position is updated then toggle it to open or muted
        const oldPos = this.selectedPositions[pos.str];
        if (oldPos.fret === pos.fret) {
            // toggle string to open or muted
            pos.fret = (pos.fret === 0 ? -1 : 0);
        }

        // update the note
        pos.note = getNoteAtPosition(pos, this.openNotes);

        const positions = this.selectedPositions.slice();
        positions[pos.str] = pos;
        this.setSelectedPositions(positions);

        return pos.note;
    }

    /** Resets all positions back to open */
    clearAllPositions(): void {
        const positions: GuitarPositionInfo[] = [];
        for (let i = 0; i < this.numStrings; i++) {
            const pos = {
                str: i,
                fret: 0,
                note: this.openNotes[i]
            };
            positions.push(pos);
        }

        this.setSelectedPositions(positions);
    }

    setTab(positions: GuitarPositionInfo[]): void {
        positions.forEach(p => p.note = getNoteAtPosition(p, this.openNotes));
        this.setSelectedPositions(positions);
    }

    private setSelectedPositions(positions: GuitarPositionInfo[]): void {
        const notes = getNotesFromPositions(positions, this.openNotes);
        this.selectedNotes = getFormattedNoteNames(notes).join("-");
        this.isScaleSelected = positions.length > this.openNotes.length;

        this.chordName = "";
        this.tabString = "";

        if (!this.isScaleSelected) {
            this.tabString = getTab(positions);
            const chord = getChordFromNotes(...notes);
            if (chord) {
                this.chordName = chord.formattedName;

                // Update root notes
                positions.forEach(p => {
                    p.isRoot = (chord && p.note?.equalsIgnoreOctave(chord.root));
                });
            }
        }

        this.selectedPositions = positions;
    }
}

/** Turns guitar positions into a tab */
function getTab(positions: GuitarPositionInfo[]): string {
    let tab: string[] = [];
    positions.forEach(pos => {
        if (pos.fret >= 0) {
            tab.push(pos.fret > 9 ? `[${pos.fret}]` : pos.fret.toString());
        }
        else {
            tab.push("X");
        }
    });

    return tab.reverse().join("");
}

/** Turns guitar positions into notes */
function getNotesFromPositions(positions: GuitarPositionInfo[], openNotes: Note[]): Note[] {
    const numbers: number[] = [];
    positions.slice().reverse().forEach(pos => {
        if (pos.fret >= 0) {
            const number = (openNotes[pos.str].number + pos.fret) % 12;
            if (numbers.indexOf(number) < 0) {
                numbers.push(number);
            }
        }
    });

    return getNotes(...numbers);
}

/**
 * Gets the note at a position, or undefined if no note
 */
export function getNoteAtPosition(pos: GuitarPositionInfo, openNotes: Note[]): Note | undefined {
    return pos.fret >= 0 ? openNotes[pos.str].transpose(pos.fret) : undefined;
}
