import { CircleOfFifths, getScale, MusicScale, Note } from "@power-chord/music-theory";
import { COF_NOTES, getCircleOfFifths, MODE_NAMES } from "@power-chord/music-theory/circle-of-fifths";
import { normalizeMode } from "@power-chord/music-theory/circle-of-fifths";
import { CanvasRenderer } from "../common/canvas-renderer";
import getCircleOfFifthsColors from "./circle-of-fifths-colors";

const CIRCLE_FIFTHS = COF_NOTES.getList();

const ANGLE_PER_NOTE = Math.PI * 2 / 12;
const PI_OVER2 = Math.PI / 2;

type CofDimensions = {
    width: number;
    radius: number;
    centerX: number;
    centerY: number;
    textXOff: number; // better alignment for left side text
    fontSize: number;
    notesDistance: number;
    numbersDistance: number;
};

export class CircleOfFifthsRenderer extends CanvasRenderer {
    private dimensions: CofDimensions;
    // animations
    //private toAngle = 0;
    //private curAngle = 0;

    private circleOfFifths: CircleOfFifths = getCircleOfFifths(getScale("C"))

    private readonly colors = getCircleOfFifthsColors();

    constructor(canvas: HTMLCanvasElement, shadowColor = "cyan") {
        super(canvas);
        this.colors.shadow = shadowColor;

        const width = Math.min(canvas.width, canvas.height) - 2;
        const radius = width / 2;
        this.dimensions = {
            width: width,
            radius: radius,
            centerX: radius + 1,
            centerY: radius + 1,
            textXOff: 2,
            notesDistance: radius * .85,
            numbersDistance: radius * .6,
            fontSize: width * .105
        };
    }

    setScale(scale: MusicScale): void {
        this.circleOfFifths = getCircleOfFifths(scale);
        this.render();

    }

    protected draw(): CircleOfFifthsRenderer {
        const d = this.dimensions;

        this.context
            .save()
            .clear()
            .lineWidth(1)
            .fillStyle(this.colors.background)
            .fillCircle(d.centerX, d.centerY, d.radius - 1)
            .strokeStyle(this.colors.outline)
            .drawCircle(d.centerX, d.centerY, d.radius - 1)
            .restore();

        const modeIndex = this.getModeIndex();
        this//.drawGuides(d)
            .drawSelection(d, modeIndex)
            .drawNotes(d)
            .drawRomanNumbers(d, modeIndex);

        this.context.restore();

        return this;
    }

    private drawSelection(d: CofDimensions, modeIndex: number): CircleOfFifthsRenderer {
        const startAngle = (ANGLE_PER_NOTE * (-modeIndex - .5)) - PI_OVER2;
        const endAngle = startAngle + ANGLE_PER_NOTE * 7;
        const innerDistance = d.numbersDistance * .85;
        const divDistance = innerDistance + (d.radius - innerDistance) / 2.4;
        const majorP1 = toCartesian(startAngle, innerDistance, d.centerX, d.centerY);
        const minorP1 = toCartesian(startAngle + ANGLE_PER_NOTE * 3, innerDistance, d.centerX, d.centerY);
        const minorP2 = toCartesian(startAngle + ANGLE_PER_NOTE * 3, d.radius, d.centerX, d.centerY);
        const dimP1 = toCartesian(startAngle + ANGLE_PER_NOTE * 6, innerDistance, d.centerX, d.centerY);
        const dimP2 = toCartesian(startAngle + ANGLE_PER_NOTE * 6, d.radius, d.centerX, d.centerY);
        const divP1 = toCartesian(startAngle, divDistance, d.centerX, d.centerY);
        const color = this.colors.shadow;

        this.context.save()
            .fillStyle(this.colors.selection)
            .strokeStyle(color)
            .shadowStyle(color, 0, 0, 8)
            .beginPath()
            .moveTo(majorP1.x, majorP1.y)
            .arc(d.centerX, d.centerY, d.radius, startAngle, endAngle)
            .arc(d.centerX, d.centerY, innerDistance, endAngle, startAngle, true)
            .fill()
            .stroke()
            .lineWidth(.5)
            .strokeStyle(color)
            .drawLine(minorP1.x, minorP1.y, minorP2.x, minorP2.y)
            .drawLine(dimP1.x, dimP1.y, dimP2.x, dimP2.y)
            .moveTo(divP1.x, divP1.y)
            .arc(d.centerX, d.centerY, divDistance, startAngle, endAngle)
            .stroke()
            ;

        this.context.restore();
        return this;
    }

    private drawRomanNumbers(d: CofDimensions, modeIndex: number): CircleOfFifthsRenderer {
        this.context.save()
            .font(d.fontSize * .5 + "px sans-serif")
            .textAlign("center")
            .textBaseline("middle")
            .fillStyle(this.colors.degrees);

        // Angle starts with the previous fifth
        const startAngle = (-modeIndex * ANGLE_PER_NOTE) - PI_OVER2;
        this.circleOfFifths.fifths.forEach((fifth, i) => {
            const a = (i * ANGLE_PER_NOTE) + startAngle;
            const point = toCartesian(a, d.numbersDistance, d.centerX, d.centerY);
            this.context.fillText(fifth.degreeRoman, point.x + d.textXOff, point.y);
        });

        this.context.restore();
        return this;
    }

    private drawNotes(d: CofDimensions): CircleOfFifthsRenderer {
        const tonicIdx = this.getTonicIndex();
        const startAngle = (-tonicIdx * ANGLE_PER_NOTE) - PI_OVER2;

        this.context.save()
            .font(d.fontSize + "px sans-serif")
            .textAlign("center")
            .textBaseline("middle");

        CIRCLE_FIFTHS.forEach((note, i) => {
            this.context.fillStyle(this.getNoteColor(note));
            const a = (i * ANGLE_PER_NOTE) + startAngle;
            const point = toCartesian(a, d.notesDistance, d.centerX, d.centerY);

            const fifth = this.circleOfFifths.fifths.find(f => f.note.number === note.number);
            const noteName = (fifth ? fifth.note.formattedName : note.formattedName);
            this.context.fillText(noteName, point.x + d.textXOff, point.y);
        });

        this.context.restore();
        return this;
    }

    /** Gets the color to draw a note name */
    private getNoteColor(note: Note): string {
        if (note === this.circleOfFifths.scale.tonic) {
            return this.colors.notes.root;
        }

        const fifth = this.circleOfFifths.fifths.find(f => f.note.number === note.number);
        if (fifth) {
            return fifth.quality === "d" ? this.colors.notes.diminished :
                fifth.quality === "m" ? this.colors.notes.minor :
                    this.colors.notes.major;
        }

        return this.colors.notes.other;
    }

    private getTonicIndex(): number {
        const tonic = this.circleOfFifths.scale.tonic;
        const tonicIdx = CIRCLE_FIFTHS.findIndex(n => n.equalsIgnoreOctave(tonic));
        if (tonicIdx < 0) {
            throw new Error("Couldn't find tonic: " + tonic);
        }
        return tonicIdx;
    }

    private getModeIndex(): number {
        const mode = normalizeMode(this.circleOfFifths.scale.mode);
        const modeIndex = MODE_NAMES.indexOf(mode);
        if (modeIndex < 0) {
            throw new Error("Couldn't find mode: " + mode);
        }
        return modeIndex;
    }

    private drawGuides(d: CofDimensions): CircleOfFifthsRenderer {
        this.context
            .strokeStyle("gray")
            .drawLine(d.radius, 0, d.radius, d.width)
            .drawLine(0, d.radius, d.width, d.radius);
        return this;
    }
}

function toCartesian(angle: number, length: number, xoff: number, yoff: number): { x: number, y: number } {
    return {
        x: length * Math.cos(angle) + xoff,
        y: length * Math.sin(angle) + yoff
    };
}