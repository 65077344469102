const KeyboardToKeyNumberMap: {[key: string]: number} = {
    // Top row
    "q": 0, // C1
    "2": 1, // C#
    "w": 2, // D
    "3": 3, // D#
    "e": 4, // E
    "r": 5, // F
    "5": 6, // F#
    "t": 7, // G
    "6": 8, // Ab
    "y": 9, // A
    "7": 10,// Bb
    "u": 11,// B
    "i": 12,// C2
    "9": 13,// C#
    "o": 14,// D
    "0": 15,// D#
    "p": 16,// E
    "[": 17,// F
    "=": 18,// F#
    "]": 19,// G

    // Bottom row
    "z": 12,// C2
    "s": 13,// C#
    "x": 14,// D
    "d": 15,// D#
    "c": 16,// E
    "v": 17,// F
    "g": 18,// F#
    "b": 19,// G
    "h": 20,// Ab
    "n": 21,// A
    "j": 22,// Bb
    "m": 23,// B
    ",": 24 // C3
};

/**
 * Gets the key number for a keyboard event key, or -1 if not defined
 * @param key The value of a keyboard event key
 */
export function getKeyNumberForKey(key: string): number{
    const keyNum = KeyboardToKeyNumberMap[key];
    return isFinite(keyNum) ? keyNum : -1;
}