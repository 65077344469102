import { VocalRange } from '@local/power-chord-lib/build/src/state-types';
import { VocalRangeSpan, getVocalRangeSpan } from '@local/power-chord-lib/build/src/vocals/get-vocal-range-span';
import { AudioTuner } from 'audio-analyzer';
import { useMemo, useState } from 'react';
import RenderWhen from '../common/RenderWhen';
import VocalRangeTest from './VocalRangeTest';

type VocalRangeFinderProps = {
  tuner: AudioTuner;
  range: VocalRange;
  expanded: boolean;
  onExpandChanged: (expanded: boolean) => any;
  onRangeChanged: (range: VocalRange) => any;
};

export default function VocalRangeControl(props: VocalRangeFinderProps) {
  // Need to keep of range internally to validate before committing
  const [range, setRange] = useState<VocalRange>(props.range);
  const [error, setError] = useState<string>();

  const rangeSpan = useMemo<VocalRangeSpan>(() => getVocalRangeSpan(range), [range]);

  const hasRange = Boolean(props.range.low && props.range.high);

  return (
    <div className="vocal-range">
      <RenderWhen condition={!hasRange}>
        <p>Use the controls below to find your vocal range.</p>
      </RenderWhen>
      <RenderWhen condition={hasRange}>
        <p>
          <button className="toggle" onClick={() => toggleExpanded()}>
            {props.expanded ? <span title="Hide controls">&#9650;</span> : <span title="Show controls">&#9660;</span>}
          </button>
          Your range is <span className="note">{props.range.low?.toString(true)}</span>
          &nbsp;to <span className="note">{props.range.high?.toString(true)}</span>
          , {rangeSpan.octaves} octaves {rangeSpan.notes ? `+ ${rangeSpan.notes} notes.` : ""}
        </p>
      </RenderWhen>
      <RenderWhen condition={!hasRange || props.expanded}>
        <div>
          <RenderWhen condition={Boolean(error)}>
            <div className="error">{error}</div>
          </RenderWhen>
          <VocalRangeTest tuner={props.tuner} range={range} onChange={onRangeChanged} />
        </div>
      </RenderWhen>
    </div>
  );

  function toggleExpanded(): void {
    props.onExpandChanged(!props.expanded);
  }

  function onRangeChanged(range: VocalRange): any {
    const newRange = Object.assign({}, range);
    setRange(newRange)
    setError("");

    if (newRange.low && newRange.high) {
      if (newRange.high.midiNumber < newRange.low.midiNumber) {
        setError("Your high must be higher than your low!");
        return;
      }
    }

    props.onRangeChanged(newRange);
  }
}
