import { VocalRange } from '../state-types';

export type VocalRangeSpan = {
  octaves: number;
  notes: number;
}

export function getVocalRangeSpan(range: VocalRange): VocalRangeSpan {
  let octaves = 0;
  let notes = 0;
  if (range.low && range.high && range.low.keyNumber < range.high.keyNumber) {
    const span = (range.high.midiNumber - range.low.midiNumber);
    octaves = Math.floor(span / 12);
    notes = span - octaves * 12;
  }

  return { octaves, notes };
}
