import { featureFlags } from "@local/power-chord-lib/build/src/services/feature-flag-service";
import { Chord, getNote, MusicScale } from "@power-chord/music-theory";
import { useEffect, useRef, useState } from "react";
import { useAudioLib } from "../../hooks/useAudioLib";
import { useLogger } from "../../hooks/useLogger";
import SoundIcon from "../common/icons/SoundIcon";
import PlayNotesButton from "../common/PlayNotesButton";
import PopupMenuButton, { NameValueTitle } from "../common/PopupMenuButton";
import RenderWhen from "../common/RenderWhen";
import ScaleSelector from "../common/ScaleSelector";
import SplitContainer from "../common/SplitContainer";
import ToggleSwitch from "../common/ToggleSwitch";
import KeyboardStaffRenderer from "./keyboard-staff-renderer";
import KeyboardChordSelector from "./KeyboardChordSelector";
import "./KeyboardControls.scss";

const SELECTION_MODES: NameValueTitle[] = [
  ["⦸", "0", "None"],
  ["1", "1", "Single Note"],
  ["M", "M", "Major"],
  ["m", "m", "Minor"],
  ["7", "7", "7th"],
  ["M7", "M7", "Major 7th"],
  ["m7", "m7", "Minor 7th"],
  ["dim", "dim", "Diminished"],
];

let _isAudioLoaded = false;
let _audioError = "";

export type KeyboardControlsProps = {
  selectedKeys: number[];
  selectedNotes: string;
  chordName: string;
  selectionMode: string;
  shareMenuItems: string[];
  soundOn: boolean;
  sustainOn: boolean;
  onSelectionModeChanged: (mode: string) => void;
  onClearSelections: () => void;
  onSoundChanged: (soundOn: boolean) => void;
  onSustainChanged: (sustainOn: boolean) => void;
  onPlayNotes: () => void;
  onChordSelected: (chord: Chord) => void;
  onScaleSelected: (key: MusicScale) => void;
  onShareClicked: (shareType: string) => void
};

export default function KeyboardControls(props: KeyboardControlsProps) {
  const logger = useLogger("KeyboardControls");
  const audioLib = useAudioLib("piano");
  // Used to control when to clear the chord selector
  const selectingChord = useRef(false);
  const selectingKey = useRef(false);

  const staffCanvasRef = useRef<HTMLCanvasElement>(null);
  const staffRenderer = useRef<KeyboardStaffRenderer>();

  const [audioLoaded, setAudioLoaded] = useState(_isAudioLoaded);
  const [audioError, setAudioError] = useState(_audioError);
  const [audioPercent, setAudioPercent] = useState(0);
  const [selectedChord, setSelectedChord] = useState<Chord>();
  const [selectedScale, setSelectedScale] = useState<MusicScale>();

  // When selected keys change update the staff
  useEffect(() => {
    if (staffCanvasRef.current) {
      if (!staffRenderer.current) {
        staffRenderer.current = new KeyboardStaffRenderer(staffCanvasRef.current);
      }
      staffRenderer.current.clearNotes();
      props.selectedKeys.forEach(key => {
        const octave = 2 + Math.floor(key / 12);
        staffRenderer.current!.addNote(getNote(key).name, octave);
      });
    }
  }, [props.selectedKeys]);

  // When selected keys change and we aren't selecting then clear the chord & scale selectors 
  useEffect(() => {
    if (!selectingChord.current) {
      setSelectedChord(undefined);
    }
    if (!selectingKey.current) {
      setSelectedScale(undefined);
    }

    selectingChord.current = false;
    selectingKey.current = false;
  }, [props.selectedKeys]);

  useEffect(() => {
    if (props.soundOn && !audioLoaded) {
      loadAudio();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioLoaded, props.soundOn]);

  return (
    <div className="controls">
      <SplitContainer direction="vertical" leftWeight={1} rightWeight={1}>
        <SplitContainer direction="vertical" leftWeight={1}>
          <section className="section-0">
            <div>
              <label>Selection Mode:</label>
              <PopupMenuButton
                menuItems={SELECTION_MODES}
                value={props.selectionMode}
                title="Selection Mode"
                onChange={selectionModeChanged} />
            </div>
            <div>
              <button onClick={() => clearSelections()} title="Clear selections (Escape)">Reset</button>
            </div>
            <div className="chord-selection">
              <label>Select Chord:</label>
              <KeyboardChordSelector chord={selectedChord} onChanged={onChordSelected} />
            </div>
            <div className="chord-selection">
              <label>Select Scale:</label>
              <ScaleSelector
                selectedMode={selectedScale?.mode}
                selectedTonic={selectedScale?.tonic.name}
                selectedScaleType={selectedScale?.scaleType}
                onChange={onScaleSelected} />
            </div>
          </section>

          <section className="section-1">
            <div className="large">
              <label>Chord:</label> <span>{props.chordName}</span>
            </div>
            <div className="sound">
              <label>Sound:</label>
              <ToggleSwitch
                checked={props.soundOn}
                onChange={toggleSound}
                icons={{ checked: <SoundIcon on={true} />, unchecked: null }} />
            </div>
            <RenderWhen condition={featureFlags.shareLink && props.shareMenuItems.length > 0}>
              <>
                <div>&nbsp;</div>
                <div className="share">
                  <label>Share: </label>
                  <PopupMenuButton
                    menuItems={props.shareMenuItems.map(i => [i])}
                    value=""
                    onChange={props.onShareClicked}
                    title="Select Share Link" />
                </div>
              </>
            </RenderWhen>
          </section>
        </SplitContainer>
        <SplitContainer direction="vertical" leftWeight={1}>
          <section className="section-2">
            <div className="large">
              <label>Notes:</label> <span>{props.selectedNotes}</span>
            </div>
            <RenderWhen condition={props.soundOn}>
              <div className="sound">
                <PlayNotesButton
                  soundOn={props.soundOn}
                  audioError={audioError}
                  audioLoaded={audioLoaded}
                  audioPercent={audioPercent}
                  onClick={props.onPlayNotes} />
                <label className="sustain"><ToggleSwitch checked={props.sustainOn} onChange={toggleSustain}/><span>Sustain</span></label>
              </div>
            </RenderWhen>
          </section>

          <section className="section-3">
            <div>
              <canvas ref={staffCanvasRef} width="100" height="81"></canvas>
            </div>
          </section>
        </SplitContainer>
      </SplitContainer>
    </div>
  );

  function onScaleSelected(scale: MusicScale): void {
    setSelectedScale(scale);
    selectingKey.current = true;
    props.onScaleSelected(scale);
  }

  function onChordSelected(chord: Chord): any {
    setSelectedChord(chord);
    selectingChord.current = true;
    props.onChordSelected(chord);
  }

  function clearSelections(): void {
    staffRenderer.current!.clearNotes();
    props.onClearSelections();
  }

  function selectionModeChanged(quality: string): any {
    props.onSelectionModeChanged(quality);
  }

  function toggleSound(): void {
    const soundOn = !props.soundOn;
    if (soundOn && !_isAudioLoaded) {
      loadAudio();
    }
    props.onSoundChanged(soundOn);
  }

  function toggleSustain(): void {
    props.onSustainChanged(!props.sustainOn);
  }

  function loadAudio(): void {
    audioLib.load(pct => setAudioPercent(pct))
      .then(() => {
        _isAudioLoaded = true;
        setAudioPercent(1);
        setAudioLoaded(true)
      })
      .catch(err => {
        logger.error(err);
        setAudioError(err.message);
      });
  }
}
