import React from "react";

function PowerChordIcon() {
  return (
    <svg className="powerchord"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43.846 7.696"
    >
      <path
        d="M15.495 32.593c0-.381.313-.686.703-.686a.69.69 0 11-.373 1.27v1.584c.127.016.246.025.373.025a2.193 2.193 0 10-2.193-2.193v3.65h1.49v-.298h-.823l-.001-.198h.824v-.199h-.824v-.203h.824v-.303h-.824v-.198h.824v-.198h-.824l.003-.199h.821v-.203h-.824v-.193h.824v-.308h-.824v-.204l.824.006v-.199h-.824v-.198h.824zM18.921 32.593a2.193 2.193 0 104.387 0 2.193 2.193 0 00-4.387 0zm1.499 0c0-.381.304-.686.694-.686s.694.305.694.686c0 .39-.305.694-.694.694s-.694-.305-.694-.694zM26.012 30.57v2.388c0 .186-.16.33-.347.33a.325.325 0 01-.33-.33V30.57h-1.49v2.396c0 1.008.812 1.82 1.82 1.82.398 0 .762-.118 1.067-.338.296.22.66.338 1.058.338a1.832 1.832 0 001.837-1.82V30.57H28.12v2.388c0 .186-.152.33-.339.33s-.33-.144-.33-.33V30.57zM34.509 32.974h-1.465a.791.791 0 01-.66.339c-.026 0-.085-.009-.085-.009l1.888-1.905a2.212 2.212 0 00-1.845-.999 2.193 2.193 0 000 4.386 2.208 2.208 0 002.167-1.812zm-2.862-.135a.987.987 0 01-.059-.322c0-.44.356-.796.796-.796a.76.76 0 01.288.05zM35.039 32.458v2.227h1.49v-2.286c0-.314.254-.568.559-.568v-1.43a2.052 2.052 0 00-2.049 2.057zM41.537 32.957h-1.575a.676.676 0 01-.592.33.687.687 0 01-.695-.694c0-.381.305-.686.695-.686.177 0 .33.06.457.161l1.058-1.05a2.174 2.174 0 00-1.515-.618 2.193 2.193 0 000 4.386 2.21 2.21 0 002.167-1.829zM43.558 32.314c0-.229.186-.406.415-.406.22 0 .406.177.406.406v2.37h1.49v-2.632c0-.915-.736-1.651-1.642-1.651-.237 0-.466.059-.669.144v-1.999h-1.49v6.139h1.49zM46.404 32.593a2.193 2.193 0 104.387 0 2.193 2.193 0 00-4.387 0zm1.499 0c0-.381.304-.686.694-.686.39 0 .694.305.694.686 0 .39-.305.694-.694.694a.687.687 0 01-.694-.694zM51.328 32.458v2.227h1.49v-2.286c0-.314.254-.568.559-.568v-1.43a2.052 2.052 0 00-2.049 2.057zM56.359 32.545a.692.692 0 01-.703.686.69.69 0 11.373-1.27v-1.583a2.755 2.755 0 00-.373-.026 2.193 2.193 0 102.193 2.193v-3.65h-1.49v.298h.823l.001.198h-.824v.199h.824v.203h-.824v.303h.824v.198h-.824v.198h.824l-.003.199h-.821v.203h.824v.193h-.824v.308h.824v.204l-.824-.006v.198h.824v.199h-.824z"
        transform="translate(-14.005 -28.546)"
      ></path>
    </svg>
  );
}

export default PowerChordIcon;
