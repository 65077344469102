import * as React from 'react';
import ClearIcon from '../common/icons/ClearIcon';
import OpenIcon from '../common/icons/OpenIcon';
import SaveIcon from '../common/icons/SaveIcon';
import UndoIcon from '../common/icons/UndoIcon';

type ProgressionToolbarProps = {
  isPlaying: boolean;
  onClear: () => any;
  onDelete: () => any;
  onSaveFile: () => any;
  onOpenFile: () => any;
  onUndo: () => any;
  onRedo: () => any;
  canUndo: boolean;
  canRedo: boolean;
};

export default function ProgressionToolbar(props: ProgressionToolbarProps) {
  return (
    <div className="controls">
      <section className="toolbar">
        <button onClick={props.onClear} title="Clear Progression (Escape)" className={props.isPlaying ? "disabled" : ""}>
          <ClearIcon />
        </button>
        <button onClick={props.onOpenFile} title="Open (Shift+O)"><OpenIcon /></button>
        <button onClick={props.onSaveFile} title="Save (Shift+S)"><SaveIcon /></button>
        <button onClick={props.onDelete} title="Delete Last Chord (Backspace)" className={props.isPlaying ? "disabled" : ""}>&#9003;</button>
        <button onClick={props.onUndo} title="Undo (Ctrl+Z)" className={props.canUndo ? "" : "disabled"}><UndoIcon /></button>
        <button onClick={props.onRedo} title="Redo (Ctrl+Y)" className={props.canRedo ? "redo" : "redo disabled"}><UndoIcon /></button>
      </section>
    </div>
  );
}