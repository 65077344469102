import React, { useMemo } from "react";
import PopupMenuButton, { NameValueTitle } from "../common/PopupMenuButton";
import { NoteName, Chord, ChordQuality, getChord } from "@power-chord/music-theory";
import NoteSelector from "../common/NoteSelector";
import RenderWhen from "../common/RenderWhen";

const QUALITY_MENU_ITEMS: NameValueTitle[] = [
  ["M", "M", "Major"],
  ["m", "m", "Minor"],
  ["7", "7", "7th"],
  ["M7", "M7", "Major 7th"],
  ["m7", "m7", "Minor 7th"],
  ["dim", "dim", "Diminished"],
  ["dim7", "dim7", "Diminished 7th"],
  ["sus4", "sus4", "Suspend 4"],
  ["sus2", "sus2", "Suspend 2"],
  ["aug", "aug", "Augmented"],
  ["5", "5", "Fifth"],
  ["M6", "M6", "Major 6th"],
  ["m6", "m6", "Minor 6th"],
];

type KeyboardChordSelectorProps = {
  chord?: Chord;
  onChanged: (chord: Chord) => any;
}

/**
 * Chord selector component allows user to select a chord by name, quality and bass note (inversion).
 * Fires an onChange event when the chord selection changes.
 */
export default function KeyboardChordSelector(props: KeyboardChordSelectorProps) {
  const rootNote = useMemo(() => props.chord?.root.name ?? "", [props.chord]);
  const chordQuality = useMemo(() => props.chord?.quality ?? "", [props.chord]);
  const bassNote = useMemo(() => props.chord?.bass.name ?? "", [props.chord]);
  const bassNotes = useMemo(() => {
    if (rootNote && chordQuality) {
      const notes = getChord(rootNote as NoteName, chordQuality as ChordQuality).notes;
      return notes.map((n, i) => [n.formattedName, n.name, getInversionName(i)] as NameValueTitle);  
    }
    return [];
  }, [chordQuality, rootNote])

  return (
    <div className="chord selector">
      <NoteSelector selectedNote={rootNote} title="Select Root" onChange={e => onRootChanged(e)}></NoteSelector>
      <PopupMenuButton
        menuItems={QUALITY_MENU_ITEMS}
        value={chordQuality}
        title="Select Quality"
        onChange={e => onQualityChanged(e as ChordQuality)}>
      </PopupMenuButton>
      <RenderWhen condition={Boolean(rootNote)}>
        <span> /
          <PopupMenuButton
            menuItems={bassNotes}
            value={bassNote}
            title="Select Bass Note (Inversion)"
            onChange={e => onBassNoteChanged(e as NoteName)}>
          </PopupMenuButton>
        </span>
      </RenderWhen>
    </div>
  );

  function onRootChanged(root: NoteName): void {
    fireOnChanged(root, chordQuality, root);
  }

  function onQualityChanged(quality: ChordQuality): void {
    if (rootNote) {
      fireOnChanged(rootNote, quality, bassNote);
    }
  }

  function onBassNoteChanged(bass: NoteName): void {
    if (bassNotes) {
      fireOnChanged(rootNote, chordQuality, bass);
    }
  }

  function fireOnChanged(root: string, quality: string, bass: string): void {
    props.onChanged(getChord(root as NoteName, quality as ChordQuality, bass as NoteName));
  }
}

function getInversionName(inversion: number): string {
  return inversion === 0 ? "Root" : "Inversion " + inversion;
}
