import { deserializeNote } from "@power-chord/music-theory";
import * as analog from "analogging";
import { debounce } from "debounce";
import { AppState, ChordProgression, getDefaultRhythmState, getDefaultVocalRange, GuitarPositionInfo, GuitarState, KeyboardState, RhythmState, TheoryState, VocalRange, VocalsState } from "../state-types";

const DEFAULT_DEBOUNCE_TIME = 1000;
const STORAGE_KEY = "powerchord.state";

function getDefaultState(version: string): AppState {
    return {
        version: version,
        timestamp: Date.now(),
        privacyConsent: false,
        currentView: "",
        navExpanded: true,
        color: "Cyan",
        bgColor: "Blue",
        colorMode: "dark",
        ledMode: "on",
        keyboard: {
            selectedKeys: [],
            selectionMode: "1",
            soundOn: false,
            sustainOn: true
        },
        guitar: {
            selectedPositions: [],
            soundOn: false
        },
        ukulele: {
            selectedPositions: [],
            soundOn: false
        },
        theory: {
            selectedKey: "C",
            selectedQuality: "major"
        },
        progression: {
            name: "unnamed",
            key: "C",
            quality: "major",
            items: [],
            beatsPerMinute: 120
        },
        rhythm: getDefaultRhythmState(),
        vocals: {
            range: {},
            rangeExpanded: true,
            tab: "wave",
            captureRange: getDefaultVocalRange()
        }
    };
}

export default interface StateService {
    loadState(): Promise<AppState>;
    saveState(immediate?: boolean): Promise<void>;
    /** Resets state to default and saves it */
    resetState(): Promise<AppState>;
    getState(): AppState;
    state: AppState;

    /** A function to call when state changes */
    onStateChanged: (state: AppState) => void;

    /** Persists entire state */
    setState(state: AppState): Promise<void>;
    setKeyboardState(state: KeyboardState): Promise<void>;
    setGuitarState(state: GuitarState): Promise<void>;
    setUkuleleState(state: GuitarState): Promise<void>;
    setRhythmState(state: RhythmState): Promise<void>;
    setTheoryState(state: TheoryState): Promise<void>;
    setProgressionState(state: ChordProgression): Promise<void>;
    setVocalsState(state: VocalsState): Promise<void>;
}

abstract class BaseStateService implements StateService {
    /** Saves state to persistent storage */
    protected abstract saveToStorage(): Promise<void>;

    private saveStateDebounce: any;
    protected logger = analog.getLogger("BaseStateService");
    protected _state: AppState = getDefaultState(this.version);
    get state(): AppState {
        return this._state;
    }
    
    onStateChanged: () => void = () => undefined;

    constructor(readonly version: string, debounceTime = DEFAULT_DEBOUNCE_TIME) {
        this.saveStateDebounce = debounce(() => this.saveToStorage(), debounceTime);
    }

    getState(): AppState {
        if (!this.state) throw new Error("You must call loadState() before calling getState()");
        return this.state;
    }

    async loadState(): Promise<AppState> {
        this.logger.debug("Loading state");
        try {
            const jsonState = localStorage.getItem(STORAGE_KEY);
            if (jsonState) {
                // Merge in saved state
                Object.assign(this.state, JSON.parse(jsonState));
                this.upgradeState(this.state);
                this.deserialize(this.state);
            };
            return Promise.resolve(this.state);
        }
        catch (err) {
            this.logger.debug("Error loading state", (err as any).message);
            return Promise.resolve(getDefaultState(this.version));
        }
    }
    
    async resetState(): Promise<AppState> {
        this._state = getDefaultState(this.version);
        try {
            this.saveState();
            return Promise.resolve(this.state);
        }
        catch (err) {
            this.logger.error("Error saving state", (err as any).message);
            return Promise.reject((err as any).message);
        }
    }

    async setState(state: AppState): Promise<void> {
        this._state = state;
        await this.saveState();
    }

    async setKeyboardState(state: KeyboardState): Promise<void> {
        this.state.keyboard = state;
        return this.saveState();
    }

    async setGuitarState(state: GuitarState): Promise<void> {
        this.state.guitar = state;
        return this.saveState();
    }

    async setUkuleleState(state: GuitarState): Promise<void> {
        this.state.ukulele = state;
        return this.saveState();
    }

    async setRhythmState(state: RhythmState): Promise<void> {
        this.state.rhythm = state;
        return this.saveState();
    }

    async setTheoryState(state: TheoryState): Promise<void> {
        this.state.theory = state;
        return this.saveState();
    }
    
    async setProgressionState(state: ChordProgression): Promise<void> {
        this.state.progression = state;
        return this.saveState();
    }
    
    async setVocalsState(state: VocalsState): Promise<void> {
        this.state.vocals = state;
        return this.saveState();
    }

    async saveState(immediate = false): Promise<void> {
        this.onStateChanged();

        if (immediate) {
            this.saveStateDebounce.clear();
            return this.saveToStorage();
        }
        else {
            this.saveStateDebounce();
        }
    }

    /** Performs any last minute deserialization of the parsed state */
    protected deserialize(state: AppState): void {
        this.deserializeRange(state.vocals.range);
        this.deserializeRange(state.vocals.captureRange);
        
        this.deserializeGuitarPositions(state.guitar.selectedPositions);
        this.deserializeGuitarPositions(state.ukulele.selectedPositions);
    }

    private deserializeGuitarPositions(pos: GuitarPositionInfo[]): void {
        pos.forEach(pos => {
            if (pos.note) {
                pos.note = deserializeNote(pos.note as any);
            }
        });
    }

    private deserializeRange(range: VocalRange) {
        if (range.low) {
            range.low = deserializeNote(range.low as any);
        }
        if (range.high) {
            range.high = deserializeNote(range.high as any);
        }
    }

    /** Upgrades the state to a newer version */
    protected upgradeState(state: AppState): void {
    }
}

export class LocalStorageStateService extends BaseStateService {
    protected logger = analog.getLogger("LocalStorageStateService");

    async loadState(): Promise<AppState> {
        this.cleanupOldState();
        return super.loadState();
    }

    protected saveToStorage(): Promise<void> {
        this.logger.debug("Saving state");
        this.state.version = this.version;
        this.state.timestamp = Date.now();
        try {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(this.state));
            return Promise.resolve();
        }
        catch (err) {
            this.logger.error("Error saving state", (err as any).message);
            return Promise.reject((err as any).message);
        }
    }

    protected upgradeState(state: AppState): void {
        // v3 added keyboard.sustainOn
        state.keyboard.sustainOn = state.keyboard.sustainOn ?? true;

            // v2.1.2 sequence became separate property
        if (!state.rhythm.sequence) {
            state.rhythm.sequence = getDefaultState(this.version).rhythm.sequence
        }

        if ((state.keyboard.selectionMode as string) === "𝅘𝅥") {
            state.keyboard.selectionMode = "1";
        }
        else if ((state.keyboard.selectionMode as string) === "⦸") {
            state.keyboard.selectionMode = "0";
        }

        if (!state.progression.key) {
            state.progression = getDefaultState(this.version).progression;
        }
        if ((state.currentView as string) === "help") {
            state.currentView = "about";
        }
    }

    /** Removes any obsolete state from localstorage */
    private cleanupOldState(): void {
        try {
            // version 1
            localStorage.removeItem("PowerChord.settings");
        }
        catch(err) {
            this.logger.warn("Error cleaning up state", (err as any).message);
        }
    }
}
