import { ReactNode } from "react";

type RenderWhenProps = {
  condition: boolean;
  children: ReactNode | ReactNode[];
};

/**
 * Renders child elements only if the condition is true
 */
export default function RenderWhen(props: RenderWhenProps) {
  return <>{props.condition && props.children}</>
}