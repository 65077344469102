import { Point } from "@local/power-chord-lib/build/src/geometry";
import { VocalRange } from "@local/power-chord-lib/build/src/state-types";
import { TuneCaptureProcessor } from "@local/power-chord-lib/build/src/vocals/tune-capture-processor";
import { getNote, Note } from "@power-chord/music-theory";
import { debounce } from "debounce";
import React, { useEffect, useRef, useState } from "react";
import { useConst } from "../../hooks/useConst";
import RenderWhen from "../common/RenderWhen";
import { TuneCaptureRenderer } from "./tune-capture-renderer";

type TuneCaptureCanvasProps = {
  processor: TuneCaptureProcessor;
  range: VocalRange;
  maxTime: number;
  // This only here to provide a way to signal the control to redraw
  curTime: string;
  curNote?: Note;
};

export default function TuneCaptureCanvas(props: TuneCaptureCanvasProps) {
  const renderDebounce = useConst(() => debounce(() => renderer.current!.update(), 200));
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const renderer = useRef<TuneCaptureRenderer>();
  const cursorPos = useRef<Point>();

  const [cursorInfo, setCursorInfo] = useState<string>();

  // Init canvas and renderer
  useEffect(() => {
    if (canvasRef.current) {
        const canvas = canvasRef.current!;
        if (!renderer.current) {
          renderer.current = new TuneCaptureRenderer(canvas, props.processor, props.range);
        }
        renderer.current.setRange(props.range);
        updateCanvasSize(canvas, renderDebounce);

        const resizeObserver = new ResizeObserver(() => updateCanvasSize(canvas, renderDebounce));
        resizeObserver.observe(canvas.parentElement!);

        return () => {
          resizeObserver.disconnect();
        }
    }
  }, [renderDebounce, props.processor, props.range]);

  useEffect(() => {
    renderer.current?.setMaxTime(props.maxTime);
  }, [props.maxTime]);

  useEffect(() => {
    renderer.current?.render();
  }, [props.curTime]);

  return (
    <div className="canvas-container">
      <canvas width="1024" height="576" ref={canvasRef}
        onPointerLeave={() => setCursorInfo(undefined)}
        onPointerMove={e => onPointerMove(e)}>
      </canvas>
      <RenderWhen condition={Boolean(cursorInfo)}>
        <span style={getCursorNoteStyle()}>{cursorInfo}</span>
      </RenderWhen>
      <RenderWhen condition={Boolean(props.curNote)}>
        <span style={getCurrentNoteStyle()}>{props.curNote?.toString()}</span>
      </RenderWhen>
    </div>
  );

  /** Gets the style used to position the current note tooltip on the graph */
  function getCurrentNoteStyle(): React.CSSProperties {
    if (props.processor.lastTime && props.curNote) {
      const rend = renderer.current!;
      let keyNumber = props.curNote.keyNumber;
      if (keyNumber > rend.maxKey) {
        keyNumber = rend.maxKey;
      }
      else if (keyNumber < rend.minKey) {
        keyNumber = rend.minKey;
      }

      return {
        left: rend.getX(props.processor.lastTime!, rend.maxTime) + 4,
        top: Math.max(0, rend.getY(keyNumber))
      };
    }

    return {};
  }

  /** Gets the style used to position the cursor tooltip on the graph */
  function getCursorNoteStyle(): React.CSSProperties {
    return cursorPos.current ? {
      left: cursorPos.current.x + 4,
      top: cursorPos.current.y
    } : {};
  }

  function onPointerMove(ev: React.PointerEvent<HTMLCanvasElement>): void {
    if (ev.isPrimary) {
      ev.stopPropagation();
      ev.preventDefault();
      const canvas = ev.target as HTMLElement;
      const bounds = canvas.getBoundingClientRect();
      cursorPos.current = { x: ev.clientX - bounds.left, y: ev.clientY - bounds.top };

      const note = getNoteAt(cursorPos.current.y);
      const time = Math.floor(renderer.current!.maxTime * cursorPos.current.x / renderer.current!.width) / 1000;

      const cursorInfo = `${note.toString(true)} : ${time.toFixed(2)}s`;
      setCursorInfo(cursorInfo);
    }
  }

  function getNoteAt(y: number) {
    const rend = renderer.current!;
    const pct = 1 - (y / rend.height);
    const rawKey = Math.floor(pct * rend.noteCount);
    // Subtract 4 to get from A to C base
    const keyNumber = rend.minKey + rawKey - 4;
    const octave = Math.floor(keyNumber / 12) + 1;
    const note = getNote(keyNumber % 12, octave);
    return note;
  }
}

function updateCanvasSize(canvas: HTMLCanvasElement, renderDebounce: Function): void {
  const container = canvas.parentElement!;
  canvas.width = Math.max(200, container.clientWidth - 16);
  canvas.height = Math.max(200, container.clientHeight - 16);

  renderDebounce();
}
