import { ReactNode } from 'react';
import { Key } from 'ts-key-enum';
import { useEventListener } from '../../hooks/useEventListener';
import "./Modal.scss";

export type ModalProps = {
  children: ReactNode;
  onClose: () => any;
};

/**
 * Defines a modal popup that contains child components
 */
export default function Modal({ onClose, children }: ModalProps) {
  useEventListener("keyup", e => {
    if ((e as KeyboardEvent).key === Key.Escape) {
      onClose();
    }
  });

  // useEventListener("keydown", e => {
  //   e.preventDefault();
  // });

  return (
    <div className='modal'>
      <div className="content">
        {children}
        <button className='close' title="Close" onClick={onClose}>X</button>
      </div>
    </div>
  );
}
