import React from "react";

function ProgressionsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 320.07 320.07"
      xmlSpace="preserve"
    >
      <path d="M115.262 29.93v173.505c-6.239-2.564-13.111-3.921-20.305-3.921-17.458 0-35.266 7.796-48.857 21.388-25.344 25.343-28.516 63.407-7.072 84.853 9.232 9.232 22.016 14.316 35.995 14.316 17.458 0 35.266-7.796 48.857-21.388 11.843-11.843 19.308-26.842 21.018-42.234.244-2.198.355-4.379.355-6.537h.01V84.941l120-19.953v108.517c-6.239-2.564-13.111-3.921-20.305-3.921-17.458 0-35.266 7.796-48.857 21.388-25.344 25.343-28.516 63.407-7.072 84.852 9.232 9.232 22.016 14.316 35.995 14.316 17.458 0 35.266-7.796 48.857-21.388 11.843-11.843 19.308-26.842 21.018-42.234.244-2.198.354-4.379.354-6.537h.01V0L115.262 29.93z"></path>
    </svg>
  );
}

export default ProgressionsIcon;