import StateService from '@local/power-chord-lib/build/src/services/state-service';
import { ColorMode } from '@local/power-chord-lib/build/src/state-types';
import iocContainer from 'ez-ioc';
import React, { useState } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { TYPES } from '../../lib/init-dependencies';
import PopupMenuButton, { NameValueTitle } from '../common/PopupMenuButton';
import ToggleSwitch from '../common/ToggleSwitch';
import ColorModeIcon from '../common/icons/ColorModeIcon';
import "./SettingsView.css";

const stateService = iocContainer.resolve<StateService>(TYPES.StateService);

const COLOR_MENU_ITEMS: NameValueTitle[] = [
  ["Red", "Red"],
  ["Yellow", "Yellow"],
  ["Green", "Green"],
  ["Cyan", "Cyan"],
  ["Blue", "Blue"],
  ["Magenta", "Magenta"],
  ["White", "White"],
  ["Black", "Black"],
  ["None", "None"],
];
const BGCOLOR_MENU_ITEMS: NameValueTitle[] = [
  ["Red"],
  ["Yellow"],
  ["Green"],
  ["Cyan"],
  ["Blue"],
  ["Magenta"],
  ["Gray"],
  ["White"],
];

type SettingsViewProps = {
};

export default function SettingsView(props: SettingsViewProps) {
  const analytics = useAnalytics();
  const [colorMode, setColorMode] = useState<ColorMode>(stateService.state.colorMode);
  const [ledColor, setLedColor] = useState<string>(stateService.state.color);
  const [bgColor, setBgColor] = useState<string>(stateService.state.bgColor);
  const [ledMode, setLedMode] = useState(stateService.state.ledMode);

  return (
    <div className="settingsView">
      <h2>Settings</h2>
      <section>
        <div className="color-mode">
          <label>Dark Mode:</label>
          <ToggleSwitch checked={colorMode === "light"}
            onChange={toggleColorMode}
            icons={{ checked: <ColorModeIcon mode={colorMode} />, unchecked: <ColorModeIcon mode={colorMode} /> }} />
        </div>
        <div>
          <label>Border Color:</label>
          <PopupMenuButton value={ledColor} width="6em" title="Choose a color" menuItems={COLOR_MENU_ITEMS} onChange={e => colorChanged(e)}></PopupMenuButton>
          <label>LED Mode:</label>
          <ToggleSwitch checked={ledMode === "on"} onChange={toggleLedModeOn} />
        </div>
        <div>
          <label>Background:</label>
          <PopupMenuButton value={bgColor} width="6em" title="Choose a color" menuItems={BGCOLOR_MENU_ITEMS} onChange={e => backgroundChanged(e)}></PopupMenuButton>
          {/* <label>Intensity:</label>
                        <input type="range" min="20" max="128" value="40" step="10"></input> */}
        </div>
      </section>
      <section>
        <a href="#reset" title="Reset App State" onClick={e => resetClicked(e)}>Reset app to default settings</a>
      </section>
    </div>
  );

  function resetClicked(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): any {
    e.preventDefault();
    e.stopPropagation();
    return stateService.resetState().then(state => {
      setColorMode(state.colorMode);
      setBgColor(state.bgColor);
      setLedColor(state.color);
    })
  }

  function toggleColorMode(): void {
    stateService.state.colorMode = (colorMode === "light" ? "dark" : "light");
    stateService.saveState();
    setColorMode(stateService.state.colorMode);
  }

  function toggleLedModeOn(): void {
    stateService.state.ledMode = (ledMode === "on" ? "off" : "on");
    stateService.saveState();
    setLedMode(stateService.state.ledMode);
  }

  function backgroundChanged(bg: string): void {
    analytics.logEvent("settings", "background", bg);
    stateService.state.bgColor = bg;
    stateService.saveState();
    setBgColor(stateService.state.bgColor);
  }

  function colorChanged(color: string): any {
    analytics.logEvent("settings", "color", color);
    stateService.state.color = color;
    stateService.saveState();
    setLedColor(stateService.state.color);
  }
}
