import { useState } from "react";
import PopupMenuButton, { NameValueTitle } from "../common/PopupMenuButton";
import NoteSelector from "../common/NoteSelector";
import { NoteName, ChordQuality } from "@power-chord/music-theory";
import { normalize } from "@local/power-chord-lib/build/src/utils";

const CHORD_NUMBERS_M = ["I", "ii", "iii", "IV", "V", "vi", "vii°"];
const CHORD_NUMBERS_m = ["i", "ii°", "III", "iv", "v", "VI", "VII"];
const QUALITY_MENU_ITEMS: NameValueTitle[] = [
  ["M", "M", "Major"],
  ["m", "m", "Minor"],
  ["7", "7", "7th"],
  ["M7", "M7", "Major 7th"],
  ["m7", "m7", "Minor 7th"],
  ["dim", "dim", "Diminished"],
  ["dim7", "dim7", "Diminished 7th"],
  ["sus4", "sus4", "Suspend 4"],
  ["sus2", "sus2", "Suspend 2"],
  ["aug", "aug", "Augmented"],
  ["5", "5", "Fifth"],
  ["M6", "M6", "Major 6th"],
  ["m6", "m6", "Minor 6th"],
];

// Max beats that can be selected
const MAX_BEATS = 16;

type ProgressionChordSelectorProps = {
  chordsInKey: string[];
  isMinorKey: boolean;
  suggestedChords: string[];
  onSelect: (chord: string, beats: number) => any;
};

/**
 * Chord selector component allows user to select a chord by name, quality and bass note (inversion).
 * Fires an onChange event when the chord selection changes.
 */
export default function ProgressionChordSelector(props: ProgressionChordSelectorProps) {
  const [root, setRoot] = useState<NoteName | "">("");
  const [quality, setQuality] = useState<ChordQuality | "">("");
  const [beats, setBeats] = useState(4);

  return (
    <div className="chord-selector">
      <div className="chords-in-key">
        <label>In Key:</label>
        {props.chordsInKey.map((c, i) =>
          <button key={c} onClick={() => chordClicked(c)} className={getButtonClassName(c)} title={`Add ${c} (${i + 1})`}>
            {c}
            <span className="chord-number">{getChordNumber(i)}</span>
          </button>
        )}
        <label className="beats">
          Beats: <input type="number" min="1" max={MAX_BEATS} value={beats} onChange={beatsChanged} />
        </label>
      </div>
      <div className="other-chords">
        <label>Other:</label>
        <NoteSelector selectedNote={root} title="Select Root" onChange={rootChanged}/>
        <PopupMenuButton menuItems={QUALITY_MENU_ITEMS} value={quality} title="Select Quality" onChange={e => qualityChanged(e as ChordQuality)}/>
        {root && <button title="Add to progression" onClick={addClicked}>Add</button>}
      </div>
    </div>
  );

  function beatsChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    e.preventDefault();
    e.stopPropagation();
    const newBeats = parseInt(e.target.value);
    if (isFinite(newBeats)) {
      setBeats(normalize(newBeats, 1, MAX_BEATS));
    }
  }

  function chordClicked(chord: string): void {
    props.onSelect(chord, beats);
  }

  function addClicked(): void {
    chordClicked(root + quality)
  }

  function getButtonClassName(chord: string): string {
    const isSuggested = props.suggestedChords.some(c => c === chord);
    return isSuggested ? "" : "not-suggested";
  }

  function getChordNumber(i: number): string {
    const numbers = props.isMinorKey ? CHORD_NUMBERS_m : CHORD_NUMBERS_M;
    return numbers[i];
  }

  function rootChanged(newRoot: NoteName): void {
    setRoot(newRoot);
  }

  function qualityChanged(newQuality: ChordQuality): void {
    setQuality(newQuality);
  }
}