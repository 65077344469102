import { getNote, Note } from '@power-chord/music-theory';
import { AudioTuner, TunerEvent } from 'audio-analyzer';
import React, { useEffect } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useLogger } from '../../hooks/useLogger';
import SplitContainer from '../common/SplitContainer';
import AudioVisualizerCanvas from './AudioVisualizerCanvas';

export type AudioVisualizerProps = {
  tuner: AudioTuner;
};

const POLL_INTERVAL = 100;

export default function AudioVisualizer(props: AudioVisualizerProps) {
  const analytics = useAnalytics();
  const logger = useLogger("AudioVisualizer");

  const [isRunning, setIsRunning] = React.useState(props.tuner?.listener.isRunning);
  const [curNote, setCurNote] = React.useState<Note>();

  useEffect(() => {
    if (isRunning) {
      analytics.logEvent("vocals", "waveform-start");
      logger.debug("Starting tuner");
      const tunerListener = (e: TunerEvent) => onTunerNoteChanged(e);
      props.tuner.addTunerListener(tunerListener);
      props.tuner.start(POLL_INTERVAL);

      return () => {
        logger.debug("Stopping tuner");
        props.tuner.stop();
        props.tuner.removeTunerListener(tunerListener);
      };
    }
  }, [analytics, isRunning, logger, props.tuner])

  return (
    <div className="audio-visualizer">
      <SplitContainer direction="horizontal" bottomWeight={1}>
        <div className="actions">
          <button onClick={() => toggleRunning()}>{isRunning ? "Stop" : "Start"}</button>
          <label>Note: <span>{curNote ? curNote!.toString(true) : "---"}</span></label>
        </div>
        <AudioVisualizerCanvas tuner={props.tuner} isRunning={isRunning} />
      </SplitContainer>
    </div>
  );

  function toggleRunning(): void {
    // Make sure audio context is running
    props.tuner.listener.analyzer.context.resume().then(() => {
      setIsRunning(!isRunning);
    });
  }

  function onTunerNoteChanged(e: TunerEvent): void {
    let note: Note | undefined;
    if (e.note) {
      note = getNote(e.noteNumber % 12, e.octave);
      setCurNote(note);
    }
    else {
      setCurNote(undefined);
    }
  }
}
