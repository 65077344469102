export const isLocalHost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

let _urlSearchParams: Record<string, string>;

/**
 * Gets the search params from the current URL
 * @returns Name value pairs from the URL search
 */
export function getUrlSearchParams(): Record<string, string> {
    if (!_urlSearchParams) {
        _urlSearchParams = {};
        // search will have this format "?p1=va1&p2=v2"
        const params = window.location.search.slice(1).split("&");
        params.forEach(r => {
            const nameValue = r.split("=");
            _urlSearchParams[nameValue[0]] = nameValue[1] || "";
        });
    }

    return _urlSearchParams;
}

export function getUrlSearchParam(name: string): string {
    return getUrlSearchParams()[name];
}

/** Checks if a search param is defined */
export function hasUrlSearchParam(name: string): boolean {
    return getUrlSearchParam(name) != null;
}

/** Checks if we're at the root path of the app */
export function isPathRoot(): boolean {
    return window.location.pathname === "/" || window.location.pathname === "/index.html";
}
