import { getKeysToSelect, KeySelectionStrategy, multipleKeySelection, playerKeySelection, singleKeySelection } from "@local/power-chord-lib/build/src/keyboard/key-selection-strategy";
import { KeyboardShareParams, parseKeyboardShareParams } from "@local/power-chord-lib/build/src/keyboard/parse-keyboard-share-params";
import { KeyboardState } from "@local/power-chord-lib/build/src/state-types";
import { Chord, ChordQuality, getChordFromNotes, getFormattedNoteNames, getNote, MusicScale, Note, NoteName, unformatAccidentals } from "@power-chord/music-theory";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Key } from "ts-key-enum";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAudioLib } from "../../hooks/useAudioLib";
import { useEventListener } from "../../hooks/useEventListener";
import { useLogger } from "../../hooks/useLogger";
import { useStateService } from "../../hooks/useStateService";
import RenderWhen from "../common/RenderWhen";
import { ShareLinkModal } from "../common/ShareLinkModal";
import Keyboard from "./Keyboard";
import { getKeyNumberForKey } from "./keyboard-to-keynumber-map";
import KeyboardControls from "./KeyboardControls";
import "./KeyboardView.scss";

type KeyboardViewProps = {
  octaves: string;
  width: string;
  height: string;
};

// This the same as KeyboardState except all fields are optional
type StateChanges = {
  selectedKeys?: number[];
  selectionMode?: string;
  soundOn?: boolean;sustainOn?: boolean;
};

// Delay between notes when playing
const PLAY_NOTES_DELAY = 10;
// Used to compute keys from middle C
const C4_KEY_NUMBER = 40;

export default function KeyboardView(props: KeyboardViewProps) {
  const analytics = useAnalytics();
  const logger = useLogger("KeyboardView");
  const stateService = useStateService();
  const audioLib = useAudioLib("piano");

  const [persistentState, setPersistentState] = useState<KeyboardState>(stateService.getState().keyboard);
  const [hintKeys, setHintKeys] = React.useState<number[]>([]);
  const [rootNote, setRootNote] = React.useState<NoteName | "">("");
  const [chordName, setChordName] = React.useState<string>("");
  const [shareLink, setShareLink] = React.useState("");
  const [selectedNotes, setSelectedNotes] = React.useState("");

  const getSelectedKeys: KeySelectionStrategy = useMemo(() => {
    switch (persistentState.selectionMode) {
      case "0": return playerKeySelection;
      case "1": return singleKeySelection;
      default: return multipleKeySelection;
    }
  }, [persistentState.selectionMode]);

  const shareMenuItems = useMemo(() => {
      const items = [] as string[];
        if (chordName) {
          items.push("Chord: " + chordName);
        }
        if (selectedNotes) {
          items.push("Notes: " + selectedNotes);
        }
        // TODO: Determine if a scale is selected
        // if (keyboardRenderer.current?.getRootNote()) {
        //   items.push("Scale: " + formatAccidentals(keyboardRenderer.current.getRootNote()));
        // }
      return items;//.concat("Image");
  }, [chordName, selectedNotes]);

  useEffect(() => {
    updateSelectedNotes(persistentState.selectedKeys);
  }, [persistentState.selectedKeys]);

    // Check for share params in the url
    const shareParams = useParams() as KeyboardShareParams;
    useEffect(() => {
      logger.debug("shareParams", shareParams);
      if (shareParams.shareType && shareParams.shareId) {
        try {
          const shareInfo = parseKeyboardShareParams(shareParams, analytics);
          if (shareInfo) {
            if (shareInfo.chord) {
              logger.debug("Setting chord from url", shareInfo.chord);
              const keys = getKeysForChord(shareInfo.chord);
              saveSelectedKeys(keys);
            }
            else if (shareInfo.notes) {
              logger.debug("Setting notes from url", shareInfo.notes);
              const keys = shareInfo.notes.map(c => c.keyNumber - C4_KEY_NUMBER);
              saveSelectedKeys(keys);
            }
            else if (shareInfo.scale) {
              logger.debug("Setting scale from url", shareInfo.scale);
              const scaleKeys = shareInfo.scale.notes.map(n => n.number);
              saveSelectedKeys(scaleKeys, shareInfo.scale.tonic.name);          
            }
          }
          else {
            alert("Error parsing share url");
          }
        }
        catch (err) {
          alert("Error parsing share url: " + (err as Error).message);
          logger.error((err as Error).message);
          if ((err as Error).cause) {
            logger.error(((err as Error).cause as Error).message)
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareParams]);
  
  useEventListener("keydown", e => onKeyboardDown(e as KeyboardEvent));
  useEventListener("keyup", e => onKeyboardUp(e as KeyboardEvent));

  /**
   * Applies changes to persistentState and saves to persistent storage
   * @param changes KeyboardState changes
   */
  function saveState(changes: StateChanges): void {
    // This is necessary so we have the right values when state doesn't get updated before the next event
    Object.assign(persistentState, changes);
    const newState: KeyboardState = Object.assign({}, persistentState);
    setPersistentState(newState);
    stateService.setKeyboardState(newState);
  }

  return (
    <div className="keyboard-view">
      <Keyboard
        width={props.width}
        height={props.height}
        octaves={parseInt(props.octaves)}
        hintKeys={hintKeys}
        selectedKeys={persistentState.selectedKeys}
        rootNote={rootNote}
        onKeyPressed={onKeyPressed}
        onKeyReleased={onKeyReleased}
        onKeyHover={onKeyHover}
      />
      <div className="control-container">
        <KeyboardControls
          selectionMode={persistentState.selectionMode}
          selectedKeys={persistentState.selectedKeys}
          selectedNotes={selectedNotes}
          chordName={chordName}
          shareMenuItems={shareMenuItems}
          soundOn={persistentState.soundOn}
          sustainOn={persistentState.sustainOn}
          onClearSelections={clearSelections}
          onSelectionModeChanged={setSelectionMode}
          onSoundChanged={soundChanged}
          onSustainChanged={sustainChanged}
          onPlayNotes={playSelectedNotes}
          onChordSelected={chordSelected}
          onScaleSelected={scaleSelected}
          onShareClicked={shareClicked} />
      </div>
      <RenderWhen condition={Boolean(shareLink)}>
        <ShareLinkModal shareLink={shareLink} onClose={() => setShareLink("")} />
      </RenderWhen>
    </div>
  );

  function onKeyPressed(key: number): void {
    logger.debug("pressed", key);
    let keys = getSelectedKeys(key, true, persistentState.selectedKeys, persistentState.selectionMode);
    saveSelectedKeys(keys);

    if (isSelectModeSingle() || isSelectModeOff()) {
      playNotes(key);
    }
    else {
      playNotes(...keys);
    }
  }

  function onKeyReleased(key: number): void {
    logger.debug("released", key);
    if (!persistentState.sustainOn) {
      audioLib.stop(getNoteName(key));
    }
    
    let keys = getSelectedKeys(key, false, persistentState.selectedKeys);
    saveSelectedKeys(keys);
    // This is necessary so we have the right keys when state doesn't get updated before the next event
    persistentState.selectedKeys = keys;
  }

  function saveSelectedKeys(keys: number[], rootNote: (NoteName | "") = ""): void {
    setRootNote(rootNote);
    setChordName("");
    setSelectedNotes("");

    updateSelectedNotes(keys);

    saveState({ selectedKeys: keys });
  }

  function updateSelectedNotes(keys: number[]): void {
    const notes = getNotes(keys.sort((a, b) => a < b ? -1 : 1));
    if (notes.length > 0) {
      const chord = getChordFromNotes(...notes);
      if (chord) {
        setRootNote(chord.root.name);
        setChordName(chord.formattedName);
      }
      setSelectedNotes(getFormattedNoteNames(notes).join("-"));
    }
  }

  function onKeyHover(key: number): void {
    if (key >= 0) {
      if (isSelectModeSingle() || isSelectModeOff()) {
        setHintKeys([key]);
      }
      else {
        setHintKeys(getKeysToSelect(key, persistentState.selectionMode as ChordQuality));
      }
    }
    else {
      setHintKeys([]);
    }
  }

  function isSelectModeSingle(): boolean {
    return persistentState.selectionMode === "1";
  }

  function isSelectModeOff(): boolean {
    return persistentState.selectionMode === "0";
  }

  function playSelectedNotes(): void {
    analytics.logEvent("keyboard", "play-notes");//, () => keys.join(","));
    playNotes(...persistentState.selectedKeys);
  }

  function soundChanged(on: boolean): void {
    saveState({ soundOn: on });
  }

  function sustainChanged(on: boolean): void {
    saveState({ sustainOn: on });
  }

  function chordSelected(chord: Chord): void {
    analytics.logEvent("keyboard", "chord", chord.name);
    // Map keys to use two octaves of keyboard
    const keys = getKeysForChord(chord);
    saveSelectedKeys(keys);
  }

  function scaleSelected(key: MusicScale): void {
    analytics.logEvent("keyboard", "key", key.name);
    // The note numbers are analogous to key numbers
    const scaleKeys = key.notes.map(n => n.number);
    // Add the second octave
    scaleKeys.push(...scaleKeys.map(n => n + 12));
    // If there is a C anywhere in the scale add the final C
    if (scaleKeys.indexOf(0) >= 0) scaleKeys.push(24);
    saveSelectedKeys(scaleKeys.slice(0, 24), key.tonic.name);
  }

  function clearSelections(): void {
    saveSelectedKeys([]);
  }

  function setSelectionMode(mode: string): any {
    analytics.logEvent("keyboard", "mode", mode);
    saveState({ selectionMode: mode });
    if (mode === "0") {
      clearSelections();
    }
  }

  function onKeyboardDown(e: KeyboardEvent): void {
    if (shareLink) return;
    if (e.repeat) {
      return;
    }

    const key = getKeyNumberForKey(e.key);
    if (key >= 0) {
      onKeyPressed(key);
    }
  }
  
  function onKeyboardUp(e: KeyboardEvent): void {
    if (shareLink) return;

    if (e.key === Key.Escape) { //escape
      clearSelections();
    }
    else if (e.key === " ") { //space
      playSelectedNotes();
    }
    else {
      const key = getKeyNumberForKey(e.key);
      if (key >= 0) {
        onKeyReleased(key);
      }
    }
  }

  function playNotes(...keys: number[]): void {
    if (audioLib.isLoaded && persistentState.soundOn) {
      keys.forEach((key, i) => {
        const name = getNoteName(key);
        // Use a delay so the sound isn't so jarring
        setTimeout(() => audioLib.play(name), i * PLAY_NOTES_DELAY);
      });
    }
  }

  function getNoteName(key: number) {
    const octave = Math.floor(4 + (key / 12));
    return getNote(key).name + octave;
  }

  function shareClicked(shareInfo: string): void {
    const parts = shareInfo.split(": ");
    analytics.logEvent("keyboard", "share", parts[0]);
    switch (parts[0]) {
      case "Chord":
        updateShareLink("chord", parts[1]); break;
      case "Notes":
        updateShareLink("notes", persistentState.selectedKeys.map(n => getNote(n).toString()).join("-")); break;
      case "Scale":
        updateShareLink("scale", parts[1]); break;
      default:
        logger.error("Invalid share type:", shareInfo);
    }
  }

  function updateShareLink(shareType: string, params: string): void {
    setShareLink(`${window.location.origin}/keyboard/${shareType}/${encodeURIComponent(unformatAccidentals(params))}`);
  }
}

/**
 * Gets the keyboard key numbers for a chord
 */
function getKeysForChord(chord: Chord): number[] {
  const bassNumber = chord.bass.number;
  return chord.notes.map(c => c.number < bassNumber ? c.number + 12 : c.number);
}

/**
 * Gets the notes for a list of key numbers
 */
function getNotes(keys: number[]): Note[] {
  let selected: Note[] = [];
  keys.forEach(key => {
    const note = getNote(key);
    if (selected.findIndex(n => n.equalsIgnoreOctave(note)) < 0) {
      selected.push(note);
    }
  });
  //console.log(selected);
  return selected;
}
