import React from "react";

function SquareIcon() {
  return (
    <svg className="square-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="130.001"
      height="130.001"
      viewBox="0 0 34.396 34.396"
    >
      <path d="M0 0.006H34.396V34.402H0z"></path>
    </svg>
  );
}

export default SquareIcon;
