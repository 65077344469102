import React, { ReactNode, useEffect, useRef } from "react";
import { featureFlags } from "@local/power-chord-lib/build/src/services/feature-flag-service";
import "./PopupMenuButton.scss";

/**
 * Defines a [name, value, title] tuple for a menu item, only name is required.
 * If the name is "\n" it will create a new row.
 */
export type NameValueTitle = string[];

export type PopupMenuButtonProps = {
  /** Selected value */
  value: string;
  /** List of strings or NameValueTitles */
  menuItems: NameValueTitle[];
  /** Function to call when the selected value changes */
  onChange: (value: string) => any;
  /** Optional title for tooltip */
  title?: string;
  /** Optional width of the button */
  width?: string;
  /** Optional popup items per row */
  itemsPerRow?: number;
  children?: ReactNode | ReactNode[];
};

/**
 * A component with a button that displays a popup menu when clicked.
 * Either displays a HTML select element or a custom popup menu component based on
 * feature flags.
 */
export default function PopupMenuButton(props: PopupMenuButtonProps) {
  if (featureFlags.dropDownMenuButton) return (
    <div className="popup-menu-button">
      <select onChange={e => onChanged(e)} value={props.value} title={props.title}>
        {!props.value && <option key=""></option>}
        {props.menuItems.map(i =>
          <option key={getTupleValue(i)} value={getTupleValue(i)}>
            {getTupleName(i)}
          </option>)}
      </select>
    </div>
  )
  else return PopupMenuButtonImpl(props);

  function onChanged(e: React.ChangeEvent<HTMLSelectElement>): void {
    if (e.target.value) {
      props.onChange(e.target.value);
    }
  }
}

/**
 * Implements a popup button that uses a custom popup element
 */
function PopupMenuButtonImpl(props: PopupMenuButtonProps) {
  const clickListener = useRef<() => any>();

  const [showPopup, setShowPopup] = React.useState(false);

  useEffect(() => {
    return () => {
      if (clickListener.current) hide();
    };
  });

  const buttonStyle = props.width ? { width: props.width } : undefined;

  if (featureFlags.dropDownMenuButton) return (
    <div className="popup-menu-button">
      <select onChange={e => onChanged(e)} value={props.value} title={props.title}>
        {!props.value && <option key=""></option>}
        {props.menuItems.map(i =>
          <option key={getTupleValue(i)} value={getTupleValue(i)}>
            {getTupleName(i)}
          </option>)}
      </select>
    </div>
  )
  else return (
    <div className="popup-menu-button">
      <button onClick={e => buttonClicked(e)} title={props.title} style={buttonStyle}>
        {getButtonText()}
      </button>
      {props.itemsPerRow ? renderMultiLinePopup() : renderPopup()}
    </div>
  );

  function onChanged(e: React.ChangeEvent<HTMLSelectElement>): void {
    if (e.target.value) {
      props.onChange(e.target.value);
    }
  }

  function renderPopup(): React.ReactNode {
    if (showPopup) {
      let counter = 0;
      const buttons: JSX.Element[] = [];
      for (const i of props.menuItems) {
        if (i[0] === "\n") {
          buttons.push(<span key={"n" + counter++} className="newline"></span>);
        }
        else {
          buttons.push(createButton(i));
        }
      }
      return createPopupElement(buttons);
    }

    return undefined;
  }

  function renderMultiLinePopup(): JSX.Element | undefined {
    if (showPopup) {
      let rows: JSX.Element[] = [];

      const itemCnt = props.menuItems.length;
      const itemsPerRow = props.itemsPerRow || Number.MAX_SAFE_INTEGER;
      if (itemCnt > itemsPerRow) {
        const rowCount = Math.ceil(itemCnt / itemsPerRow);
        for (let r = 0; r < rowCount; r++) {
          const buttons: JSX.Element[] = [];
          for (let c = 0; c < itemsPerRow; c++) {
            const tuple = props.menuItems[r * itemsPerRow + c];
            // If there are extra spaces left over in the row then add spans to fill it up
            buttons.push(tuple ? createButton(tuple) : <span key={"span" + c}></span>);
          }
          rows.push(<div key={"row" + r}>{buttons}</div>)
        }
      }
      else {
        rows = props.menuItems.map(item => createButton(item));
      }

      return createPopupElement(rows);
    }

    return undefined;
  }

  function createPopupElement(rows: JSX.Element[]): JSX.Element {
    const popupStyle = props.width ? { left: props.width } : undefined;
    return <div className="popup" onClick={e => menuItemClicked(e)} style={popupStyle}>
      {rows}
    </div>;
  }

  function getButtonText(): string {
    const selected = props.menuItems.find(tuple => getTupleValue(tuple) === props.value);
    return selected ? selected[0] : "";
  }

  function createButton(tuple: NameValueTitle): JSX.Element {
    const itemName = getTupleName(tuple);
    const itemValue = getTupleValue(tuple);
    const itemTitle = getTupleTitle(tuple);
    return <button key={itemValue} value={itemValue} title={itemTitle}>{itemName}</button>
  }

  function menuItemClicked(ev: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    const item = (ev.target as HTMLElement);
    if (item.tagName.toUpperCase() === "BUTTON") {
      props.onChange((item as HTMLButtonElement).value);
    }
    else {
      // Clicked outside a button but in the menu area
      // In this case we don't want to close the menu
      ev.stopPropagation();
    }
  }

  function buttonClicked(ev: React.MouseEvent<HTMLButtonElement>): void {
    if (!clickListener) {
      // Wait for the event to be handled by the window to cancel any other popups
      setTimeout(() => show(), 0);
    }
  }

  function show(): void {
    setShowPopup(true);
    window.addEventListener("click", clickListener.current = () => hide());
  }

  function hide(): void {
    window.removeEventListener("click", clickListener.current!);
    clickListener.current = undefined;
    setShowPopup(false);
  }
}

function getTupleName(tuple: NameValueTitle): string {
  return tuple[0];
}
function getTupleValue(tuple: NameValueTitle): string {
  return tuple[1] || tuple[0];
}
function getTupleTitle(tuple: NameValueTitle): string {
  return tuple[2] || tuple[0];
}
