import { Sequencer } from '@local/power-chord-lib/build/src/audio/sequencer';
import { RhythmController } from "@local/power-chord-lib/build/src/rhythm/rhythm-controller";
import { RhythmLib } from '@local/power-chord-lib/build/src/rhythm/rhythm-lib';
import FileService from '@local/power-chord-lib/build/src/services/file-service';
import { RhythmSequence } from "@local/power-chord-lib/build/src/state-types";
import { normalize } from '@local/power-chord-lib/build/src/utils';
import iocContainer from 'ez-ioc';
import { useEffect, useState } from "react";
import { Key } from 'ts-key-enum';
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAudioLib } from "../../hooks/useAudioLib";
import { useConst } from "../../hooks/useConst";
import { useEventListener } from "../../hooks/useEventListener";
import { useLogger } from "../../hooks/useLogger";
import { useStateService } from "../../hooks/useStateService";
import { TYPES } from '../../lib/init-dependencies';
import FilePicker, { FilePickerOperation } from '../common/FilePicker';
import RenderWhen from "../common/RenderWhen";
import RhythmControls from './RhythmControls';
import RhythmToolbar from "./RhythmToolbar";
import "./RhythmView.scss";
import SequenceGrid from './SequenceGrid';

export const ZOOM_AMT = .1;

export default function RhythmView() {
  const analytics = useAnalytics();
  const logger = useLogger("RhythmView");
  const stateService = useStateService();
  const audioLib = useAudioLib("percussion");
  const fileService = useConst(() => iocContainer.resolve<FileService>(TYPES.RhythmFileSvc));
  const sequencer = useConst(() => iocContainer.resolve<Sequencer>(TYPES.RhythmSequencer));
  const controller = useConst(() => new RhythmController(stateService.getState().rhythm.sequence))

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioPercent, setAudioPercent] = useState(0);
  const [currentBeat, setCurrentBeat] = useState(-1);
  const [zoomFactor, setZoomFactor] = useState(1);
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [filePickerOperation, setFilePickerOperation] = useState<FilePickerOperation>("open");
  const [repeatOn, setRepeatOn] = useState(stateService.getState().rhythm.repeatOn);
  const [sequence, setSequence] = useState(controller.sequence);

  useEffect(() => {
    sequencer.onTick = (i) => setCurrentBeat(i);
    sequencer.onStop = () => {
      setCurrentBeat(-1);
      setIsPlaying(false);
    }

    // Unmounting should stop playback
    return stopPlayback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener("keyup", e => onKeyUp(e as KeyboardEvent));
  // Stop browser from handling events
  useEventListener("keydown", e => onKeyDown(e as KeyboardEvent));

  function onKeyUp(e: KeyboardEvent): void {
    if (showFilePicker) return;
    
    switch (e.key) {
      case " ":
        isPlaying ? stopPlayback() : startPlayback();
        break;
      case Key.Escape:
        clear();
        break;
      case "r":
        if (e.ctrlKey) reset();
        break;
      case "s":
        if (e.ctrlKey) saveFile();
          break;
      case "o":
        if (e.ctrlKey) openFile();
          break;
      case "z":
        if (e.ctrlKey) undoState();
        break;
      case "y":
        if (e.ctrlKey) redoState();
        break;
      case "+":
        changeZoom(ZOOM_AMT);
        break;
      case "-":
        changeZoom(-ZOOM_AMT);
        break;
      case "1":
        changeZoom(0);
        break;
      default:
        return;
    }

    e.preventDefault();
    e.stopPropagation();
  }

  function onKeyDown(e: KeyboardEvent): void {
    if (e.ctrlKey) {
      switch (e.key) {
        case "r":
        case "o":
        case "s":
        case "z":
        case "y":
          e.preventDefault();
      }
    }
  }

  /**
   * Applies changes to state.rhythm and saves to persistent storage
   * @param changes RhythmState changes
   */
  function saveState(isRepeatOn = repeatOn): void {
    stateService.setRhythmState({
      sequence: controller.sequence,
      repeatOn: isRepeatOn
    });

    setSequence(controller.sequence);
  }

  return (
    <div className="rhythm-view">
      <h2>Rhythm Sequencer</h2>
      <RhythmControls
        barCount={sequence.barCount}
        onBarCountChanged={onBarCountChanged}
        beatsPerBar={sequence.beatsPerBar}
        onBeatPerBarChanged={onBeatsPerBarChanged}
        unitsPerBeat={sequence.unitsPerBeat}
        onUnitsPerBeatChanged={onUnitsPerBeatChanged}
        beatsPerMinute={sequence.beatsPerMinute}
        onBPMChanged={onBpmChanged}
        isLoading={isLoading}
        audioPercent={audioPercent}
        isPlaying={isPlaying}
        isRepeatOn={repeatOn}
        onRepeatChanged={onRepeatChanged}
        onPreset={onPresetSelected}
        onStartPlayback={startPlayback}
        onStopPlayback={stopPlayback} />
      <RhythmToolbar
        isPlaying={isPlaying}
        onZoom={changeZoom}
        onClear={clear}
        onReset={reset}
        onSaveFile={saveFile}
        onOpenFile={openFile}
        onUndo={undoState}
        onRedo={redoState}
        canUndo={controller.canUndo}
        canRedo={controller.canRedo} />
      <SequenceGrid
        zoomFactor={zoomFactor}
        rows={sequence.rows}
        barCount={sequence.barCount}
        unitsPerBeat={sequence.unitsPerBeat}
        beatsPerBar={sequence.beatsPerBar}
        onRowAdded={addRow}
        onRowDeleted={deleteRow}
        currentBeat={currentBeat}
        onInstrumentChanged={onInstrumentChanged}
        onBeatChanged={onBeatChanged} />
      <RenderWhen condition={showFilePicker}>
        <FilePicker operation={filePickerOperation}
          fileService={fileService}
          fileName={filePickerOperation === "save" ? controller.sequence.name : ""}
          description={filePickerOperation === "save" ? controller.sequence.description : ""}
          onClose={onFileChosen} />
      </RenderWhen>
    </div>
  );

  function changeZoom(amount: number): any {
    analytics.logEvent("rhythm", "grid-zoom", () => amount === 0 ? "reset" : amount > 0 ? "in" : "out");
    setZoomFactor(amount === 0 ? 1 : normalize(zoomFactor + amount, .1, 2));
  }

  function addRow(instrument = ""): void {
    analytics.logEvent("rhythm", "grid-add", instrument);
    controller.addRow(instrument);
    saveState();
  }

  function deleteRow(row: number): void {
    analytics.logEvent("rhythm", "grid-remove");
    controller.deleteRow(row);
    saveState();

    // This will update the sequencer
    updateSequencer();
  }

  function onInstrumentChanged(row: number, instrument: string): any {
    analytics.logEvent("rhythm", "grid-change");
    controller.changeInstrument(row, instrument);
    saveState();

    // This will update the sequencer
    updateSequencer();
  }

  function onBeatChanged(row: number, beat: number, isOn: boolean): void {
    controller.setBeat(row, beat, isOn);
    saveState();
    const instr = controller.sequence.rows[row];

    if (isOn) {
      if (!audioLib.isLoaded) {
        loadAudio();
      }
      else if (instr.name && !isPlaying) {
        setTimeout(() => audioLib.play(instr.name), 0);
      }
    }

    // This will update the sequencer
    updateSequencer();
  }

  function onRepeatChanged(isOn: boolean): void {
    setRepeatOn(isOn);
    saveState(isOn);
    sequencer.isRepeatOn = isOn;
  }

  function onBpmChanged(bpm: number): void {
    controller.setBeatsPerMinute(bpm);
    saveState();
    // This will update the sequencer
    updateSequencer();
  }

  function reset(): void {
    if (isPlaying) return;

    analytics.logEvent("rhythm", "grid-new");
    controller.reset();
    saveState();
  }

  function clear(): void {
    if (isPlaying) return;

    analytics.logEvent("rhythm", "grid-clear");
    controller.clear();
    saveState();
  }

  function onPresetSelected(name: string): any {
    const newSequence = RhythmLib.find(r => r.name === name) as RhythmSequence;
    if (newSequence) {
      stopPlayback();
      analytics.logEvent("rhythm", "preset", name);
      logger.debug(newSequence);
      controller.setSequence(newSequence);
      saveState();
    }
    else {
      logger.error("Invalid preset", name);
    }
  }

  function onBarCountChanged(count: number): void {
    controller.setBarCount(count);
    saveState();
    // This will update the sequencer
    updateSequencer();
  }

  function onBeatsPerBarChanged(bpb: number): any {
    controller.setBeatsPerBar(bpb);
    saveState();
    // This will update the sequencer
    updateSequencer();
  }

  function onUnitsPerBeatChanged(upb: number): any {
    controller.setUnitsPerBeat(upb);
    saveState();
    // This will update the sequencer
    updateSequencer();
  }

  function startPlayback(): void {
    if (sequence.barCount > 0 && !isLoading && !isPlaying) {
      analytics.logEvent("rhythm", "play");
      sequencer.isRepeatOn = repeatOn;
      setIsPlaying(true);

      if (audioLib.isLoaded) {
        setTimeout(playSequence, 0);
      }
      else {
        loadAudio().then(() => {
          setTimeout(playSequence, 0);
        });
      }
    }
  }

  function stopPlayback(): void {
    logger.debug("Stopping playback");
    sequencer.stop();
  }

  function playSequence(): void {
    logger.debug("Starting playback");
    sequencer.start(sequence.beatsPerMinute * sequence.unitsPerBeat, sequence.rows);
  }

  function updateSequencer() {
    if (sequencer.isPlaying) {
      logger.debug("Resuming playback");
      // Use the controller because state may not be settled yet
      const seq = controller.sequence;
      sequencer.resume(seq.beatsPerMinute * seq.unitsPerBeat, seq.rows);
    }
  }

  function loadAudio(): Promise<void> {
    if (isLoading) return Promise.reject();

    setIsLoading(true);

    return audioLib.load(pct => setAudioPercent(pct))
      .catch(err => {
        logger.error(err);
        alert("Error loading audio: " + err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onFileChosen(name: string, descr: string): any {
    setShowFilePicker(false);
    if (name) {
      if (filePickerOperation === "save") {
        saveSequence(name, descr);
      }
      else {
        openSequence(name);
      }
    }
  }

  function openFile(): void {
    analytics.logEvent("rhythm", "grid-open");

    if (isPlaying) {
      stopPlayback();
    }

    setShowFilePicker(true);
    setFilePickerOperation("open");
  }

  function saveFile(): void {
    analytics.logEvent("rhythm", "grid-save");
    setShowFilePicker(true);
    setFilePickerOperation("save");
  }

  function saveSequence(name: string, descr: string): any {
    const seq = controller.sequence;
    seq.name = name;
    seq.description = descr;
    logger.debug("save", controller.sequence);
    fileService.set(name, controller.sequence)
      .catch(e => {
        logger.error(e);
        alert("Error saving file: " + e.message);
      });
  }

  function openSequence(name: string): any {
    fileService.get<RhythmSequence>(name)
      .then(seq => {
        logger.debug("open", seq);
        controller.setSequence(seq);
        saveState();
      })
      .catch(e => {
        logger.error(e);
        alert("File not found: " + name);
      });
  }

  function redoState(): void {
    const state = controller.redo();
    //logger.debug("redo", state);
    if (state) {
      saveState();
      // This will update the sequencer
      updateSequencer();
    }
  }

  function undoState(): void {
    const state = controller.undo();
    //logger.debug("undo", state);
    if (state) {
      saveState();
      // This will update the sequencer
      updateSequencer();
    }
  }
}
