import { ChordProgressionItem } from '@local/power-chord-lib/build/src/state-types';
import { formatAccidentals } from '@power-chord/music-theory';

type ChordListItemProps = {
  index: number;
  item: ChordProgressionItem;
  isHilited: boolean;
  onDeleteClicked: (idx: number) => any;
  onAddBeat: (idx: number, count: number) => any;
}

export default function ChordListItem(props: ChordListItemProps) {
  const beats = getBeats(props.item.beats);
  const chord = props.item.chord;
  const susIndex = chord.indexOf("sus");
  const displayName = getDisplayName(formatAccidentals(chord), susIndex);
  const superscript = (susIndex > 0 ? chord.slice(susIndex) : "");

  return (
    <li title={props.item.chord} className={props.isHilited ? "selected" : ""}>
      <span>
        {displayName}
        {superscript && <sup>{superscript}</sup>}
      </span>
      <div className="beats" onClick={e => beatClicked(e)}>
        {beats.map((b, i) => <span key={i} title={(b === "+" ? "Add" : "Remove") + " Beat"}>{b}</span>)}
      </div>
      <button className="delete" title="Delete" onClick={() => props.onDeleteClicked(props.index)}>&#10006;</button>
    </li>
  );

  function beatClicked(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    const target = e.target as HTMLElement;
    if (target.tagName === "SPAN") {
      e.preventDefault();
      e.stopPropagation();
      if (target.innerText === "+") {
        props.onAddBeat(props.index, 1);
      }
      else if (target.innerText === "-") {
        props.onAddBeat(props.index, -1);
      }
    }
  }

  function getBeats(beatCount: number): string[] {
    const a = new Array<string>(beatCount).fill("-");
    a[beatCount - 1] = "+";
    return a;
  }

  function getDisplayName(chord: string, susIndex: number): string {
    if (susIndex > 0) {
      return chord.slice(0, susIndex);
    }
    else if (chord.endsWith("aug")) {
      return chord.slice(0, -3) + "+";
    }
    else if (chord.indexOf("dim") > 0) {
      return chord.replace("dim", "°");
    }

    return chord;
  }
}
