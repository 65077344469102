import React from "react";

function NewGridIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 49.356 49.356"
      version="1.1"
      viewBox="0 0 49.356 49.356"
      xmlSpace="preserve"
    >
      <path
        d="M7.97 49.356h33.417c1.103 0 2-.897 2-2V11.701c0-.843-.46-1.857-1.093-2.411L32.735.907C32.135.382 31.121 0 30.324 0H7.97c-1.103 0-2 .897-2 2v45.355c0 1.104.896 2.001 2 2.001zM32.304 4.519l5.881 5.159h-5.882l.001-5.159zM8.97 3h20.333v7.678c0 1.103.897 2 2 2h9.084v33.678H8.97V3z"
      ></path>
    </svg>
  );
}

export default NewGridIcon;