import { useRef } from 'react';
import { useLogger } from "../../hooks/useLogger";
import Modal from './Modal';
import "./ShareLinkModal.scss";

type ShareLinkModalProps = {
  shareLink: string;
  onClose: () => void;
};

export function ShareLinkModal({ onClose, shareLink }: ShareLinkModalProps) {
  const logger = useLogger("ShareLinkModal");
  const shareLinkRef = useRef<HTMLInputElement>(null);

  return (
    <Modal onClose={onClose}>
      <div className="share-link">
        <label>Shareable Link</label>
        <div>
          <input type="text" ref={shareLinkRef} defaultValue={shareLink} contentEditable={false} />
          <button onClick={copyShare} title="Copy to clipboard" autoFocus>Copy</button>
        </div>
      </div>
    </Modal>
  );

  function copyShare(): void {
    const el = shareLinkRef.current;
    if (el) {
      try {
        // First try to use Clipboard API
        if (navigator.clipboard && navigator.permissions) {
          logger.debug("Copying using Clipboard API");
          navigator.permissions.query({ name: "clipboard-write" as any }).then(result => {
            if (result.state === "granted" || result.state === "prompt") {
              navigator.clipboard.writeText(shareLink!);
            }
          });
        }
        else {
          // Fallback, try to use execCommand instead
          logger.debug("Copying using legacy API");
          el.focus();
          el.setSelectionRange(0, shareLinkRef.current!.value.length);
          document.execCommand("copy");
        }
        el.parentElement!.querySelector("button")!.textContent = "Copied";
        
        onClose();
      }
      catch (err) {
        logger.error("Error copying to clipboard", (err as Error).message);
      }
    }
  }
}
