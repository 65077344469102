import { GuitarController } from "@local/power-chord-lib/build/src/guitar/guitar-controller";
import { DEFAULT_OPEN_NOTES, getDefaultTuningUkuleleLookup } from '@power-chord/music-theory/guitar/ukulele-default-tuning';
import { useMemo } from "react";
import { useStateService } from '../../hooks/useStateService';
import GuitarView from "./GuitarView";

/**
 * Wrapper of a GuitarView for a ukulele with standard tuning
 */
export default function UkuleleView() {
  const stateService = useStateService();
  const controller = useMemo<GuitarController>(() => {
    const state = stateService.getState().ukulele;
    return new GuitarController(DEFAULT_OPEN_NOTES, getDefaultTuningUkuleleLookup(), 16, state.selectedPositions);
  }, [stateService]);

  return (
    <GuitarView guitarType="ukulele" width="1000" height="125"
      numFrets={16}
      controller={controller}
      getState={() => stateService.getState().ukulele}
      saveState={state => stateService.setUkuleleState(state)} />
  );
}