import { NoteName } from "@power-chord/music-theory";
import React, { useEffect, useRef } from "react";
import KeyboardRenderer from "./keyboard-renderer";

type KeyboardProps = {
  octaves: number;
  width: string | number;
  height: string | number;
  hintKeys: number[];
  selectedKeys: number[];
  rootNote: NoteName | "";
  onKeyPressed: (key: number) => void;
  onKeyReleased: (key: number) => void;
  onKeyHover: (key: number) => void;
};

export default function Keyboard(props: KeyboardProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const keyboardRenderer = useRef<KeyboardRenderer>();
  // Used for pointer events
  const currentKey = useRef(-1);
  const isPointerDown = useRef(false);
  const currentHover = useRef(-1);

  useEffect(() => {
    if (canvasRef.current) {
      keyboardRenderer.current = new KeyboardRenderer(canvasRef.current, props.octaves);
    }
  }, [props.octaves]);

  useEffect(() => {
    if (keyboardRenderer.current) {
      keyboardRenderer.current.setHintKeys(...props.hintKeys);
    }
  }, [props.hintKeys])

  useEffect(() => {
    if (keyboardRenderer.current) {
      keyboardRenderer.current.setSelectedKeys(...props.selectedKeys);
    }
  }, [props.selectedKeys])

  useEffect(() => {
    if (keyboardRenderer.current) {
      keyboardRenderer.current.setRootNote(props.rootNote);
    }
  }, [props.rootNote])

  return (
    <div className="keyboard">
      <canvas ref={canvasRef}
        width={props.width}
        height={props.height}
        onPointerDown={e => pointerDown(e)}
        onPointerUp={e => pointerUp(e)}
        onPointerMove={e => pointerMove(e)}
        onPointerLeave={e => pointerLeave(e)}>
      </canvas>
      <div className="backboard"></div>
    </div>
  );

  function pointerDown(ev: React.PointerEvent<HTMLCanvasElement>): void {
    ev.preventDefault();
    ev.stopPropagation();

    isPointerDown.current = true;
    const key = getKeyUnderPointer(ev);
    if (key >= 0) {
      props.onKeyPressed(key);
      if (currentKey.current < 0) {
        currentKey.current = key;
      }
    }
  }

  function pointerUp(ev: React.PointerEvent<HTMLCanvasElement>): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (isPointerDown.current) {
      isPointerDown.current = false;
      currentKey.current = -1;
      const key = getKeyUnderPointer(ev);
      if (key >= 0) {
        props.onKeyReleased(key);
      }
    }
  }

  function pointerMove(ev: React.PointerEvent<HTMLCanvasElement>): void {
    ev.preventDefault();
    ev.stopPropagation();

    const selectedKeys = keyboardRenderer.current!.getSelectedKeys();
    const key = getKeyUnderPointer(ev);
    if (key >= 0 && selectedKeys.indexOf(key) < 0) {
      // Moved to another key
      if (isPointerDown.current && currentKey.current !== key) {
        props.onKeyReleased(currentKey.current);
        props.onKeyPressed(key);
        currentKey.current = key;
      }
    }

    if (key >= 0) {
      if (key !== currentHover.current) {
        props.onKeyHover(key);
        currentHover.current = key;
      }
    }
  }

  function pointerLeave(ev: React.PointerEvent<HTMLCanvasElement>): void {
    ev.preventDefault();
    ev.stopPropagation();

    if (currentHover.current !== -1) {
      keyboardRenderer.current!.clearHintKeys();
      props.onKeyHover(-1);
      currentHover.current = -1;
    }

    if (isPointerDown) {
      if (currentKey.current >= 0) {
        props.onKeyReleased(currentKey.current);
      }
      isPointerDown.current = false;
      currentKey.current = -1;
    }
  }

  function getKeyUnderPointer(ev: React.PointerEvent<HTMLCanvasElement>): number {
    const bounds = canvasRef.current!.getBoundingClientRect();
    const key = keyboardRenderer.current!.getKeyAt(ev.clientX - bounds.left, ev.clientY - bounds.top);
    return key;
  }
}