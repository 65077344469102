export default function getCircleOfFifthsColors() { 
    return {
        shadow: "cyan",
        background: "rgba(0, 0, 0, .2)",
        outline: "#004",
        degrees: "silver",
        selection: "#004",
        notes: {
            root: "#C80",
            major: "#080",
            minor: "#0AA",
            diminished: "#077",
            other: "gray"
        }
    };
}