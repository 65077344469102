import React from "react";

function ZoomOutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 135.47 133.3"
    >
      <path
        d="M386.35 336.16c23.574-34.148 37.408-75.546 37.408-120.19 0-117.02-94.843-211.88-211.88-211.88S-.002 98.952-.002 215.97s94.843 211.88 211.88 211.88c48.849 0 93.8-16.572 129.64-44.338l124.39 124.39 46.097-46.09zm-174.47 46.052c-91.667 0-166.24-74.574-166.24-166.24 0-91.667 74.574-166.24 166.24-166.24s166.24 74.574 166.24 166.24-74.576 166.24-166.24 166.24z"
        transform="translate(-3.326 -5.077) translate(3.326 3.993) scale(.26458)"
      ></path>
      <path
        d="M189.06 242.05h127.13v-45.635H107.57v45.635z"
        transform="translate(-3.326 -5.077) matrix(.26458 0 0 .26586 4.796 2.242)"
      ></path>
    </svg>
  );
}

export default ZoomOutIcon;