import { VocalRange } from '@local/power-chord-lib/build/src/state-types';
import { TuneCaptureProcessor } from "@local/power-chord-lib/build/src/vocals/tune-capture-processor";
import { getNote, Note } from '@power-chord/music-theory';
import { AudioTuner, TunerEvent } from 'audio-analyzer';
import React, { useEffect, useMemo } from 'react';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useConst } from '../../hooks/useConst';
import { useLogger } from '../../hooks/useLogger';
import SquareIcon from '../common/icons/SquareIcon';
import TriangleIcon from '../common/icons/TriangleIcon';
import RenderWhen from '../common/RenderWhen';
import SplitContainer from '../common/SplitContainer';
import NoteOctaveSelector from './NoteOctaveSelector';
import TuneCaptureCanvas from './TuneCaptureCanvas';

const POLL_TIME = 100;
const DEFAULT_MIN = getNote("C", 2);
const DEFAULT_MAX = getNote("C", 5);
// Initial max time on the capture control
const INIT_TIME = 10000;
// Max amount of time that data can be captured
const MAX_TIME = 1000 * 60;

export type TuneCaptureProps = {
  tuner: AudioTuner;
  range: VocalRange;
  hasDefaultRange: boolean;
  onRangeChanged: (r: VocalRange) => any;
  onResetRange: () => any;
};

export default function TuneCaptureControl(props: TuneCaptureProps) {
  const analytics = useAnalytics();
  const logger = useLogger("TuneCaptureControl");
  const processor = useConst(() => new TuneCaptureProcessor());

  const [isRunning, setIsRunning] = React.useState(false);
  const [curTime, setCurTime] = React.useState("0.00");
  const [curNote, setCurNote] = React.useState<Note>();
  const [maxTime, setMaxTime] = React.useState(INIT_TIME);

  const minNote = useMemo(() => (props.range.low || DEFAULT_MIN), [props.range.low]);
  const maxNote = useMemo(() => (props.range.high || DEFAULT_MAX), [props.range.high]);

  useEffect(() => {
    analytics.logEvent("vocals", "tune-capture");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRunning) {
      analytics.logEvent("vocals", "start-capture");
      logger.debug("Starting tuner");
      const tunerListener = (e: TunerEvent) => onTunerNoteChanged(e);
      props.tuner.addTunerListener(tunerListener);
      props.tuner.start(POLL_TIME);
      processor.reset();

      return () => {
        logger.debug("Stopping tuner");
        props.tuner.stop();
        props.tuner.removeTunerListener(tunerListener);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, isRunning, logger, processor, props.tuner]);

  // When curTime changes check if we need to extend the graph
  useEffect(() => {
    if (processor.totalTime > maxTime) {
      if (processor.totalTime < MAX_TIME) {
        // Extend the time
        setMaxTime(maxTime + INIT_TIME);
      }
      else {
        // Can't extend any more
        setIsRunning(false);
        setCurNote(undefined);
      }
    }
  }, [maxTime, curTime, processor.totalTime]);

  return (
    <div className="tune-capture">
      <SplitContainer direction="horizontal" bottomWeight={1}>
        <div className="actions">
          <RenderWhen condition={isRunning}>
            <button onClick={() => toggleRunning()} title="Stop (Space)"><SquareIcon /> Stop</button>
          </RenderWhen>
          <RenderWhen condition={!isRunning}>
            <button onClick={() => toggleRunning()} title="Start (Space)"><TriangleIcon /> Start</button>
          </RenderWhen>
          <label>Time: <span>{curTime}</span></label>
          &nbsp;|
          <NoteOctaveSelector label="Min" note={minNote} onChange={n => onRangeChanged(n, true)} />
          <NoteOctaveSelector label="Max" note={maxNote} onChange={n => onRangeChanged(n, false)} />
          <RenderWhen condition={props.hasDefaultRange}>
            <button className="unbuttoned reset-range" onClick={() => props.onResetRange()} title="Reset to your vocal range">[reset]</button>
          </RenderWhen>
        </div>
        <TuneCaptureCanvas processor={processor} range={props.range} curNote={curNote} maxTime={maxTime} curTime={curTime} />
      </SplitContainer>
    </div>
  );

  function onRangeChanged(note: Note, isMin: boolean): void {
    const range = Object.assign({}, props.range);
    if (isMin) {
      if (note.midiNumber < maxNote.midiNumber) {
        range.low = note;
      }
    }
    else if (note.midiNumber > minNote.midiNumber) {
      range.high = note;
    }

    props.onRangeChanged(range);
    analytics.logEvent("vocals", "capture-range", "change");
  }

  /**
   * This gets called on every poll interval
   */
  function onTunerNoteChanged(e: TunerEvent): void {
    let note: Note | undefined;
    if (e.note) {
      note = getNote(e.noteNumber % 12, e.octave);
      setCurNote(note);
    }
    else {
      setCurNote(undefined);
    }

    processor.addEvent(Date.now(), note, e.cents);
    setCurTime((processor.totalTime / 1000).toFixed(2));
  }

  function toggleRunning(): void {
    if (isRunning) {
      setIsRunning(false);
      setCurNote(undefined);
    }
    else {
      // Make sure audio context is running
      props.tuner.listener.analyzer.context.resume().then(() => {
        setIsRunning(true);
        setMaxTime(INIT_TIME);
      });
    }
  }
}
