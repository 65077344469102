import iocContainer from "ez-ioc";
import { LocalStorageFileService } from "@local/power-chord-lib/build/src/services/file-service";
import { LocalStorageStateService } from "@local/power-chord-lib/build/src/services/state-service";
import * as analog from "analogging";
import { GoogleAnalytics } from "@local/power-chord-lib/build/src/services/analytics-service";
import { AudioAnalyzer, getAudioTunerSync } from "audio-analyzer";
import { GuitarInstrument } from "@local/power-chord-lib/build/src/audio/tone/instruments/guitar-instrument";
import { PianoInstrument } from "@local/power-chord-lib/build/src/audio/tone/instruments/piano-instrument";
import { PercussionInstrument } from "@local/power-chord-lib/build/src/audio/tone/instruments/percussion-instrument";
import { ToneSequencer } from "@local/power-chord-lib/build/src/audio/tone/tone-sequencer";
import { Globals } from "../globals";
import { isLocalHost } from "@local/power-chord-lib/build/src/browser-utils";

const RHYTHM_STORAGE_KEY = "powerchord.rhythms";
const PROG_STORAGE_KEY = "powerchord.progressions";

export const TYPES = {
    StateService: Symbol.for("StateService"),
    AnalyticsService: Symbol.for("AnalyticsService"),
    PianoAudioLib: Symbol.for("PianoAudioLib"),
    GuitarAudioLib: Symbol.for("GuitarAudioLib"),
    PercussionAudioLib: Symbol.for("PercussionAudioLib"),
    RhythmFileSvc: Symbol.for("RhythmFileSvc"),
    ProgressionFileSvc: Symbol.for("ProgressionFileSvc"),
    PianoSequencer: Symbol.for("PianoSequencer"),
    RhythmSequencer: Symbol.for("RhythmSequencer"),
    AudioAnalyzer: Symbol.for("AudioAnalyzer"),
    AudioTuner: Symbol.for("AudioTuner"),
    
};

export function initDependencies(): void {
    analog.getLogger("initDependencies").debug("Initializing dependencies");

    iocContainer.bind(TYPES.StateService, new LocalStorageStateService(Globals.version))
        .bind(TYPES.AnalyticsService, new GoogleAnalytics(!isLocalHost))
        .bindFactoryLazy(TYPES.PianoAudioLib, () => new PianoInstrument(Globals.audio.path, Globals.audio.extensions))
        .bindFactoryLazy(TYPES.GuitarAudioLib, () => new GuitarInstrument(Globals.audio.path, Globals.audio.extensions))
        .bindFactoryLazy(TYPES.PercussionAudioLib, () => new PercussionInstrument(Globals.audio.path, Globals.audio.extensions))
        .bindLazy(TYPES.PianoSequencer, ToneSequencer, [TYPES.PianoAudioLib])
        .bindLazy(TYPES.RhythmSequencer, ToneSequencer, [TYPES.PercussionAudioLib])
        .bindFactoryLazy(TYPES.AudioAnalyzer, () => new AudioAnalyzer({ fftSize: 2048 }))
        .bindFactoryLazy(TYPES.AudioTuner, getAudioTunerSync, [TYPES.AudioAnalyzer])
        .bindFactoryLazy(TYPES.RhythmFileSvc, () => new LocalStorageFileService(RHYTHM_STORAGE_KEY))
        .bindFactoryLazy(TYPES.ProgressionFileSvc, () => new LocalStorageFileService(PROG_STORAGE_KEY));
}