import React from "react";

function KeyboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.653 7.971"
    >
      <path
        stroke="#000000"
        strokeWidth="0.465"
        d="M20.385 15.151h7.188v7.506h-7.188z"
        transform="translate(-20.152 -14.918)"
      ></path>
      <path
        fill="#000"
        stroke="#000"
        strokeWidth="0.263"
        d="M20.564 22.046h6.78"
        transform="translate(-20.152 -14.918)"
      ></path>
      <g stroke="#000000">
        <g strokeWidth="0.266">
          <path
            d="M22.289 22.424v-3.213M25.726 22.436v-3.213M23.978 22.436v-3.213"
            transform="translate(-20.152 -14.918)"
          ></path>
        </g>
        <g strokeWidth="0.262">
          <path
            fill="#000"
            d="M21.85 15.074h.817c.052 0 .094.12.094.267v3.473c0 .148-.042.267-.094.267h-.817c-.052 0-.094-.12-.094-.267V15.34c0-.148.042-.267.094-.267zM23.581 15.081h.817c.052 0 .094.12.094.267v3.473c0 .148-.042.267-.094.267h-.817c-.052 0-.094-.12-.094-.267v-3.473c0-.148.042-.267.094-.267zM25.318 15.093h.817c.052 0 .094.12.094.267v3.473c0 .148-.042.267-.094.267h-.817c-.052 0-.094-.12-.094-.267V15.36c0-.148.042-.267.094-.267z"
            transform="translate(-20.152 -14.918)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default KeyboardIcon;