import * as React from 'react';
import SquareIcon from '../common/icons/SquareIcon';
import TriangleIcon from '../common/icons/TriangleIcon';
import RenderWhen from '../common/RenderWhen';
import { normalize } from '@local/power-chord-lib/build/src/utils';
import KeySelector from '../common/KeySelector';
import { ModeName, MusicScale, NoteName } from '@power-chord/music-theory';

const MAX_BPM = 240;

export type ProgressionControlsProps = {
  isPlaying: boolean;
  isLoading: boolean;
  audioPercent: number;
  beatsPerMinute: number;
  tonic: NoteName;
  mode: ModeName;
  onBPMChanged: (bpm: number) => any;
  onKeyChanged: (key: MusicScale) => any;
  onStartPlayback: () => any;
  onStopPlayback: () => any;
};

export default function ProgressionControls(props: ProgressionControlsProps) {
  return (
    <div className="controls">
      <section>
        <label>Key:</label>
        <KeySelector
          extended={false}
          selectedTonic={props.tonic}
          selectedMode={props.mode}
          onChange={props.onKeyChanged} />
      </section>
      <section>
        <RenderWhen condition={props.isPlaying}>
          <button onClick={props.onStopPlayback} title="Stop playback (Space)"><SquareIcon /> Stop</button>
        </RenderWhen>
        <RenderWhen condition={!props.isPlaying}>
          <button onClick={props.onStartPlayback} title="Start playback (Space)"><TriangleIcon /> Play</button>
        </RenderWhen>
        <label title="Beats per minute">
          BPM: <input onChange={e => bpmChanged(e)} type="number" min="1" max={MAX_BPM} step="1" value={props.beatsPerMinute} />
        </label>
        <RenderWhen condition={props.isLoading}>
          <label>Loading: <progress title="Loading audio" value={props.audioPercent}></progress></label>
        </RenderWhen>
      </section>
    </div>
  );

  function bpmChanged(e: React.ChangeEvent<HTMLInputElement>): void {
    const bpm = normalize(parseInt(e.target.value), 1, MAX_BPM);
    if (isFinite(bpm)) {
      props.onBPMChanged(bpm);
    }
  }
}
