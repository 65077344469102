import { AudioAnalyzer, AudioTuner } from 'audio-analyzer';
import iocContainer from 'ez-ioc';
import React, { useEffect } from 'react';
import { useConst } from "../../hooks/useConst";
import { TYPES } from '../../lib/init-dependencies';
import RenderWhen from "../common/RenderWhen";
import SplitContainer from "../common/SplitContainer";
import TabContainer, { TabListItem } from "../common/TabContainer";
import AudioVisualizer from './AudioVisualizer';
// import ScalesControl from './ScalesControl';
import { VocalRange, getDefaultVocalRange } from "@local/power-chord-lib/build/src/state-types";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useLogger } from "../../hooks/useLogger";
import { useStateService } from '../../hooks/useStateService';
import TuneCaptureControl from './TuneCaptureControl';
import VocalRangeControl from './VocalRangeControl';
import "./VocalsView.scss";

// This the same as VocalState except all fields are optional
type StateChanges = {
  range?: VocalRange;
  rangeExpanded?: boolean;
  tab?: string;
  captureRange?: VocalRange;
};

const TABS: TabListItem[] = [["Waveform", "wave"], ["Tune Capture", "capture"]];

// A global flag to keep multiple components from connecting
let connectingToAudio = false;

export default function VocalsView() {
  const analytics = useAnalytics();
  const logger = useLogger("VocalsView");
  const stateService = useStateService();
  const analyzer = useConst(() => iocContainer.resolve<AudioAnalyzer>(TYPES.AudioAnalyzer));
  const tuner = useConst(() => iocContainer.resolve<AudioTuner>(TYPES.AudioTuner));

  const [persistentState, setPersistentState] = React.useState(stateService.getState().vocals);
  const [audioConnected, setAudioConnected] = React.useState(analyzer.isConnected);
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    if (!connectingToAudio) {
      if (!analyzer.isConnected) {
        logger.debug("Connecting to audio...");
        connectingToAudio = true;
        analyzer.connect().then(() => {
          // throw new Error("Test");
          logger.debug("Audio connected");
          setAudioConnected(true);
          connectingToAudio = false;
        })
          .catch(err => {
            console.error(err);
            logger.error("Error connecting to audio:", err.message);
            setErrorMessage(err.message);
          });
      }
      else {
        logger.debug("Audio already connected");
        setAudioConnected(true);
      }
    }

    const context = analyzer.context;
    // This will suspend the audio context when unmounted
    return () => {
      if (context.state === "running") {
        logger.debug("Stopping audio");
        context.suspend();
      }
    };
  }, [analyzer, logger]);

  /**
   * Applies changes to rhythm and saves to persistent storage
   * @param changes RhythmState changes
   */
  function saveState(changes: StateChanges): void {
    // This is necessary so we have the right values when state doesn't get updated before the next event
    Object.assign(persistentState, changes);
    const newState = Object.assign({}, persistentState, changes);
    setPersistentState(newState);
    stateService.setVocalsState(newState);
  }

  return (
    <div className="vocals-view">
      <RenderWhen condition={!audioConnected}>
        <div className="connecting">
          Connecting to audio...
          <RenderWhen condition={Boolean(errorMessage)}>
            <p className="error">
              Error connecting to audio: {errorMessage}<br />
              Make sure you allow this page to use audio.
            </p>
          </RenderWhen>
        </div>
      </RenderWhen>
      <RenderWhen condition={audioConnected}>
        <SplitContainer direction="horizontal" bottomWeight={1}>
          <VocalRangeControl
            tuner={tuner}
            range={persistentState.range}
            expanded={persistentState.rangeExpanded}
            onRangeChanged={onRangeChanged}
            onExpandChanged={b => saveState({ rangeExpanded: b })}
          />
          <TabContainer tabs={TABS} selectedTab={persistentState.tab} tabClicked={onTabSelected}>
            <RenderWhen condition={persistentState.tab === "wave"}>
              <AudioVisualizer tuner={tuner} />
            </RenderWhen>
            <RenderWhen condition={persistentState.tab === "capture"}>
              <TuneCaptureControl tuner={tuner}
                range={persistentState.captureRange}
                hasDefaultRange={Boolean(persistentState.range.low && persistentState.range.high)}
                onRangeChanged={onCaptureRangeChanged}
                onResetRange={onResetCaptureRange} />
            </RenderWhen>
            {/* <ScalesControl tuner={tuner} minNote={persistentState.range.low} maxNote={persistentState.range.high}/>} */}
          </TabContainer>
        </SplitContainer>
      </RenderWhen>
    </div>
  );

  function onResetCaptureRange(): void {
    if (persistentState.range.low && persistentState.range.high) {
      analytics.logEvent("vocals", "capture-range", "reset");
      saveState({ captureRange: Object.assign({}, persistentState.range) });
    }
  }

  function onCaptureRangeChanged(r: VocalRange): any {
    saveState({ captureRange: r });
  }

  function onTabSelected(tab: string): void {
    if (tab !== persistentState.tab) {
      analytics.logEvent("vocals", "tab", tab);
      saveState({ tab: tab });
    }
  }

  function onRangeChanged(r: VocalRange) {
    analytics.logEvent("vocals", "range-found");
    const captureRange = (r.low && r.high ? r : getDefaultVocalRange());
    saveState({
      range: r,
      captureRange
    });
  }
}
