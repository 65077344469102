import { Chord, MusicScale, Note, parseChord, parseNote, parseScale } from "@power-chord/music-theory";
import * as analog from "analogging";
import { upperCaseFirstLetter } from "../utils";
import AnalyticsService from "../services/analytics-service";

export type KeyboardShareParams = {
    shareType: "chord" | "notes" | "scale";
    shareId: string;
};

export type KeyboardShareInfo = {
    chord?: Chord;
    scale?: MusicScale;
    notes?: Note[];
};

function logAnalyticsEvent(action: string, svc: AnalyticsService): void {
    svc.logEvent("keyboard", action);
}

export function parseKeyboardShareParams(params: KeyboardShareParams, svc: AnalyticsService): KeyboardShareInfo | undefined {
    switch (params.shareType) {
        case "chord": return getChord(params.shareId, svc);
        case "scale": return getScale(params.shareId, svc);
        case "notes": return getNotes(params.shareId, svc);
    }
}

/**
 * Parses chord URL param into a chord.
 * @returns The chord if exists and valid, otherwise undefined
 */
function getChord(value: string, svc: AnalyticsService): KeyboardShareInfo | undefined {
    logAnalyticsEvent("url-chord", svc);
    try {
        const chordName = upperCaseFirstLetter(decodeURIComponent(value));
        const chord = parseChord(chordName);
        if (chord) {
            return { chord };
        }
    }
    catch (err) {
        const logger = analog.getLogger("parseKeyboardShareParams");
        logger.error("Error parsing chord:", err.message);
        throw new Error("Invalid chord in url: " + decodeURIComponent(value));//, { cause: err });
    }

    throw new Error("Couldn't find chord: " + value);
}

/**
 * Gets the scale from the URL param if it exists
 * @returns The scale if exists and valid, otherwise undefined
 */
function getScale(value: string, svc: AnalyticsService): KeyboardShareInfo | undefined {
    logAnalyticsEvent("url-scale", svc);
    try {
        const scaleName = upperCaseFirstLetter(decodeURIComponent(value));
        const scale = parseScale(scaleName);
        if (scale) {
            return { scale };
        }
    }
    catch (err) {
        const logger = analog.getLogger("parseKeyboardShareParams");
        logger.error("Error parsing scale:", err.message);
        throw new Error("Invalid scale in url: " + decodeURIComponent(value));//, { cause: err });
    }

    throw new Error("Couldn't find scale: " + value);
}

/**
 * Gets the notes from the URL param
 * @param value Note names separated by dash, e.g. C-E-G
 * @returns Info with notes member set to an array of notes
 */
function getNotes(value: string, svc: AnalyticsService): KeyboardShareInfo | undefined {
    logAnalyticsEvent("url-notes", svc);
    try {
        const notes = decodeURIComponent(value)
            .split("-")
            .map(n => parseNote(upperCaseFirstLetter(n)))
            .filter(n => n !== undefined);
        return { notes };
    }
    catch (err) {
        const logger = analog.getLogger("parseKeyboardShareParams");
        logger.error("Error parsing notes:", err.message);
        throw new Error("Invalid notes in url: " + decodeURIComponent(value));//, { cause: err });
    }
}
