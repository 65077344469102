import { ChordQuality, getChord, getNote } from "@power-chord/music-theory";
import { KeyboardSelectionMode } from "../state-types";

/**
 * Defines a function that determines what keys should be shown as depressed
 * given a key and the current set of pressed keys
 * @key The key that was pressed or released
 * @isDepressed True if pressed, false if released
 * @currentKeys The current set of depressed keys
 * @returns The new set of depressed keys
 */
export type KeySelectionStrategy = (key: number, isDepressed: boolean, currentKeys: number[], selectionMode?: KeyboardSelectionMode) => number[];

/**
 * Allows for turning keys on when they are depressed and off when released
 */
export function playerKeySelection(key: number, isDepressed: boolean, currentKeys: number[]): number[] {
    const exists = currentKeys.indexOf(key) >= 0;
    if (isDepressed && !exists) {
        // turn the key on
        return currentKeys.concat(key);
    }
    else if (!isDepressed && exists) {
        // turn the key off
        return currentKeys.filter(k => k !== key);
    }

    return currentKeys;
}

/**
 * Allows for the toggling of keys on and off when pressed
 */
export function singleKeySelection(key: number, isDepressed: boolean, currentKeys: number[]): number[] {
    if (isDepressed) {
        const exists = currentKeys.indexOf(key) >= 0;
        if (exists) {
            // turn the key off
            return currentKeys.filter(k => k !== key);
        }
        else {
            // turn the key on
            return currentKeys.concat(key);
        }
    }

    return currentKeys;
}

/**
 * Allows for the toggling of keys on and off when a selection mode is given
 */
export function multipleKeySelection(key: number, isDepressed: boolean, currentKeys: number[], selectionMode?: KeyboardSelectionMode): number[] {
    if (isDepressed) {
        return getKeysToSelect(key, selectionMode as ChordQuality);
    }

    return currentKeys;
}

/**
 * Gets the keys to select based on the root key and selection mode
 * @param rootKey The key under the pointer
 */
export function getKeysToSelect(rootKey: number, quality: ChordQuality): number[] {
    const note = getNote(rootKey);
    const keyNumbers = getChord(note.name, quality).notes.map(n => n.number);

    // Map the keys to use two octaves
    return keyNumbers.map(key => (key < rootKey ? key += 12 : key));
}
