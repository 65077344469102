import * as React from 'react';
import { ReactNode } from 'react';
import PopupMenuButton, { NameValueTitle } from '../common/PopupMenuButton';
import RenderWhen from '../common/RenderWhen';
import { SequencerRow } from '@local/power-chord-lib/build/src/audio/sequencer';
import { PERCUSSION_NAMES } from '@local/power-chord-lib/build/src/audio/tone/instruments/percussion-instrument';

const COLORS = [
  "red", "yellow", "green", "cyan", "blue", "magenta",
  "orange", "limegreen", "teal", "royalblue", "purple", "olive"
];
const INSTR_NAMES: NameValueTitle[] = PERCUSSION_NAMES.map(n => [n]);

const MAX_ROWS = 16;

export type SequenceGridProps = {
  rows: SequencerRow[];
  barCount: number;
  unitsPerBeat: number;
  beatsPerBar: number;
  currentBeat: number;
  zoomFactor: number;
  onRowAdded: (instrument?: string) => any;
  onRowDeleted: (row: number) => any;
  onInstrumentChanged: (row: number, name: string) => any;
  onBeatChanged: (row: number, beat: number, on: boolean) => any;
};

export default function SequenceGrid(props: SequenceGridProps) {
  const rows = props.rows;

  return (
    <section className="grid" style={{ fontSize: (props.zoomFactor * 100) + "%" }}>
      <table>
        <thead>
          <tr>
            <th></th>
            {renderHeaderCells()}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowNum) =>
            <tr key={row.name + rowNum}>
              <th className={getColor(rowNum)}>
                <RenderWhen condition={rows.length > 1}>
                  <span className="delete-button" title="Delete row" onClick={() => deleteRow(rowNum)}>&times;</span>
                </RenderWhen>
                <PopupMenuButton itemsPerRow={7} menuItems={INSTR_NAMES} value={row.name} onChange={e => instrumentChanged(e, rowNum)} />
              </th>
              {renderBodyCells(rowNum)}
            </tr>)
          }
          <RenderWhen condition={rows.length < MAX_ROWS}>
            <tr>
              <th>
                <PopupMenuButton itemsPerRow={7} menuItems={INSTR_NAMES} value="" onChange={instrumentChanged}></PopupMenuButton>
              </th>
              {renderEmptyCells()}
            </tr>
          </RenderWhen>
        </tbody>
      </table>
    </section>
  );

  function renderBodyCells(rowNum: number): ReactNode[] {
    const notesPerBar = props.beatsPerBar * props.unitsPerBeat;

    return props.rows[rowNum].beats.map((isOn, colNum) => {
      const bar = Math.floor(colNum / notesPerBar) % 2;
      const className = colNum === props.currentBeat ? "hilite" : "bar" + bar;

      return (
        <td key={"col" + colNum}
          onClick={e => toggleBeat(rowNum, colNum)}
          className={className}>
          {isOn && <span className={getColor(rowNum)}></span>}
        </td>)
    });
  }

  function renderHeaderCells(): JSX.Element[] {
    const cellCount = props.barCount * props.beatsPerBar;
    const curBeat = Math.floor(props.currentBeat / props.unitsPerBeat);

    const headerRow = [] as JSX.Element[];
    for (let i = 0; i < cellCount; i++) {
      const bar = Math.floor(i / props.beatsPerBar) + 1;
      const beat = (i % props.beatsPerBar) + 1;
      const label = bar + "-" + beat;
      const className = i === curBeat ? "hilite" : "bar" + ((bar + 1) % 2);
      headerRow.push(<th key={i} colSpan={props.unitsPerBeat} className={className}>{label}</th>);
    }
    return headerRow;
  }

  function renderEmptyCells(): JSX.Element[] {
    const emptyRow = [] as JSX.Element[];
    const beatCount = props.barCount * props.beatsPerBar * props.unitsPerBeat;
    for (let i = 0; i < beatCount; i++) {
      emptyRow.push(<td key={"empty" + i}></td>);
    }
    return emptyRow;
  }

  function getColor(row: number): string {
    return COLORS[row % COLORS.length];
  }

  function deleteRow(row: number): void {
    if (props.rows.length > 1) {
      props.onRowDeleted(row);
    }
  }

  function instrumentChanged(name: string, row?: number): any {
    if (typeof row === "number") {
      props.onInstrumentChanged(row, name);
    }
    else {
      props.onRowAdded(name);
    }
  }

  function toggleBeat(rowNum: number, beatNum: number): void {
    const row = props.rows[rowNum];
    props.onBeatChanged(rowNum, beatNum, !row.beats[beatNum]);
  }
}
