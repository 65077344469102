import React from "react";
import SplitContainer from "./components/common/SplitContainer";

type PrivacyPolicyProps = {
  onConsent: () => void;
};

export default function PrivacyPolicy(props: PrivacyPolicyProps) {
  return (
    <div className="privacy-policy">
    <SplitContainer direction="vertical" leftWeight={1}>
      <div>
      This site uses cookies. See the <a href="/privacy-policy/index.htm" target="_blank" rel="noopener noreferrer">privacy policy</a>
      </div>
      <button onClick={props.onConsent}>Got it!</button>
    </SplitContainer>
  </div>
);
}