import { GuitarController } from "@local/power-chord-lib/build/src/guitar/guitar-controller";
import { GuitarShareParams, parseGuitarShareParams } from "@local/power-chord-lib/build/src/guitar/parse-guitar-share-params";
import { GuitarPositionInfo, GuitarState } from "@local/power-chord-lib/build/src/state-types";
import { MusicScale, Note, formatAccidentals } from "@power-chord/music-theory";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Key } from "ts-key-enum";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useAudioLib } from "../../hooks/useAudioLib";
import { useEventListener } from "../../hooks/useEventListener";
import { useLogger } from "../../hooks/useLogger";
import { NameValueTitle } from "../common/PopupMenuButton";
import Fretboard from "./Fretboard";
import { ChordInformation } from "./GuitarChordSelector";
import GuitarControls, { GuitarType } from "./GuitarControls";
import "./GuitarView.scss";

const STRUM_DELAY = 20;

export type GuitarViewProps = {
  guitarType: GuitarType;
  width: string;
  height: string;
  numFrets: number;
  controller: GuitarController;
  getState: () => GuitarState;
  saveState: (state: GuitarState) => Promise<void>;
}

export default function GuitarView(props: GuitarViewProps) {
  const analytics = useAnalytics();
  const logger = useLogger("GuitarView");
  const audioLib = useAudioLib("guitar");

  const [chordName, setChordName] = useState<string>(props.controller.chordName);
  const [selectedNotes, setSelectedNotes] = useState(props.controller.selectedNotes);
  const [tabString, setTabString] = useState(props.controller.tabString);
  const [selectedPositions, setSelectedPositions] = useState(props.controller.selectedPositions);
  const [hintPosition, setHintPosition] = useState<GuitarPositionInfo>();
  const [currentPos, setCurrentPos] = useState<GuitarPositionInfo>();
  const [soundOn, setSoundOn] = useState(props.getState().soundOn);

  const shareMenuItems = useMemo<NameValueTitle[]>(() => {
    const items = [] as string[];
    if (chordName) {
      items.push("Chord: " + chordName);
    }

    if (tabString) {
      items.push("Tab: " + formatAccidentals(tabString));
    }

    if (props.controller.isScaleSelected) {
      items.push("Scale: " + props.controller.selectedPositions.find(i => i.isRoot)?.note?.name);
    }
    return items.map(i => [i]);
  }, [chordName, props.controller.isScaleSelected, props.controller.selectedPositions, tabString]);

  // Check for share params in the url
  const shareParams = useParams() as GuitarShareParams;
  useEffect(() => {
    logger.debug(shareParams);
    if (shareParams.shareType && shareParams.shareId) {
      try {
        if (parseGuitarShareParams(shareParams, props.controller, analytics)) {
          saveState();
        }
        else {
          alert("Error parsing share url");
        }
      }
      catch (err) {
        alert("Error parsing share url: " + (err as Error).message);
        logger.error((err as Error).message);
        if ((err as Error).cause) {
          logger.error(((err as Error).cause as Error).message)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controller, shareParams]);

  useEventListener("keyup", e => onKeyUp(e as KeyboardEvent));

  return (
    <div className={"guitar-view"}>
      <Fretboard
        width={props.width}
        height={props.height}
        numFrets={props.numFrets}
        openNotes={props.controller.openNotes}
        hintPositions={hintPosition ? [hintPosition] : []}
        selectedPositions={selectedPositions}
        onHover={onPositionHover}
        onLeave={() => onPositionHover()}
        onSelect={onPositionSelected}
      />
      <div className="control-container">
        <GuitarControls
          guitarType={props.guitarType}
          stringCount={props.controller.openNotes.length}
          hoverFret={currentPos?.fret.toString() ?? ""}
          hoverNote={currentPos?.note}
          hoverString={currentPos ? (currentPos.str + 1).toString() : ""}
          chordName={chordName}
          selectedPositions={props.controller.selectedPositions}
          selectedNotes={selectedNotes}
          selectedTab={tabString}
          guitarLookup={props.controller.guitarLookup}
          shareMenuItems={shareMenuItems}
          soundOn={soundOn}
          onClearSelections={onClearSelections}
          onPlayNotes={playSelectedNotes}
          onSoundChanged={onSoundChanged}
          onChordSelected={onChordSelected}
          onScaleSelected={onScaleSelected} />
      </div>
    </div>
  );

  /**
   * Applies changes to persistentState and saves to persistent storage
   * @param changes GuitarState changes
   */
  function saveState(isSoundOn = soundOn): void {
    props.saveState({
      selectedPositions: props.controller.selectedPositions.slice(),
      soundOn: isSoundOn
    });
    updateInternalState();
  }

  /** Updates the internal state from the controller */
  function updateInternalState(): void {
    setChordName(props.controller.chordName);
    setTabString(props.controller.tabString);
    setSelectedNotes(props.controller.selectedNotes);
    setSelectedPositions(props.controller.selectedPositions);
  }

  function onClearSelections(): void {
    props.controller.clearAllPositions();
    saveState();
  }

  function onSoundChanged(soundOn: boolean): void {
    setSoundOn(soundOn);
    saveState(soundOn);
  }

  function onKeyUp(e: KeyboardEvent): void {
    switch (e.key) {
      case Key.Escape:
        props.controller.clearAllPositions();
        break;
      case " ":
        playSelectedNotes();
        break;
      default:
        return;
    }

    e.stopPropagation();
    e.preventDefault();
  }

  function onScaleSelected(scale: MusicScale): void {
    analytics.logEvent("guitar", "scale", scale.name);
    props.controller.setScale(scale);
    saveState();
  }

  function onChordSelected(info: ChordInformation): any {
    analytics.logEvent("guitar", "chord", () => info.root + info.quality, info.inversion);
    props.controller.setChord(info);
    saveState();
  }

  function onPositionSelected(pos: GuitarPositionInfo): void {
    const note = props.controller.updatePosition(pos);
    saveState();

    if (note) {
      playNotes(note);
    }
  }

  function onPositionHover(pos?: GuitarPositionInfo): void {
    if (pos) {
      const curPos = currentPos;
      if (!curPos || pos.fret !== curPos.fret || pos.str !== curPos.str) {
        setHintPosition(pos);
        setCurrentPos(pos);
      }
    }
    else {
      setHintPosition(undefined);
      setCurrentPos(undefined);
    }
  }

  function playSelectedNotes(): void {
    if (soundOn && audioLib.isLoaded) {
      analytics.logEvent("guitar", "play-notes");
      const notes = props.controller.selectedPositions
        .filter(p => p.note && p.fret >= 0)
        .map(p => p.note!)
        .reverse();
      playNotes(...notes);
    }
  }

  function playNotes(...notes: Note[]): void {
    if (soundOn && audioLib.isLoaded) {
      logger.debug("playing notes", notes);
      audioLib.play(notes.map(n => n.name + n.octave), STRUM_DELAY);
    }
  }
}
