import { TheoryState } from '@local/power-chord-lib/build/src/state-types';
import { getCircleOfFifths, getNote, getScale, ModeName, MusicScale, NoteName } from '@power-chord/music-theory';
import { COF_NOTES, DegreeName, MODE_NAMES } from '@power-chord/music-theory/circle-of-fifths';
import { normalizeNoteName } from '@power-chord/music-theory/notes';
import { CircularList } from '@power-chord/music-theory/util/circular-list';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Key } from 'ts-key-enum';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useEventListener } from '../../hooks/useEventListener';
import { useLogger } from '../../hooks/useLogger';
import { useStateService } from '../../hooks/useStateService';
import KeySelector from '../common/KeySelector';
import { CircleOfFifthsRenderer } from './circle-of-fifths-renderer';
import { KeySignatureRenderer } from './key-signature-renderer';
import KeyNotesAndChords from './KeyNotesAndChords';
import "./TheoryView.scss";

const CIRCULAR_MODE_NAMES = new CircularList(MODE_NAMES);

export default function TheoryView() {
  const analytics = useAnalytics();
  const logger = useLogger("TheoryView");
  const stateService = useStateService();

  const signatureRenderer = useRef<KeySignatureRenderer>();
  const cofRenderer = useRef<CircleOfFifthsRenderer>();
  const circleOfFifths = useRef(getCircleOfFifths(getScale("C")));

  const cofCanvasRef = useRef<HTMLCanvasElement>(null);
  const signatureCanvasRef = useRef<HTMLCanvasElement>(null);

  const [persistentState, setPersistentState] = useState<TheoryState>(stateService.getState().theory);
  const [notesInKey, setNotesInKey] = React.useState<string[]>([]);
  const [chordsInKey, setChordsInKey] = React.useState<string[]>([]);
  const [chordNumbers, setChordNumbers] = React.useState<string[]>([]);
  const [noteDegrees, setNoteDegrees] = React.useState<DegreeName[]>([]);

  useEffect(() => {
    signatureRenderer.current = new KeySignatureRenderer(signatureCanvasRef.current!);
    cofRenderer.current = new CircleOfFifthsRenderer(cofCanvasRef.current!, stateService.getState().color);

    const scale = getScale(persistentState.selectedKey as NoteName, persistentState.selectedQuality as ModeName);
    circleOfFifths.current = getCircleOfFifths(scale);
    signatureRenderer.current.setScale(scale);
    cofRenderer.current.setScale(scale);

    updateNotesInKey();
  }, [persistentState.selectedKey, persistentState.selectedQuality, stateService]);

  useEventListener("keyup", e => onKeyUp(e as KeyboardEvent));

  return (
    <div className="theory-view">
      <section className="selectKey">
        <label>Select Key:</label>
        <KeySelector
          extended={true}
          selectedTonic={persistentState.selectedKey as NoteName}
          selectedMode={persistentState.selectedQuality as ModeName}
          onChange={k => keySelected(k)} />
      </section>
      <div className="circleOfFifths">
        <canvas ref={cofCanvasRef} width="600" height="600"></canvas>
      </div>
      <div>
        <section className="notesInKey">
          <KeyNotesAndChords
            selectedKey={persistentState.selectedKey}
            selectedQuality={persistentState.selectedQuality}
            notesInKey={notesInKey}
            chordsInKey={chordsInKey}
            chordNumbers={chordNumbers}
            noteDegrees={noteDegrees} />
          <div className="signatureCanvas">
            {/* todo: show all the notes in the scale */}
            <canvas ref={signatureCanvasRef} width="100" height="84"></canvas>
            <label>Key Signature</label>
          </div>
        </section>
      </div>
    </div>
  );

  function onKeyUp(e: KeyboardEvent): void {
    switch (e.key) {
      case Key.ArrowRight:
        incrementSelectedKey(-1);
        break;
      case Key.ArrowLeft:
        incrementSelectedKey(1);
        break;
      case Key.ArrowUp:
        incrementSelectedMode(1);
        break;
      case Key.ArrowDown:
        incrementSelectedMode(-1);
        break;
      case Key.Escape:
        keySelected(getScale("C", "major"));
        break;
      // case "1":
      // case "2":
      // case "3":
      // case "4":
      // case "5":
      // case "6":
      // case "7":
      //   keyNotesAndChordsRef.current!.toggleTriad(parseInt(e.key) - 1);
      //   break;
      case "a": case "A":
      case "b": case "B":
      case "c": case "C":
      case "d": case "D":
      case "e": case "E":
      case "f": case "F":
      case "g": case "G":
        const note = normalizeNoteName((e.shiftKey ? e.key + "#" : e.key.toUpperCase()) as NoteName);
        keySelected(getScale(note, persistentState.selectedQuality as ModeName));
        break;
      default:
        return;
    }

    e.preventDefault();
  }

  function incrementSelectedKey(amount: number): void {
    const note = getNote(persistentState.selectedKey as NoteName);
    const index = COF_NOTES.indexOf(note);
    const next = COF_NOTES.itemAt(index + amount);

    keySelected(getScale(next, persistentState.selectedQuality as ModeName));
  }

  function incrementSelectedMode(amount: number): void {
    const mode = persistentState.selectedQuality as ModeName;
    const index = CIRCULAR_MODE_NAMES.indexOf(mode);
    const next = CIRCULAR_MODE_NAMES.itemAt(index + amount);

    keySelected(getScale(persistentState.selectedKey as NoteName, next));
  }

  function keySelected(scale: MusicScale): void {
    logger.debug("Key selected", scale.name);
    analytics.logEvent("theory", "key", scale.name);
    const persistent: TheoryState = {
      selectedQuality: scale.mode,
      selectedKey: scale.tonic.name
    };
    setPersistentState(persistent);
    stateService.setTheoryState(persistent);

    circleOfFifths.current = getCircleOfFifths(scale);
    updateNotesInKey();

    cofRenderer.current!.setScale(scale);
  }

  function updateNotesInKey(): void {
    const notes = circleOfFifths.current.scale.notes;
    const fifths = circleOfFifths.current.orderedFifths;

    setNotesInKey(notes.map(n => n.formattedName));
    setChordsInKey(circleOfFifths.current.scale.chords.map(c => c.formattedName));
    setChordNumbers(fifths.map(f => f.degreeRoman));
    setNoteDegrees(fifths.map(f => f.degreeName));

    if (signatureRenderer.current) {
      signatureRenderer.current.setScale(circleOfFifths.current.scale);
    }
  }
}
