import { MusicScale, parseChord, parseScale } from "@power-chord/music-theory";
import AnalyticsService from "../services/analytics-service";
import { GuitarPositionInfo } from "../state-types";
import { ChordInformation, GuitarController } from "./guitar-controller";
import { getLogger } from "analogging";
import { upperCaseFirstLetter } from "../utils";

export type GuitarShareParams = {
    shareType: "chord" | "scale" | "tab";
    /** The chord, tab or scale */
    shareId: string;
};

export type GuitarShareInfo = {
    chord?: ChordInformation;
    scale?: MusicScale;
    tab?: GuitarPositionInfo[];
};

/**
 * Parses guitar share parameters and updates the controller
 * @returns True if the controller was updated
 * @throws An error if the share params are invalid
 */
export function parseGuitarShareParams(shareParams: GuitarShareParams, controller: GuitarController, svc: AnalyticsService): boolean {
    const logger = getLogger("parseGuitarShareParams");

    const info = getGuitarShareInfo(shareParams, svc);
    if (info) {
        if (info.chord) {
            logger.debug("Setting chord from url", info.chord);
            return controller.setChord(info.chord);
        }
        else if (info.scale) {
            logger.debug("Setting scale from url", info.scale);
            controller.setScale(info.scale);
            return true;
        }
        else if (info.tab) {
            if (info.tab.length !== controller.numStrings) {
                throw new Error("Wrong number of positions in tab: " + shareParams.shareId);
            }
            if (info.tab.some(p => p.fret < -1 || p.fret > controller.numFrets)) {
                throw new Error("Invalid fret number in tab: " + shareParams.shareId);
            }
            logger.debug("Setting tab from url", info.tab);
            controller.setTab(info.tab);
            return true;
        }
    }

    return false;
}

export function getGuitarShareInfo(params: GuitarShareParams, svc: AnalyticsService): GuitarShareInfo | undefined {
    switch (params.shareType) {
        case "chord": return getChord(params.shareId, svc);
        case "scale": return getScale(params.shareId, svc);
        case "tab": return getTab(params.shareId, svc);
    }
}

function logAnalyticsEvent(action: string, svc: AnalyticsService): void {
    svc.logEvent("guitar", action);
}

/**
 * Parses chord URL params into selections.
 * Format is {chord-name}-{inversion-number}
 * @returns The chord if exists and valid, otherwise undefined
 */
function getChord(value: string, svc: AnalyticsService): GuitarShareInfo | undefined {
    logAnalyticsEvent("url-chord", svc);
    try {
        const parts = decodeURIComponent(value).split("-");
        const chordName = upperCaseFirstLetter(parts[0]);
        const chord = parseChord(chordName);
        if (chord) {
            return {
                chord: {
                    root: chord.root.name,
                    quality: chord.quality,
                    inversion: parts[1] ? parseInt(parts[1]) - 1 : 0
                }
            };
        }
    }
    catch (err) {
        const logger = getLogger("parseGuitarShareParams");
        logger.error("Error parsing chord:", err.message);
        throw new Error("Invalid chord in url: " + value);//, { cause: err });
    }

    throw new Error("Couldn't find chord: " + value);
}

/**
 * Gets the scale from the URL if it exists
 * @returns The scale if exists and valid, otherwise undefined
 */
function getScale(value: string, svc: AnalyticsService): GuitarShareInfo | undefined {
    logAnalyticsEvent("url-scale", svc);
    try {
        const scaleName = upperCaseFirstLetter(decodeURIComponent(value));
        const scale = parseScale(scaleName);
        if (scale) {
            return { scale };
        }
    }
    catch (err) {
        const logger = getLogger("parseGuitarShareParams");
        logger.error("Error parsing scale:", err.message);
        throw new Error("Invalid scale in url: " + value);//, { cause: err });
    }

    throw new Error("Couldn't find scale: " + value);
}

function getTab(value: string, svc: AnalyticsService): GuitarShareInfo | undefined {
    try {
        logAnalyticsEvent("url-tab", svc);
        return { tab: parseTab(decodeURIComponent(value)) };
    }
    catch (err) {
        const logger = getLogger("parseGuitarShareParams");
        logger.error("Error parsing tab:", err.message);
        throw new Error("Invalid tab in url: " + value);//, { cause: err });
    }
}

/**
 * Parses tab URL params into selections
 */
function parseTab(value: string): GuitarPositionInfo[] {
    const reTab = /(X|\d|\[\d{1,2}\])/g;
    const frets: number[] = [];

    let match: RegExpExecArray | null;
    for (let str = 0; (match = reTab.exec(value)) !== null; str++) {
        let fret = match[0];
        if (fret[0] === "[" && fret.endsWith("]")) {
            fret = fret.slice(1, -1);
        }
        const fretNum = fret === "X" ? -1 : parseInt(fret);

        frets.push(fretNum);
    }

    return frets.reverse().map((f, i) => ({ fret: f, str: i } as GuitarPositionInfo));
}

