import { NoteName } from "@power-chord/music-theory";
import MusicStaffRenderer from "../common/music-staff-renderer";

const notePositions: Record<string, number> = {
    C: 0,
    D: 1,
    E: 2,
    F: 3,
    G: 4,
    A: 5,
    B: 6
};

export default class KeyboardStaffRenderer extends MusicStaffRenderer {
    // There are 15 spots for displaying notes
    private notes = new Array<number>(15);
    private accidentals = new Array<string>(15);

    constructor(canvas: HTMLCanvasElement) {
        super(canvas);

        this.clearNotes();
    }

    public clearNotes(): void {
        for (let i = 0; i < this.notes.length; i++) {
            this.notes[i] = 0;
            this.accidentals[i] = "";
        }
        this.render();
    }

    public addNote(note: NoteName, octave: number): void {
        let n = this.getNotePosition(note, octave);
        this.notes[n] += 1;
        if (note.length > 1) {
            this.accidentals[n] = note.charAt(1);
        }
        this.render();
    }

    public removeNote(note: NoteName, octave: number): void {
        let n = this.getNotePosition(note, octave);
        this.notes[n] -= 1;
        if (this.notes[n] === 0 || note.length > 1) {
            this.accidentals[n] = "";
        }
        this.render();
    }

    // @override
    protected drawContent(): KeyboardStaffRenderer {
        return this.drawNotes();
    }

    private drawNotes(): KeyboardStaffRenderer {
        // lowest note
        const c2 = this._top + 10.5 + 35;
        const noteSize = 10;

        for (let i = 0; i < this.notes.length; i++) {
            if (this.notes[i]) {
                let y = c2 - i * (noteSize / 2);
                //console.log(`octave: ${note.octave}, y: ${y}`);
                let offset = this.getNoteOffset(i);
                // If note outside staff and even then draw a ledger line
                let drawLedgerLine = ((i < 1 || i > 11) && i % 2 === 0);
                this.drawNote(50 + offset, y, drawLedgerLine);
                if (i === 14 && this.notes[12] === 0) {
                    this.drawLedgerLine(50 + offset, y + noteSize);
                }

                if (this.accidentals[i]) {
                    if (this.accidentals[i] === "#") {
                        this.drawSharp(38, y);
                    }
                    else if (this.accidentals[i] === "b") {
                        offset = this.getAccidentalOffset(i);
                        this.drawFlat(38 + offset, y);
                    }
                }
            }
        }

        return this;
    }

    private getNoteOffset(pos: number): number {
        // If note is at an odd index and there is a note above or below it then offset it to the right
        return (pos % 2 === 1 && (this.notes[pos - 1] || this.notes[pos + 1]) ? 14 : 0);
    }

    private getAccidentalOffset(pos: number): number {
        if (pos === 6 && this.accidentals[5] && this.accidentals[7]) {
            // Special case when Ab, Bb, C# are all selected: push Bb further left
            return -16;
        }
        return (this.accidentals[pos + 1] || this.accidentals[pos + 2] ? -8 : 0);
    }

    private getNotePosition(note: NoteName, octave: number): number {
        // Note: octave on keyboard starts at 2
        return (octave - 2) * 7 + notePositions[note.charAt(0)];
    }

    protected drawSharp(x: number, y: number): KeyboardStaffRenderer {
        super.drawSharp(x, y, 8, 14);
        return this;
    }

    protected drawFlat(x: number, y: number): KeyboardStaffRenderer {
        super.drawFlat(x, y, 8, 14);
        return this;
    }

    private drawLedgerLine(x: number, y: number): KeyboardStaffRenderer {
        this.context.drawLine(x - 2, y + 4.5, x + 18, y + 4.5);
        return this;
    }

    private drawNote(x: number, y: number, drawLedgerLine = false): KeyboardStaffRenderer {
        this.context.drawClippedImage(this.images, 154, 184, 16, 9, x, y);
        if (drawLedgerLine) {
            this.drawLedgerLine(x, y);
        }
        return this;
    }
}
