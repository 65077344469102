import * as React from "react";
import TriangleIcon from "./icons/TriangleIcon";

type PlayNotesButtonProps = {
  soundOn: boolean;
  audioLoaded: boolean;
  audioError: string;
  audioPercent: number;
  onClick: () => void;
};

/**
 * A button that displays a play icon when audio has been loaded.
 * If audio is loading a progress bar is displayed.
 * If there was an error loading audio a message is displayed instead.
 * If sound is off nothing is displayed.
 */
export default function PlayNotesButton(props: PlayNotesButtonProps) {
  if (props.soundOn) {
    if (props.audioLoaded) {
      return <button title="Play selected notes (Space)" onClick={props.onClick}><TriangleIcon /> Play Notes</button>;
    }
    else if (props.audioError) {
      return <span>Error loading audio!</span>
    }
    else {
      return <progress title="Loading audio" value={props.audioPercent}></progress>;
    }
  }
  else {
    return <span></span>;
  }
}