import { useMemo } from 'react';
import { ChordProgressionItem } from '@local/power-chord-lib/build/src/state-types';
import ChordListItem from './ChordListItem';
import RenderWhen from '../common/RenderWhen';

const BEATS_PER_ROW = 32;

export type ChordListProps = {
  items: ChordProgressionItem[];
  currentBeat: number;
  onDeleteClicked: (idx?: number) => any;
  onAddBeat: (idx: number, count: number) => any;
};

export default function ChordList(props: ChordListProps) {
  const rows = useMemo(getChordRows, [props.items]);
  const beatCount = useMemo(() => props.items.reduce((p, c) => p + c.beats, 0), [props.items]);

  let cnt = 0;
  return (
    <section className="chord-list">
      <RenderWhen condition={props.items.length === 0}>
         <span className="message">No progression, try adding some chords below</span>
      </RenderWhen>
      {rows.map((row, i) =>
        <ol key={i}>
          {row.map((item, j) =>
            <ChordListItem
              key={j}
              item={item}
              index={j}
              isHilited={props.currentBeat >= cnt && props.currentBeat < (cnt += item.beats)}
              onAddBeat={props.onAddBeat}
              onDeleteClicked={props.onDeleteClicked} />
          )}
          <RenderWhen condition={i === rows.length - 1}>
            <li className="summary">
              <button className="delete-button" title="Delete (Backspace)" onClick={() => props.onDeleteClicked()}>&#9003;</button>
              <span>{beatCount} beats</span>
            </li>
          </RenderWhen>
        </ol>
      )}
    </section>
  );

  /**
   * Breaks the list of ChordProgressionItems into rows based on max beats per row
   */
  function getChordRows(): ChordProgressionItem[][] {
    const rows: ChordProgressionItem[][] = [];
    const items = props.items;
    let beatCount = 0;
    let rowCount = 0;

    if (items.length > 0) {
      rows[0] = [];
      for (let i = 0; i < items.length; i++) {
        beatCount += items[i].beats;
        if (beatCount <= BEATS_PER_ROW) {
          rows[rowCount].push(items[i]);
        }
        else {
          rows[++rowCount] = [items[i]];
          beatCount = items[i].beats;
        }
      }
    }

    return rows;
  }
}