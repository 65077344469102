import React from "react";

function ZoomInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M386.348 336.165c23.574-34.148 37.408-75.546 37.408-120.19 0-117.015-94.843-211.878-211.878-211.878S0 98.959 0 215.974s94.843 211.878 211.878 211.878c48.849 0 93.8-16.572 129.637-44.338l124.388 124.388L512 461.812 386.348 336.165zm-174.47 46.052c-91.667 0-166.243-74.574-166.243-166.243 0-91.667 74.574-166.243 166.243-166.243s166.243 74.574 166.243 166.243-74.576 166.243-166.243 166.243z"></path>
      <path d="M234.695 196.417L234.695 114.924 189.06 114.924 189.06 196.417 107.569 196.417 107.569 242.052 189.06 242.052 189.06 323.543 234.695 323.543 234.695 242.052 316.187 242.052 316.187 196.417z"></path>
    </svg>
  );
}

export default ZoomInIcon;
