import React from "react";

function UkuleleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path d="M505.421 41.409L470.593 6.581a22.467 22.467 0 00-33.138 1.491l-43.436 52.046a10.841 10.841 0 00.658 14.615l.911.911-153.93 153.93c-34.934-21.777-75.492-22.453-99.074 1.13-6.424 6.424-11.048 14.109-13.934 22.597-5.536 16.276-20.405 27.574-37.57 28.547-25.401 1.442-49.525 11.256-67.98 29.711-43.476 43.475-20.772 100.17 28.2 149.141 48.972 48.972 105.665 71.676 149.141 28.199 18.455-18.455 28.269-42.578 29.711-67.979.974-17.163 12.273-32.034 28.547-37.57 8.487-2.887 16.172-7.51 22.597-13.934 23.582-23.582 22.906-64.14 1.13-99.074l153.93-153.93.911.911a10.843 10.843 0 0014.615.658l52.046-43.436a22.466 22.466 0 001.493-33.136zM149.377 433.047a16.906 16.906 0 01-11.992 4.968 16.9 16.9 0 01-11.992-4.968l-46.438-46.438c-6.623-6.622-6.623-17.362 0-23.985 6.622-6.623 17.362-6.623 23.985 0l46.438 46.438c6.624 6.623 6.624 17.362-.001 23.985zm70.745-81.907c-16.364 16.364-42.897 16.364-59.261 0-16.364-16.364-16.364-42.897 0-59.261 16.364-16.364 42.897-16.364 59.261 0 16.364 16.365 16.364 42.897 0 59.261z"></path>
    </svg>
  );
}

export default UkuleleIcon;