import { useRef } from "react";

/**
 * Implements an immutable object that will persist for the full lifetime of the component.
 * Use this version when your constant is not a primitive value.
 * @param getValue A function that returns the value of the constant
 */
export function useConst<T>(getValue: ()=>T): T;

/**
 * Implements an immutable object that will persist for the full lifetime of the component.
 * @param value Value of the constant
 */
export function useConst<T>(value: T): T;

export function useConst<T>(value: T|(()=>T)): T {
    const ref = useRef<T>();
    if (ref.current === undefined) {
        ref.current = typeof value === 'function' ? (value as ()=>T)() : value;
    }
    return ref.current as T;
}