import { useAnalytics } from "../../hooks/useAnalytics";
import LogoIcon from '../common/icons/LogoIcon';
import NewWindowIcon from '../common/icons/NewWindow';
import PowerChordIcon from '../common/icons/PowerChordIcon';
import "./AboutView.scss";

export type AboutViewProps = {
  version: string;
};

const currentYear = new Date().getFullYear();

export default function AboutView(props: AboutViewProps) {
  const analytics = useAnalytics();
  return (
    <div className="help-view">
      <header title="PowerChord">
        <PowerChordIcon/>
      </header>
      <LogoIcon/>
      <section>
        <div>version {props.version}</div>
        <div>Copyright &copy; {currentYear}, JM Gustafson</div>
        <div>
          <a href="https://worldtreesoftware.com" target="_blank" rel="noopener noreferrer" onClick={() => logEvent("click", "wts")}>
            WorldTree Software
          </a>
        </div>
      </section>
      <p className="help">
        <a href="/help/index.htm" target="_blank" rel="noopener noreferrer">View online help <NewWindowIcon /></a>
      </p>
      <p className="help">
        <a href="https://www.youtube.com/watch?v=fdisAV2B3hs" target="_blank" rel="noopener noreferrer">
          Watch the Power Chord Introduction video <NewWindowIcon />
        </a>
        {/* <div className="video">
                        <iframe title="PowerChord Intro" width="560" height="315" src="https://www.youtube.com/embed/fdisAV2B3hs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div> */}
      </p>
      <p>
        To connect with other users, ask questions, and get information on new releases,<br></br>
        <a href="https://www.facebook.com/groups/powerchordapp" target="_blank" rel="noopener noreferrer">
          Join the Facebook group <NewWindowIcon />
        </a>
      </p>
      <p>
        Send comments/suggestions/questions&nbsp;
        <a href="https://worldtreesoftware.com/contact" target="_blank" rel="noopener noreferrer" onClick={() => logEvent("click", "contact")}>
          here <NewWindowIcon />
        </a>.
      </p>
      <p>
        <a href="/privacy-policy/index.htm" target="_blank" rel="noopener noreferrer">View privacy policy <NewWindowIcon /></a>
      </p>
    </div>
  );

  function logEvent(action: string, label?: string): void {
    analytics.logEvent("about", action, label)
  }
}
