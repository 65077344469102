import { ViewName } from "@local/power-chord-lib/build/src/state-types";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import ArrowsIcon from "../common/icons/ArrowsIcon";
import "./NavBar.scss";
import UkuleleIcon from "./icons/UkuleleIcon";
import GuitarIcon from "./icons/guitar-icon";
import HelpIcon from "./icons/help-icon";
import KeyboardIcon from "./icons/keyboard-icon";
import MicrophoneIcon from "./icons/microphone-icon";
import ProgressionsIcon from "./icons/progressions-icon";
import RhythmIcon from "./icons/rhythm-icon";
import SettingsIcon from "./icons/settings-icon";
import TheoryIcon from "./icons/theory-icon";

type NavBarProps = {
  expanded: boolean;
  onExpandedChanged: (expanded: boolean) => void;
  onViewChanged: (view: ViewName) => void;
};

export default function NavBar(props: NavBarProps) {
  const expandBtnRef = useRef<HTMLButtonElement>(null);

  return (
    <nav className={props.expanded ? "expanded" : ""} onClick={e => onLinkClicked(e)}>
      <ViewLink viewName="keyboard" title="Keyboard"><KeyboardIcon /><label>Keyboard</label></ViewLink>
      <ViewLink viewName="guitar" title="Guitar"><GuitarIcon /><label>Guitar</label></ViewLink>
      <ViewLink viewName="ukulele" title="Ukulele"><UkuleleIcon /><label>Ukulele</label></ViewLink>
      <ViewLink viewName="vocals" title="Vocals"><MicrophoneIcon /><label>Vocals</label></ViewLink>
      <ViewLink viewName="rhythm" title="Rhythm Sequencer"><RhythmIcon /><label>Rhythms</label></ViewLink>
      <ViewLink viewName="progression" title="Progression Builder"><ProgressionsIcon /><label>Progressions</label></ViewLink>
      <ViewLink viewName="theory" title="Music Theory"><TheoryIcon /><label>Theory</label></ViewLink>
      <ViewLink viewName="settings" title="Settings"><SettingsIcon /><label>Settings</label></ViewLink>
      <ViewLink viewName="about" title="Help/About"><HelpIcon /><label>Help/About</label></ViewLink>
      <button ref={expandBtnRef} title={props.expanded ? "Collapse" : "Expand"} onClick={e => toggleExpanded(e)}><ArrowsIcon /></button>
    </nav>
  );

  function toggleExpanded(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    // Make the selection box go away
    expandBtnRef.current!.blur();
    props.onExpandedChanged(!props.expanded);
  }
  
  function onLinkClicked(e: React.MouseEvent<HTMLElement, MouseEvent>): void {
    let target = e.target as HTMLElement | null;
    while (target && target.tagName.toUpperCase() !== "A") {
      target = target.parentElement;
    }

    if (target?.dataset.view) {
      props.onViewChanged(target.dataset.view as ViewName);
    }
  }
}

type ViewLinkProps = {
  viewName: ViewName;
  title: string;
  children?: React.ReactNode;
};

function ViewLink(props: ViewLinkProps) {
  return (
    <NavLink to={"/" + props.viewName} title={props.title} data-view={props.viewName}>
      {props.children}
    </NavLink>
  );
}