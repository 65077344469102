import React from "react";

function TriangleIcon() {
  return (
    <svg className="triangle-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.585 39.935"
    >
      <path d="M34.671 20.066L.086 40.034V.099l17.292 9.984z"></path>
    </svg>
  );
}

export default TriangleIcon;