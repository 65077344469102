import React from "react";

function UndoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 25.64 25.64"
      xmlSpace="preserve"
    >
      <path
        d="M10.385 1.499a.78.78 0 00-.848.168L.228 8.98a.777.777 0 000 1.1l9.309 7.278a.772.772 0 00.848.17.764.764 0 00.465-.719V12.45c9.955 0 14.309 2.808 13.646 11.751C28.713 9.893 20.619 6.659 10.85 6.659V2.217a.764.764 0 00-.465-.718z"
      ></path>
    </svg>
  );
}

export default UndoIcon;