import React from "react";

function RepeatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        fill="#161C27"
        d="M69.816 256H0l93.096 93.096L186.2 256h-69.816c.224-77.016 62.6-139.4 139.616-139.632 22.672.432 44.952 6 65.16 16.296l34.896-34.896A207.988 207.988 0 00256 69.832C153.296 70.112 70.104 153.296 69.816 256zm325.8 0c-.224 77.016-62.6 139.4-139.616 139.632-22.672-.432-44.952-6-65.16-16.296l-34.896 34.896A207.988 207.988 0 00256 442.168c102.696-.296 185.88-83.472 186.184-186.168H512l-93.096-93.096L325.8 256h69.816z"
      ></path>
    </svg>
  );
}

export default RepeatIcon;