import { getNote, Note } from '@power-chord/music-theory';
import { AudioTuner, TunerEvent } from 'audio-analyzer';
import { VocalRange } from '../state-types';

const MIN_HOLD_TIME = 200;
const POLL_INTERVAL = 100;

/**
 * Listens for tuner note changes and keeps track of vocal range lows and highs
 */
export class VocalRangeFinder {
    private noteStartTime = 0;
    private currentNote?: Note;
    private range: VocalRange = {};
    private readonly listener = (e: TunerEvent) => this.onTunerNoteChanged(e);

    constructor(
        readonly tuner: AudioTuner,
        readonly onNoteChanged: (note?: Note) => void,
        readonly onRangeChanged: (range: VocalRange) => void,
        readonly minHoldTime = MIN_HOLD_TIME) {
    }

    start(initRange?: VocalRange): void {
        this.noteStartTime = 0;
        this.currentNote = undefined;
        this.range = initRange || {};

        this.tuner.addTunerListener(this.listener);
        this.tuner.start(POLL_INTERVAL);
    }

    stop(): void {
        this.tuner.stop();
        this.tuner.removeTunerListener(this.listener);
    }

    private onTunerNoteChanged(e: TunerEvent): void {
        if (e.note) {
            const note = getNote(e.noteNumber % 12, e.octave);
            const time = Date.now();

            if (note === this.currentNote) {
                // The current note is continuing
                this.onContinueNote(time, note);
            }
            else {
                // Note changed, reset time
                this.currentNote = note;
                this.noteStartTime = time;
            }
        }
        else {
            // No note detected, reset
            this.noteStartTime = 0;
            this.currentNote = undefined;
        }

        this.onNoteChanged(this.currentNote);
    }

    private onContinueNote(time: number, note: Note) {
        const elapsed = time - this.noteStartTime;
        if (elapsed >= this.minHoldTime) {
            // Check if it's better than the current lowest
            if (this.isNoteBetter(true, note, this.range.low)) {
                this.range.low = note;
            }

            // Check if it's better than the current highest
            if (this.isNoteBetter(false, note, this.range.high)) {
                this.range.high = note;
            }

            this.onRangeChanged(Object.assign({}, this.range));
        }
    }

    /**
     * Compares a note to the best note
     */
    private isNoteBetter(isLow: boolean, note: Note, bestNote: Note | undefined): boolean {
        if (!bestNote) return true;

        const bestNumber = bestNote.midiNumber;
        const noteNumber = note.midiNumber;

        if (isLow) {
            return noteNumber < bestNumber;
        }
        else {
            return noteNumber > bestNumber;
        }
    }
}
