import { Note } from "@power-chord/music-theory";

export type CaptureEvent = {
    note?: Note;
    cents?: number;
    startTime: number;
    endTime: number;
};

export class TuneCaptureProcessor {
    private _events: CaptureEvent[] = [];
    private _ttlTime = 0;
    
    get events(): CaptureEvent[] {
        return this._events;
    }

    get lastEvent(): number|undefined {
        return this._events.length > 0 ? this._events[this._events.length - 1].startTime : undefined;
    }

    get startTime(): number|undefined {
        return this._events.length > 0 ? this._events[0].startTime : undefined;
    }

    get lastTime(): number|undefined {
        return this._events.length > 0 ? this._events[this._events.length - 1].endTime : undefined;
    }

    get totalTime(): number {
        return this._ttlTime;
    }

    reset(): void {
        this._events = [];
        this._ttlTime = 0;
    }

    /**
     * Adds an event or signals the end of the last event
     */
    addEvent(curTime: number, note?: Note, cents?: number): void {
        const lastEvent = this._events[this._events.length - 1] || { startTime: curTime, endTime: curTime };
        // Set end time of the previously last event
        lastEvent.endTime = curTime;

        if (cents) {
            // cents is [-50, 50]
            // round to nearest 5 and convert to percent where -.5 is flat, 0 is perfect, .5 is sharp
            cents = (Math.round(cents / 5) * 5) / 100;
        }

        if (lastEvent.note !== note || lastEvent.cents !== cents) {
            this._events.push({
                note: note,
                cents: cents,
                startTime: curTime,
                endTime: curTime
            });
        }

        this._ttlTime = curTime - this.startTime!;
    }
}