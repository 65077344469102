import { CanvasRenderer } from "../common/canvas-renderer";
import { IGuitarTabRenderer } from "./guitar-tab-renderer";

const NUM_FRETS = 5;

export class GuitarGridRenderer extends CanvasRenderer implements IGuitarTabRenderer {
    private positions: number[] = [0, 0, 0, 0, 0, 0];
    private numStrings = 6;
    private top = 11.5;
    private left = 21.5;
    private lineGap = 14;
    private width = 70;
    private height = 70;

    constructor(canvas: HTMLCanvasElement) {
        super(canvas);
        this.setTab(this.positions);
        this.render();
    }

    public setTab(positions: number[]): void {
        this.positions = positions;
        this.numStrings = positions.length;
        this.width = (this.numStrings - 1) * this.lineGap;
        this.left = this.numStrings === 4 ? 31.5 : 21.5;
        this.render();
    }

    protected draw(): GuitarGridRenderer {
        this.context.save()
            .clear()
            .fillStyle("whitesmoke")
            .fillRect(0, 0, this.canvas.width, this.canvas.height)
            .fillStyle("black")
            .strokeStyle("black");
        this.drawLines()
            .drawPositions();
        this.context.restore();

        return this;
    }

    private drawLines(): GuitarGridRenderer {
        // vertical
        for (let i = 0; i < this.numStrings; i++) {
            let x = this.left + (i * this.lineGap);
            this.context.drawLine(x, this.top, x, this.top + this.height);
        }
        // horizontal
        for (let i = 0; i < NUM_FRETS; i++) {
            let y = this.top + (i * this.lineGap);
            this.context.drawLine(this.left, y, this.left + this.width, y);
        }

        return this;
    }

    private drawPositions(): GuitarGridRenderer {
        this.context
            .font("12px sans-serif")
            .textBaseline("middle")
            .textAlign("right");

        let max = this.positions.reduce((prev, cur) => cur > prev ? cur : prev, 1);
        let top = 1;
        if (max > NUM_FRETS) {
            // Set top to min position
            top = this.positions.reduce((prev, cur) => cur > 0 && cur < prev ? cur : prev, max);
        }

        this.drawFretNumbers(top)
            .drawPoints(top);

        return this;
    }

    private drawPoints(top: number): GuitarGridRenderer {
        for (let i = 0; i < this.numStrings; i++) {
            let pos = this.positions[i];
            let x = this.left + ((this.numStrings - 1 - i) * this.lineGap);
            if (pos < 0) {
                this.context.textAlign("center").fillText("x", x, 5);
            }
            else if (pos === 0) {
                this.context.drawCircle(x, 6, 3.5);
            }
            else {
                let y = this.top + ((pos - top) * this.lineGap) + this.lineGap / 2;
                this.context.fillCircle(x, y, 5);
            }
        }

        return this;
    }

    private drawFretNumbers(top: number): GuitarGridRenderer {
        let x = this.left - 7;

        for (let i = 0; i < NUM_FRETS; i++) {
            let n = top + i;
            this.context.fillText(n.toString(), x, this.top * 1.6 + (i * this.lineGap));
        }
        return this;
    }
}
