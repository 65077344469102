import React from "react";

function LogoIcon() {
    return (
        <svg className="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21.394 21.394"
    >
      <path
        fill="none"
        d="M49.915 15.53H70.66c.102 0 .184.082.184.184v20.745a.183.183 0 01-.184.183H49.915a.183.183 0 01-.184-.183V15.714c0-.102.082-.184.184-.184z"
        transform="translate(-49.59 -15.389)"
      ></path>
      <path
        d="M65.513 23.387c0-1.455-1.376-3.784-5.053-3.784-2.726 0-5.424 3.281-5.424 6.139 0 3.655 2.063 6.826 5.556 6.826 3.942 0 4.947-3.28 4.947-4.895h-.45c-.211 1.376-1.534 4.101-3.81 4.101-2.46 0-3.095-2.196-3.095-5.741 0-4.472 1.455-5.636 3.228-5.636 1.402 0 2.143.74 2.275.926.053.106.08.238-.026.344-1.058 0-2.302.74-2.302 2.223 0 .9.635 1.905 1.984 1.905 1.032 0 2.143-.794 2.143-2.382z"
        transform="translate(-49.59 -15.389)"
      ></path>
      <path
        d="M50.496 16.604h1.026v18.943h-1.026zM70.224 16.614h-1.026v18.943h1.026z"
        transform="translate(-49.59 -15.389)"
      ></path>
      <g>
        <path
          d="M67.231 23.72a.771.771 0 00.771.771.771.771 0 00.772-.771.771.771 0 00-.772-.771.771.771 0 00-.771.771zM67.194 28.397a.771.771 0 00.771.771.771.771 0 00.772-.771.771.771 0 00-.772-.771.771.771 0 00-.771.771z"
          transform="translate(-49.59 -15.389) translate(-.067 .027)"
        ></path>
      </g>
      <g>
        <path
          d="M67.231 23.72a.771.771 0 00.771.771.771.771 0 00.772-.771.771.771 0 00-.772-.771.771.771 0 00-.771.771zM67.194 28.397a.771.771 0 00.771.771.771.771 0 00.772-.771.771.771 0 00-.772-.771.771.771 0 00-.771.771z"
          transform="translate(-49.59 -15.389) translate(-15.152 .027)"
        ></path>
      </g>
    </svg>
    );
}

export default LogoIcon;