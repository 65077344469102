/** Gets default colors used to draw keyboard */
export default function getKeyboardColors()
{
    return {
        white: {
            key: "ivory",
            pressed: "#A5A5A0",
            shadow: "#080808"
        },
        black: {
            key: "#111",
            pressed: "#444",
            shadow: "black",
            ridge: "rgba(255, 255, 255, .15)",
            bevel: "rgba(255, 255, 255, .15)"
        },
        selection: {
            root: {
                bg: "rgba(128, 255, 128, 0.8)",
                fg: "black"
            },
            other: {
                bg: "rgba(255, 255, 192, 0.8)",
                fg: "black"
            },
            shadow: "#333",
            background: "white",
            hint: "dodgerblue",
            hintTipBG: "rgba(255, 255, 255, 0.6)",
            hintTip: "rgba(0, 0, 0, 0.8)"
        }
    };
}