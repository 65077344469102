import React, { ReactNode } from "react";
import SplitContainer from "./SplitContainer";

/** Defines a [name, value] tuple for a tab container  */
export type TabListItem = [string, string];

type TabContainerProps = {
  tabs: TabListItem[];
  selectedTab: string;
  children: ReactNode | ReactNode[];
  tabClicked: (tab: string) => void;
};

/**
 * Renders a split container with set of tabs on top and the view for the current tab on bottom.
 */
export default function TabContainer(props: TabContainerProps) {
  return (
    <div className="tab-container">
      <SplitContainer direction="horizontal" bottomWeight={1}>
        <div className="tab-list">
          {props.tabs.map(t =>
            <span key={t[1]} onClick={() => props.tabClicked(t[1])} className={t[1] === props.selectedTab ? "active" : ""}>
              {t[0]}
            </span>
          )}
        </div>
        <>
          {props.children}
        </>
      </SplitContainer>
    </div>
  );
}