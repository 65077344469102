import MusicStaffRenderer from "../common/music-staff-renderer";
import { MusicScale } from "@power-chord/music-theory";
import { Accidental } from "@power-chord/music-theory/notes";

// Position of the tops of signatures where 0 is the top line of the staff, 2 is the next line, etc
const signatureLocations: Record<Accidental, number[]> = {
    "#": [0, 3, -1, 2, 5, 1, 4],
    "b": [4, 1, 5, 2, 6, 3, 7],
    "": []
};

export class KeySignatureRenderer extends MusicStaffRenderer
{
    private scale?: MusicScale;

    /**
     * 
     * @param key Note number of the key
     * @param isMinor 
     */
    setScale(scale: MusicScale): KeySignatureRenderer {
        this.scale = scale;
        this.render();
        return this;
    }

    protected drawContent(): KeySignatureRenderer {
        if (this.scale && this.scale.signature.count > 0) {
            const keySignature = this.scale.signature;

            let isSharp = keySignature.accidental === "#";
            let top = this._top - (isSharp ? 12 : 14);
            let locations = signatureLocations[keySignature.accidental];
            for (let i = 1; i <= keySignature.count; i++) {
                let x = (i - 1) * 10 + 25;
                let y = locations[(i - 1)] * 5 + top;
                if (isSharp) {
                    this.drawSharp(x, y);
                }
                else {
                    this.drawFlat(x, y);
                }
            }
        }

        return this;
    }
}