import PopupMenuButton, { NameValueTitle } from './PopupMenuButton';
import { NoteName, getScale, MusicScale, ModeName, ScaleType } from '@power-chord/music-theory';
import NoteSelector from './NoteSelector';
import { featureFlags } from '@local/power-chord-lib/build/src/services/feature-flag-service';
import { useMemo } from 'react';

const LIMITED_MODE_MENU_ITEMS: NameValueTitle[] = [
  ["Major", "major"],
  ["Minor", "minor"],
];

const MODE_MENU_ITEMS: NameValueTitle[] = [
  ["Major", "major"],
  ["Minor", "minor"],
  [featureFlags.dropDownMenuButton ? "" : "\n"],
  ["Lydian", "lydian"],
  ["Ionian", "ionian", "aka Major"],
  ["Mixolydian", "mixolydian"],
  ["Dorian", "dorian"],
  ["Aeolian", "aeolian", "aka Minor"],
  ["Phrygian", "phrygian"],
  ["Locrian", "locrian"]
];

const SCALE_MENU_ITEMS: NameValueTitle[] = [
  ["Diatonic", "diatonic"],
  ["Pentatonic", "pentatonic"],
  ["Blues", "blues"],
];

export type ScaleSelectorProps = {
  /** Initial tonic to select */
  selectedTonic?: NoteName;
  /** Initial mode to select */
  selectedMode?: ModeName;
  /** Initial scale to select */
  selectedScaleType?: ScaleType;
  /** Function to call when selection changed */
  onChange: (scale: MusicScale) => void;
};

/** Used to select a musical scale */
export default function ScaleSelector(props: ScaleSelectorProps) {
  const modeMenuItems = useMemo<NameValueTitle[]>(() => {
    const st = props.selectedScaleType;
    return (!st || st === "diatonic" ? MODE_MENU_ITEMS : LIMITED_MODE_MENU_ITEMS);
  }, [props.selectedScaleType]);

  return (
    <div className='scale-selector'>
      <NoteSelector selectedNote={props.selectedTonic || ""} onChange={e => tonicChanged(e)} title="Select Tonic" />
      <PopupMenuButton menuItems={modeMenuItems} value={props.selectedMode || ""} onChange={e => modeChanged(e)} title="Select Mode" />
      <PopupMenuButton menuItems={SCALE_MENU_ITEMS} value={props.selectedScaleType || ""} onChange={e => scaleChanged(e)} title="Select Scale Type" />
    </div>
  );

  function tonicChanged(tonic: NoteName): void {
    // Notify listener
    fireChangedEvent(tonic, props.selectedMode);
  }

  function modeChanged(mode: string): void {
    if (mode && props.selectedTonic) {
      fireChangedEvent(props.selectedTonic, mode, props.selectedScaleType);
    }
  }

  function scaleChanged(scaleType: string): void {
    if (scaleType && props.selectedTonic) {
      // Make sure we have valid options for the scale type
      const mode = props.selectedMode;
      if (mode !== "major" && mode !== "minor" && scaleType !== "diatonic") {
        // If not valid change mode to default
        fireChangedEvent(props.selectedTonic, "major", scaleType);
      }
      else {
        fireChangedEvent(props.selectedTonic, props.selectedMode, scaleType);
      }
    }
  }

  function fireChangedEvent(tonic: NoteName, mode?: string, scaleType?: string): void {
    props.onChange(getScale(tonic, mode as any, scaleType as any));
  }
}
