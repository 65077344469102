import { Undoable } from "undoable-wrapper";
import { getDefaultRhythmState, RhythmSequence } from "../state-types";

/**
 * Handles state updates for a RhythmSequence 
 */
export class RhythmController {
    // private readonly logger = analog.getLogger("RhythmController");
    private readonly undoableState: Undoable<RhythmSequence>;

    constructor(sequence: RhythmSequence) {
        this.undoableState = new Undoable(sequence, true);
    }

    /** Used to get the current sequence */
    get sequence(): RhythmSequence { return this.undoableState.value!; }

    get canRedo(): boolean { return this.undoableState.canRedo; }

    get canUndo(): boolean { return this.undoableState.canUndo; }

    reset(): void {
        const newSequence = getDefaultRhythmState().sequence;
        if (this.undoableState.value) {
            // Remember the current BPM
            newSequence.beatsPerMinute = this.undoableState.value.beatsPerMinute;
        }

        this.setSequence(newSequence);
    }

    clear(): void {
        this.undoableState.value = this.sequence;
        for (const row of this.undoableState.value.rows) {
            row.beats.fill(false);
        }
    }

    redo(): RhythmSequence {
        return this.undoableState.canRedo ? this.undoableState.redo()! : this.sequence;
    }

    undo(): RhythmSequence {
        return this.undoableState.canUndo ? this.undoableState.undo()! : this.sequence;
    }

    setSequence(newSequence: RhythmSequence): void {
        this.undoableState.value = newSequence;
    }

    addRow(instrument = ""): void {
        this.undoableState.value = this.sequence;
        this.undoableState.value.rows.push({
            name: instrument,
            beats: new Array(this.sequence.beatsPerBar * this.sequence.unitsPerBeat * this.sequence.barCount).fill(false)
        });
    }

    deleteRow(row: number): void {
        this.undoableState.value = this.sequence;
        this.undoableState.value.rows.splice(row, 1);
    }

    changeInstrument(row: number, name: string): any {
        this.undoableState.value = this.sequence;
        this.undoableState.value.rows[row].name = name;
    }

    setBeat(row: number, beat: number, isOn: boolean): void {
        this.undoableState.value = this.sequence;
        const instr = this.undoableState.value.rows[row];
        instr.beats[beat] = isOn;
    }

    setBeatsPerMinute(bpm: number): void {
        this.undoableState.value = this.sequence;
        this.undoableState.value.beatsPerMinute = bpm;
    }

    setBarCount(count: number): void {
        const prevSeq = this.sequence;
        this.undoableState.value = this.sequence;
        this.undoableState.value.barCount = count;
        this.updateRows(prevSeq);
    }

    setBeatsPerBar(amount: number): void {
        const prevSeq = this.sequence;
        this.undoableState.value = this.sequence;
        this.undoableState.value.beatsPerBar = amount;
        this.updateRows(prevSeq);
    }

    setUnitsPerBeat(amount: number): void {
        const prevSeq = this.sequence;
        this.undoableState.value = this.sequence;
        this.undoableState.value.unitsPerBeat = amount;
        this.updateRows(prevSeq);
    }

    private updateRows(prevSeq: RhythmSequence): void {
        const oldNoteCount = prevSeq.barCount * prevSeq.beatsPerBar * prevSeq.unitsPerBeat;
        const newNoteCount = this.sequence.barCount * this.sequence.beatsPerBar * this.sequence.unitsPerBeat;

        const rows = this.sequence.rows;
        if (oldNoteCount > newNoteCount) {
            // Remove beats from each row
            for (let i = 0; i < rows.length; i++) {
                rows[i].beats = rows[i].beats.slice(0, newNoteCount);
            }
        }
        else if (oldNoteCount < newNoteCount) {
            // Add beats to each row
            const toAdd = new Array(newNoteCount - oldNoteCount).fill(false);
            for (let i = 0; i < rows.length; i++) {
                rows[i].beats = rows[i].beats.concat(toAdd);
            }
        }
    }
}