import { parseChord, unformatAccidentals } from "@power-chord/music-theory";
import { ChordProgression } from "../state-types";
import { normalizeNoteName } from "@power-chord/music-theory/notes";
import { SequencerRow } from "../audio/sequencer";

/**
 * Converts a chord progression to an array of SequencerRow
 */
export function progressionToSequencerRows(progression: ChordProgression): SequencerRow[] {
    const items = progression.items;
    const beatCount = items.reduce((p, c) => p + c.beats, 0)
    const beats = Array(beatCount).fill(false);

    // Build the set of all notes used by all chords in the progression
    const rows = {} as Record<string, SequencerRow>;
    for (const item of items) {
        const chord = parseChord(unformatAccidentals(item.chord));
        for (const note of chord.notes) {
            const name = "2" + normalizeNoteName(note.name);
            if (!rows[name]) {
                rows[name] = {
                    name: name,
                    beats: beats.slice()
                };    
            }
        }
    }
    
    // Build the sequencer beats to play the notes at the right time
    let curBeat = 0;
    for (const item of items) {
      const chord = parseChord(unformatAccidentals(item.chord));
      const noteNames = chord.notes.map(n => "2" + normalizeNoteName(n.name));
      for (const noteName of noteNames) {
        let row = rows[noteName];
        row.beats[curBeat] = true;
      }
      curBeat += item.beats;
    }

    return Object.values(rows).map(r => r);
}