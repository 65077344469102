import React from "react";

function MicrophoneIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.098 512.098">
      <path d="M283.803 249.51l-57.099 57.099c-5.858 5.857-15.354 5.858-21.213 0-5.858-5.857-5.858-15.355 0-21.213l57.099-57.099a156.19 156.19 0 01-29.573-55.775L16.312 402.188c-22.264 23.764-21.666 60.415 1.359 83.44l8.801 8.801c23.026 23.028 59.68 23.62 83.479 1.323l229.627-216.669a156.19 156.19 0 01-55.775-29.573z"></path>
      <path d="M283.728 48.572l179.81 179.8c-21.83 17.3-49.43 27.63-79.44 27.63-70.69 0-128-57.31-128-128 0-30.009 10.33-57.6 27.63-79.43zM512.098 128.003c0 29.87-10.24 57.35-27.38 79.13l-179.75-179.75c21.78-17.14 49.26-27.38 79.13-27.38 70.69 0 128 57.31 128 128z"></path>
    </svg>
  );
}

export default MicrophoneIcon;