
/**
 * Normalizes a number within the specified range
 * @param n Number to normalize
 * @param min Minimum value
 * @param max Maximum value
 */
export function normalize(n: number, min: number, max: number): number {
    return Math.min(max, Math.max(min, n));
}

/** Makes the first letter of a string upper case */
export function upperCaseFirstLetter(s: string) {
    return s.length > 0 ? s[0].toUpperCase() + s.slice(1) : s;
}
